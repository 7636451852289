<div [ngClass]="{'!mt-0': showBigSearchHomePage()}"
     class="flex flex-col items-baseline md:items-center lg:-mt-[25px] mb-0 ml-[-.5rem] md:ml-[0rem]">
  <section class="flex items-center sm:ml-[25px] md:ml-0">
    <button #menu (click)="openMenuDropdown(menu)" [ngClass]="{'!text-white': showBigSearchHomePage()}"
            aria-controls="menu"
            aria-expanded="false"
            class="inline-flex items-center sm:-ml-[2rem] p-2 px-0 md:px-2 w-6 md:w-10 h-10 justify-center text-sm text-gray-500 rounded-lg
            hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white
            dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
      <span class="sr-only">Open main menu</span>
      <svg aria-hidden="true" class="w-4 h-4" fill="none" viewBox="0 0 17 14" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1h15M1 7h15M1 13h15" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
              stroke-width="2"/>
      </svg>
    </button>

    <a (click)="goBackToHomePageEvent.emit()" class="flex w-[114px] h-[37px] mt-[2px] relative items-center logo-container
                !mb-[2px] !sm:mb-1 cursor-pointer">
      @if (appTheme() === 'dark' || showBigSearchHomePage()) {
        <img [fill]="true" [attr.alt]="'Songhunt Dark Logo'" class="mr-3" priority
             ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/logo_dark.webp">
      } @else {
        <img [fill]="true" [attr.alt]="'Songhunt Light Logo'" class="mr-3" priority
             ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/logo_light.webp">
      }
    </a>
  </section>

  <p [ngClass]="{'opacity-0 pointer-events-none': showBigSearchHomePage()}"
     class="sm:inline sm:ml-2 pb-1 text-gray-700 focus:outline-none text-[.6rem] xs:text-xs md:text-sm lg:text-md dark:text-gray-400">
    AI Song Search & Playlisting</p>
</div>

import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'mypart-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SearchBarComponent {

}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, WritableSignal} from '@angular/core';
import {pixelateInOutSearchBoxes} from '../../../../animations/pixelateInOutSearchBoxes';
import {CuratedPlaylist} from '../../../../interfaces/curated-playlist';
import {FeaturedArtist} from '../../../../interfaces/featured-artist';

@Component({
  selector: 'mypart-featured-and-curated',
  templateUrl: './featured-and-curated.component.html',
  styleUrl: './featured-and-curated.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  animations: [
    pixelateInOutSearchBoxes
  ],
})
export class FeaturedAndCuratedComponent {
  @Input() curatedPromptPlaylists!: WritableSignal<CuratedPlaylist[] | undefined>;
  @Input() featuredArtist!: WritableSignal<FeaturedArtist | undefined>;
  @Input() isMobileDevice!: WritableSignal<boolean>;
  @Output() promptClickedEvent = new EventEmitter<{ songhuntLink: string; featuredArtistClicked: boolean }>();
  @Input() searchDropDownHidden!: Signal<boolean>;

  promptClicked(songhuntLink: string, featuredArtistClicked: boolean) {
    this.promptClickedEvent.emit({songhuntLink, featuredArtistClicked});
  }
}

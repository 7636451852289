import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, Renderer2} from '@angular/core';
import {GlobalStateService} from '../../../../services/global-state.service';
import {HttpReqService} from '../../../../services/http-req.service';
import {UserStateService} from '../../../../services/user-state.service';
import {ModalType} from '../../../../interfaces/modal-types.enum';
import {ModalService} from '../../../../services/modal.service';

@Component({
  selector: 'mypart-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MenuDropdownComponent {
  @Output() outsideDropdownClick = new EventEmitter<void>();

  user = this.userStateService.user;

  constructor(private globalStateService: GlobalStateService,
              private httpReqService: HttpReqService,
              private userStateService: UserStateService,
              private elRef: ElementRef,
              private modalService: ModalService,
              private renderer: Renderer2) {
  }

  setPosition(top: number, left: number) {
    const dropdownEl = this.elRef.nativeElement;
    this.renderer.setStyle(dropdownEl, 'top', `${top}px`);
    this.renderer.setStyle(dropdownEl, 'left', `${left}px`);
  }

  outsideClick() {
    this.outsideDropdownClick.next();
  }

  navigateToPlaylists() {
    let newUrl =
      this.httpReqService.trimUrlForPopups(window.location.href, '/playlist/') + 'playlist/top-playlists/';
    newUrl = this.trimUrl(newUrl);
    newUrl += '/playlist/top-playlists/';
    window.history.pushState({}, 'Songhunt', newUrl);
    this.globalStateService.showCuratedPlaylists.set(true);
    this.outsideClick();
  }

  showAffiliationModal() {
    this.modalService.open(ModalType.AFFILIATION_LINK);
    this.outsideClick();
  }

  private trimUrl(url: string): string {
    const parts = url.split('/');
    return parts.slice(0, 3).join('/'); // Keep only the first 3 parts
  }
}

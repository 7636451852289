import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfileComponent} from './profile.component';
import {ProfileHeaderComponent} from './profile-header/profile-header.component';
import {ProfileContentComponent} from './profile-content/profile-content.component';
import {
  ProfileContentPlaylistsComponent
} from './profile-content/profile-content-playlists/profile-content-playlists.component';
import {
  ProfileContentHistoryComponent
} from './profile-content/profile-content-history/profile-content-history.component';
import {
  ProfileContentInsightsComponent
} from './profile-content/profile-content-insights/profile-content-insights.component';
import {
  ProfileContentSettingsComponent
} from './profile-content/profile-content-settings/profile-content-settings.component';

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileHeaderComponent,
    ProfileContentComponent,
    ProfileContentPlaylistsComponent,
    ProfileContentHistoryComponent,
    ProfileContentInsightsComponent,
    ProfileContentSettingsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProfileComponent
  ]
})
export class ProfileModule {
}

import {ChangeDetectionStrategy, Component, Input, Signal} from '@angular/core';

@Component({
  selector: 'mypart-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class BackgroundComponent {
  @Input() showBigSearchHomePage!: boolean | undefined;
  @Input() isDragging!: Signal<boolean>;
}

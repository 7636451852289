import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Artwork} from '../../../../interfaces/artwork';

@Component({
  selector: 'mypart-search-list-item',
  templateUrl: './search-list-item.component.html',
  styleUrls: ['./search-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SearchListItemComponent {
  @Input() artwork!: Artwork;
}

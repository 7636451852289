<div class="flex items-center mb-2.5 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 focus:outline-none
              focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700" role="status">
  <div class="flex items-center justify-center
              md:w-[64px] md:min-w-[64px] md:h-[64px]
              w-[48px] min-w-[48px] h-[48px]
              bg-gray-300 rounded dark:bg-gray-700">
    <img [alt]="'Cover image for the song - ' + artwork.title + ', By - ' + artwork.artist" [ngSrc]="artwork.coverUrl"
         class="rounded"
         height="42" imageLoader width="64">
  </div>
  <div class="w-full flex flex-col content-center ml-2">
    <span class="lg:text-lg text-base font-semibold ellipsis" isEllipsis>{{ artwork.title }}</span>
    <span class="lg:text-base text-sm text-gray-500 dark:text-gray-400 ellipsis" isEllipsis>{{ artwork.artist }}</span>
  </div>
  <span class="sr-only">Loading...</span>
</div>

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {YoutubeBaseApiService} from './youtube-base-api.service';

@Injectable({
  providedIn: 'root'
})
export class YoutubeUserAccountService extends YoutubeBaseApiService {
  authenticateUser() {
    // Clear previous user data
    this.youtubeTokensService.clearCredentials();

    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${encodeURIComponent(environment.youtubeClientId)}` +
      `&redirect_uri=${encodeURIComponent(environment.youtubeRedirectUri)}` +
      `&response_type=code` +
      `&scope=${encodeURIComponent('https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email')}` +
      `&access_type=offline`;
  }

  disconnectAccount() {
    this.youtubeTokensService.clearCredentials();
  }

  handleAuthenticationCallback(code: string) {
    return this.exchangeCodeForToken(code);
  }

  saveUserData(accessToken: string, refreshToken: string, email: string) {
    this.youtubeTokensService.setTokens(accessToken, refreshToken, email);
    this.setIsYoutubeConnected();
    return this.getUserProfile();
  }

  setIsYoutubeConnected() {
    this.youtubeTokensService.setIsYoutubeConnected();
  }
}

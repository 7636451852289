<div class="profile-content">
  <div class="tabs-container">
    <div class="tabs">
      <button (click)="setActiveTab('playlists')" [ngClass]="{'active': isTabActive('playlists')}" class="tab-button">
        <span class="tab-icon playlist-icon"></span>
        <span class="tab-text">Playlists</span>
      </button>
      <button (click)="setActiveTab('history')" [ngClass]="{'active': isTabActive('history')}" class="tab-button">
        <span class="tab-icon history-icon"></span>
        <span class="tab-text">History</span>
      </button>
      <button (click)="setActiveTab('insights')" [ngClass]="{'active': isTabActive('insights')}" class="tab-button">
        <span class="tab-icon insights-icon"></span>
        <span class="tab-text">Insights</span>
      </button>
      <button (click)="setActiveTab('settings')" [ngClass]="{'active': isTabActive('settings')}" class="tab-button">
        <span class="tab-icon settings-icon"></span>
        <span class="tab-text">Settings</span>
      </button>
    </div>
  </div>

  <div class="content-container">
    <!-- Content will be dynamically loaded based on selected tab -->
    @if (isTabActive('playlists')) {
      <div class="content-section" @fadeAnimation>
        <!-- Playlists content -->
        <mypart-profile-content-playlists></mypart-profile-content-playlists>
      </div>
    }

    @if (isTabActive('history')) {
      <div class="content-section" @fadeAnimation>
        <!-- History content -->
        <mypart-profile-content-history></mypart-profile-content-history>
      </div>
    }

    @if (isTabActive('insights')) {
      <div class="content-section" @fadeAnimation>
        <!-- Insights content -->
        <mypart-profile-content-insights></mypart-profile-content-insights>
      </div>
    }

    @if (isTabActive('settings')) {
      <div class="content-section" @fadeAnimation>
        <!-- Settings content -->
        <mypart-profile-content-settings></mypart-profile-content-settings>
      </div>
    }
  </div>
</div>

<div class="insights-container animate-fade-in">
  <!-- Summary Stats Section -->
  <section class="stats-summary">
    <h2 class="section-title">Your Listening Stats</h2>
    <div class="stats-cards">
      <div class="stat-card">
        <div class="stat-icon">
          <svg class="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-2-9.5c0 .83-.67 1.5-1.5 1.5S7 11.33 7 10.5 7.67 9 8.5 9s1.5.67 1.5 1.5zm5 0c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5zM12 17c2.61 0 4.83-1.67 5.65-4H6.35c.82 2.33 3.04 4 5.65 4z"/>
          </svg>
        </div>
        <div class="stat-content">
          <div class="stat-value">{{ listeningStats.totalHours }}</div>
          <div class="stat-label">Hours Listened</div>
        </div>
      </div>

      <div class="stat-card">
        <div class="stat-icon">
          <svg class="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3v10.55c-.59-.34-1.27-.55-2-.55-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4V7h4V3h-6z"/>
          </svg>
        </div>
        <div class="stat-content">
          <div class="stat-value">{{ formatNumber(listeningStats.songsPlayed) }}</div>
          <div class="stat-label">Songs Played</div>
        </div>
      </div>

      <div class="stat-card">
        <div class="stat-icon">
          <svg class="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-12.5c-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5 4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5zm0 5.5c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/>
          </svg>
        </div>
        <div class="stat-content">
          <div class="stat-value">{{ listeningStats.topGenre }}</div>
          <div class="stat-label">Top Genre</div>
        </div>
      </div>

      <div class="stat-card">
        <div class="stat-icon">
          <svg class="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
          </svg>
        </div>
        <div class="stat-content">
          <div class="stat-value">{{ listeningStats.favoriteArtist }}</div>
          <div class="stat-label">Favorite Artist</div>
        </div>
      </div>
    </div>
  </section>

  <!-- Genre Preferences Section -->
  <section class="musical-preferences">
    <h2 class="section-title">Your Musical Preferences</h2>
    <div class="preferences-container">
      <div class="artist-list">
        <h3 class="subsection-title">Top Artists</h3>
        @for (artist of topArtists; track artist.name) {
          <div class="artist-item"
               [class.active]="selectedArtist === artist.name"
               (click)="selectArtist(artist.name)"
               (mouseenter)="highlightArtist(artist.name)"
               (mouseleave)="unhighlightArtist()">
            <div class="artist-color" [style.background-color]="artist.color"></div>
            <div class="artist-name">{{ artist.name }}</div>
            <div class="artist-percentage">{{ artist.percentage }}%</div>
            <div class="artist-bar">
              <div class="artist-progress" [style]="getProgressBarStyle(artist.percentage, artist.color)"></div>
            </div>
            <div class="artist-details" *ngIf="selectedArtist === artist.name">
              <div class="detail-row">
                <div class="detail-label">Top Song:</div>
                <div class="detail-value">{{ artist.topSong }}</div>
              </div>
              <div class="detail-row">
                <div class="detail-label">Listening Hours:</div>
                <div class="detail-value">{{ artist.listeningHours }} hours</div>
              </div>
            </div>
          </div>
        }
      </div>
      <div class="genre-list">
        <h3 class="subsection-title">Top Genres</h3>
        @for (genre of genreData; track genre.name) {
          <div class="genre-item"
               [class.active]="selectedGenre === genre.name"
               (click)="selectGenre(genre.name)"
               (mouseenter)="highlightGenre(genre.name)"
               (mouseleave)="unhighlightGenre()">
            <div class="genre-color" [style.background-color]="genre.color"></div>
            <div class="genre-name">{{ genre.name }}</div>
            <div class="genre-percentage">{{ genre.percentage }}%</div>
            <div class="genre-bar">
              <div class="genre-progress" [style]="getProgressBarStyle(genre.percentage, genre.color)"></div>
            </div>
            <div class="genre-details" *ngIf="selectedGenre === genre.name">
              <div class="detail-row">
                <div class="detail-label">Top Artist:</div>
                <div class="detail-value">{{ getGenreTopArtist(genre.name) }}</div>
              </div>
              <div class="detail-row">
                <div class="detail-label">Top Track:</div>
                <div class="detail-value">{{ getGenreTopTrack(genre.name) }}</div>
              </div>
              <div class="detail-row">
                <div class="detail-label">Listening Time:</div>
                <div class="detail-value">{{ getGenreListeningTime(genre.name) }}</div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </section>

  <!-- Artist Recommendations Section -->
  <section class="artist-recommendations">
    <h2 class="section-title">Recommended Artists For You</h2>
    <div class="recommendations-container">
      @for (artist of artistRecommendations; track artist.id) {
        <div class="artist-card">
          <div class="artist-image-container">
            <div class="artist-image-placeholder" [style.background-image]="'url(' + artist.imageUrl + ')'">
              <!-- Fallback if image doesn't load -->
              <svg class="fallback-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
              </svg>
            </div>
            <div class="match-badge" [class]="getMatchClass(artist.matchPercentage)">
              {{ artist.matchPercentage }}% Match
            </div>
          </div>
          <div class="artist-info">
            <h3 class="artist-name">{{ artist.name }}</h3>
            <div class="artist-genre">{{ artist.genre }}</div>
            <div class="artist-similar">Similar to {{ artist.similarTo }}</div>
          </div>
          <div class="artist-actions">
            <button class="listen-button" (click)="listenToArtist(artist)">
              <svg class="play-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M8 5v14l11-7z"/>
              </svg>
              Listen
            </button>
            <button class="add-button" (click)="addArtistToLibrary(artist, $event)">
              <svg class="add-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
              </svg>
            </button>
          </div>
        </div>
      }
    </div>
  </section>

  <!-- Mood & Time Insights Section -->
  <section class="additional-insights">
    <div class="mood-insights">
      <h2 class="section-title">Your Mood Preferences</h2>
      <div class="insights-list">
        @for (mood of moodInsights; track mood.mood) {
          <div class="insight-item">
            <div class="insight-icon" [style.color]="mood.color">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path [attr.d]="getMoodIconPath(mood.icon)"/>
              </svg>
            </div>
            <div class="insight-info">
              <div class="insight-name">{{ mood.mood }}</div>
              <div class="insight-bar">
                <div class="insight-progress" [style]="getProgressBarStyle(mood.percentage)"
                     [style.background-color]="mood.color"></div>
              </div>
            </div>
            <div class="insight-percentage">{{ mood.percentage }}%</div>
          </div>
        }
      </div>
    </div>

    <div class="time-insights">
      <h2 class="section-title">When You Listen</h2>
      <div class="insights-list">
        @for (time of timeInsights; track time.timeOfDay) {
          <div class="insight-item">
            <div class="insight-icon">
              <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path [attr.d]="getTimeIconPath(time.icon)"/>
              </svg>
            </div>
            <div class="insight-info">
              <div class="insight-name">{{ time.timeOfDay }}</div>
              <div class="insight-bar">
                <div class="insight-progress" [style]="getProgressBarStyle(time.percentage)"></div>
              </div>
            </div>
            <div class="insight-percentage">{{ time.percentage }}%</div>
          </div>
        }
      </div>
    </div>
  </section>
</div>

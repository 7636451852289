import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, WritableSignal} from '@angular/core';

@Component({
  selector: 'mypart-logo',
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LogoComponent {
  @Input() showBigSearchHomePage!: WritableSignal<boolean | undefined>;
  @Input() appTheme!: WritableSignal<'dark' | 'light'>;
  @Output() goBackToHomePageEvent = new EventEmitter<void>();
  @Output() openMenuDropdownEvent = new EventEmitter<HTMLButtonElement>();


  openMenuDropdown(menu: HTMLButtonElement) {
    this.openMenuDropdownEvent.emit(menu);
  }
}

<div class="history-container">
  <div class="history-header">
    <h2 class="header-title">Search History</h2>
    <button (click)="clearHistory()" *ngIf="searchHistory.length > 0" class="clear-btn">
      <svg fill="none" height="16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
           stroke-width="2" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 6h18"></path>
        <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
        <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
      </svg>
      <span>Clear History</span>
    </button>
  </div>

  <div *ngIf="searchHistory.length > 0" class="history-list">
    <div *ngFor="let item of searchHistory" class="history-item">
      <div class="left-gradient"></div>
      <div class="item-content">
        <div class="item-main">
          <div class="item-query">{{ item.query }}</div>
          <div class="item-timestamp">
            <svg class="clock-icon" fill="none" height="12" stroke="currentColor" stroke-linecap="round"
                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="12"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
            {{ item.timestamp }}
          </div>
        </div>
        <div class="item-tags">
          <span *ngFor="let tag of item.filterTags" class="tag">{{ tag }}</span>
        </div>
      </div>
      <div class="item-actions">
        <button (click)="searchAgain(item.query)" aria-label="Search again" class="action-btn search-again-btn">
          <svg fill="none" height="16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
               stroke-width="2" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
          </svg>
        </button>
        <button (click)="removeHistoryItem(item.id)" aria-label="Remove from history" class="action-btn remove-btn">
          <svg fill="none" height="16" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
               stroke-width="2" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg">
            <line x1="18" x2="6" y1="6" y2="18"></line>
            <line x1="6" x2="18" y1="6" y2="18"></line>
          </svg>
        </button>
      </div>
      <div class="right-gradient"></div>
    </div>
  </div>

  <div *ngIf="searchHistory.length === 0" class="empty-state">
    <svg class="empty-icon" fill="none" height="80" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
         stroke-width="1" viewBox="0 0 24 24" width="80" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="8"></circle>
      <line x1="21" x2="16.65" y1="21" y2="16.65"></line>
    </svg>
    <h3 class="empty-title">No search history</h3>
    <p class="empty-message">Your search history will appear here once you start searching for songs.</p>
  </div>
</div>

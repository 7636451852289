import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  signal
} from '@angular/core';
import {Filter, SubFilter} from '../../../../../interfaces/filter';
import {UntilDestroy} from '@ngneat/until-destroy';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs';
import {FiltersStateService} from '../../../../../services/filters-state.service';
import {GlobalStateService} from '../../../../../services/global-state.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FilterDropdownComponent implements OnInit, AfterViewInit {
  @Input() filter!: Filter;
  @Input() isInputFilter = false;
  @Output() filtersClicked = new EventEmitter<void>();
  @Output() outsideDropdownClick = new EventEmitter<void>();
  searchTerm: FormControl = new FormControl('');
  filteredSubFilters = signal<SubFilter[]>([]);
  showSearchWordInThemeFilter = signal(false);
  lyricsContains = this.filtersStateService.lyricsContains;
  showBigSearchHomePage = this.globalStateService.showBigSearchHomePage;

  constructor(private filtersStateService: FiltersStateService,
              private globalStateService: GlobalStateService,
              private elRef: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    if (this.filtersStateService.selectedFilters.hasOwnProperty(this.filter.title)) {
      const selectedFilter = this.filtersStateService.selectedFilters[this.filter.title];
      this.filter.filters.forEach(subFilter => {
        const selectedSubFilter = selectedFilter.filters.find(sf => sf.id === subFilter.id);
        if (selectedSubFilter) {
          subFilter.state = selectedSubFilter.state;
        }
      });
    }

    this.filteredSubFilters.set(this.filter.filters);

    this.searchTerm.valueChanges.pipe(
      debounceTime(100)
    ).subscribe(value => {
      this.filterSubFilters();
    });
  }

  ngAfterViewInit() {
    this.showSearchWordInThemeFilter.set(this.filter.title === 'Lyrical Theme');
  }

  filterSubFilters() {
    if (!this.searchTerm.value) {
      this.filteredSubFilters.set(this.filter.filters);
    } else {
      this.filteredSubFilters.set(this.filter.filters.filter(subFilter =>
        subFilter.name.toLowerCase().includes(this.searchTerm.value.toLowerCase())
      ));
    }
  }

  setPositionOffScreen() {
    const dropdownEl = this.elRef.nativeElement;
    this.renderer.setStyle(dropdownEl, 'top', '-9999px');
    this.renderer.setStyle(dropdownEl, 'left', '-9999px');
  }

  setPositionRight(top: number, right: number) {
    const dropdownEl = this.elRef.nativeElement;
    this.renderer.setStyle(dropdownEl, 'top', `${top}px`);
    this.renderer.setStyle(dropdownEl, 'right', `${right}px`);
    this.renderer.setStyle(dropdownEl, 'left', 'unset');
  }

  setPositionLeft(top: number, left: number) {
    const dropdownEl = this.elRef.nativeElement;
    this.renderer.setStyle(dropdownEl, 'top', `${top}px`);
    this.renderer.setStyle(dropdownEl, 'left', `${left}px`);
  }

  setPositionCenter(top: number, left: number, width: number) {
    const dropdownEl = this.elRef.nativeElement;

    // Set the initial off-screen position
    this.renderer.setStyle(dropdownEl, 'top', '-9999px');
    this.renderer.setStyle(dropdownEl, 'left', '-9999px');

    // Calculate the center position by subtracting half the dropdown width
    const centerLeft = left + (width / 2) - (250 / 2); // assuming the dropdown width is 250px

    // Set the final position
    setTimeout(() => {
      this.renderer.setStyle(dropdownEl, 'top', `${top}px`);
      this.renderer.setStyle(dropdownEl, 'left', `${centerLeft}px`);
    }, 0); // You can adjust the timeout if needed
  }

  outsideClick() {
    this.outsideDropdownClick.next();
  }

  addLyricsContains() {
    this.filtersStateService.lyricsContains.set(this.searchTerm.value);
    this.searchTerm.setValue('');
    this.emitFiltersClicked();
  }

  removeLyricsContains() {
    this.filtersStateService.lyricsContains.set(undefined);
    this.emitFiltersClicked();
  }

  toggleFilter(subFilter: SubFilter, state: 'green' | 'red') {
    if (subFilter.state === state) {
      this.removeFilter(subFilter);
    } else {
      this.filtersStateService.addOrChangeSubFilterSelectedFilters(this.filter, subFilter, state, this.isInputFilter);
    }
    this.emitFiltersClicked();
  }

  filterTextClicked(subFilter: SubFilter) {
    if (subFilter.state === 'none') {
      this.toggleFilter(subFilter, 'green');
    } else {
      this.toggleFilter(subFilter, subFilter.state);
    }
  }

  private removeFilter(subFilter: SubFilter) {
    this.filtersStateService.removeFilter(this.filter, subFilter);
  }

  private emitFiltersClicked() {
    // Use a microtask
    Promise.resolve().then(() => {
      this.filtersClicked.next();
    });
  }
}

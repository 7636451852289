<div class="playlists-container">
  <div class="playlists-header">
    <div class="search-box">
      <input (input)="searchPlaylists($any($event.target).value)" class="search-input" placeholder="Search playlists..."
             type="text">
      <span class="search-icon"></span>
    </div>
    <div class="header-buttons">
      <button (click)="toggleViewMode()"
              [attr.aria-label]="viewMode === 'grid' ? 'Switch to list view' : 'Switch to grid view'"
              class="view-toggle-btn">
        @if (viewMode === 'grid') {
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <line x1="8" y1="6" x2="21" y2="6"></line>
            <line x1="8" y1="12" x2="21" y2="12"></line>
            <line x1="8" y1="18" x2="21" y2="18"></line>
            <line x1="3" y1="6" x2="3.01" y2="6"></line>
            <line x1="3" y1="12" x2="3.01" y2="12"></line>
            <line x1="3" y1="18" x2="3.01" y2="18"></line>
          </svg>
        } @else {
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <rect x="3" y="3" width="7" height="7"></rect>
            <rect x="14" y="3" width="7" height="7"></rect>
            <rect x="14" y="14" width="7" height="7"></rect>
            <rect x="3" y="14" width="7" height="7"></rect>
          </svg>
        }
      </button>
      <button (click)="createPlaylist()" class="create-playlist-btn">
        <svg class="plus-icon" fill="none" height="24" stroke="currentColor" stroke-linecap="round"
             stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 12h14"></path>
          <path d="M12 5v14"></path>
        </svg>
        <span class="btn-text">New Playlist</span>
      </button>
    </div>
  </div>

  <!-- Grid View -->
  @if (viewMode === 'grid') {
    <div class="playlists-grid">
      <!-- Playlist Cards -->
      <div class="playlist-card" *ngFor="let playlist of playlists">
        <div class="card-image">
          <img [src]="playlist.covers[0]" alt="Playlist cover" class="playlist-cover">
          <div class="card-overlay">
            <button class="play-btn" (click)="playPlaylist(playlist.id)">
              <span class="play-icon"></span>
            </button>
          </div>
        </div>
        <div class="card-info">
          <div class="info-top-row">
            <h3 class="playlist-title">{{ playlist.title }}</h3>
            <div class="playlist-actions">
              <button class="action-btn more-btn" (click)="showMoreOptions(playlist.id, $event)"></button>
            </div>
          </div>
          <div class="playlist-details">
            <span class="song-count">{{ playlist.songCount }} songs</span>
            <span class="date">{{ playlist.date }}</span>
          </div>
        </div>
      </div>
    </div>
  } @else {
    <!-- List View -->
    <div class="playlists-list">
      <div class="playlist-list-item" *ngFor="let playlist of playlists">
        <div class="left-gradient"></div>
        <div class="right-gradient"></div>
        <div class="list-item-image">
          <img [src]="playlist.covers[0]" alt="Playlist cover" class="playlist-cover">
          <div class="card-overlay">
            <button class="play-btn" (click)="playPlaylist(playlist.id)">
              <span class="play-icon"></span>
            </button>
          </div>
        </div>
        <div class="list-item-info">
          <div class="info-top-row">
            <h3 class="playlist-title">{{ playlist.title }}</h3>
            <div class="list-item-actions">
              <button class="action-btn more-btn" (click)="showMoreOptions(playlist.id, $event)"></button>
            </div>
          </div>
          <div class="playlist-details">
            <span class="song-count">{{ playlist.songCount }} songs</span>
            <span class="date">{{ playlist.date }}</span>
          </div>
        </div>
      </div>
    </div>
  }

  <!-- Empty State -->
  @if (playlists.length === 0) {
    <div class="empty-state">
      <div class="empty-icon"></div>
      <h3 class="empty-title">No Playlists Yet</h3>
      <p class="empty-message">Create your first playlist to start organizing your favorite songs.</p>
      <button class="create-btn" (click)="createPlaylist()">Create Playlist</button>
    </div>
  }
</div>

import {ChangeDetectionStrategy, Component, computed, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {GlobalStateService} from '../../../services/global-state.service';
import {HttpReqService} from '../../../services/http-req.service';
import {UserStateService} from '../../../services/user-state.service';
import {SpotifyUserAccountService} from '../../../services/spotify/spotify-user-account.service';
import {switchMap} from 'rxjs/operators';
import {YoutubeUserAccountService} from '../../../services/youtube/youtube-user-account.service';
import {TippyDirective} from '@ngneat/helipopper';
import {ToastService} from '../../../services/toast.service';
import {ModalService} from '../../../services/modal.service';
import {ModalType} from '../../../interfaces/modal-types.enum';
import {toggleTheme} from '../../../helper/theme';
import {environment} from '../../../../environments/environment.prod';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class LoginMenuComponent implements OnInit {
  @Input() isPlaylistActive: boolean = false;
  @Output() toggleDiscoveryMode = new EventEmitter<void>();

  showBigSearchHomePage = this.globalStateService.showBigSearchHomePage;
  appTheme = this.globalStateService.appTheme;
  semanticsModel = this.globalStateService.semanticsModel;
  discoveryMode = this.globalStateService.discoveryMode;

  user = this.userStateService.user;
  signInOrUpgradeText = computed(() => {
    if (!this.user()) return 'Log In';
    return this.user()!.isSubscribed ? 'Log In' : 'Upgrade';
  });

  userImage = computed(() => {
    if (!this.user()) return 'login';
    return this.user()!.isSubscribed ? 'hunter' : 'explorer';
  });

  isProd = environment.production;

  private tooltip: TippyDirective | undefined;

  constructor(private globalStateService: GlobalStateService,
              private httpReqService: HttpReqService,
              private userStateService: UserStateService,
              private spotifyUserAccountService: SpotifyUserAccountService,
              private youtubeUserAccountService: YoutubeUserAccountService,
              private modalService: ModalService,
              private toast: ToastService) {
  }

  ngOnInit() {
    window.addEventListener('scroll', (event) => {
      this.tooltip?.hide();
    });
  }

  signIn() {
    this.modalService.open(ModalType.LOGIN_REGISTER, {
      inputs: {
        loginClicked: true
      }
    });
  }

  showProfilePage() {
    this.globalStateService.showProfilePage.set(true);
  }

  logout() {
    this.httpReqService.logout().pipe(
      switchMap(() => {
        this.toast.success(`${this.user()?.name} has logged out`);
        this.userStateService.setUser(null);
        return this.httpReqService.loginDefaultUser();
      })
    ).subscribe({
      next: () => {

      },
      error: (error) => {

      }
    });
  }

  toggleDarkMode() {
    const appTheme = this.appTheme() === 'light' ? 'dark' : 'light';
    toggleTheme(appTheme === 'light');
    localStorage['theme'] = appTheme;
    this.appTheme.set(appTheme);
    this.updateUserPreferences('theme', appTheme);
  }

  discoveryModeClicked() {
    this.discoveryMode.set(!this.discoveryMode());
    localStorage.setItem('discovery', this.discoveryMode().toString());
    this.updateUserPreferences('discovery', `${this.discoveryMode()}`);
    this.toggleDiscoveryMode.emit();
  }

  switchAiModel() {
    const semanticsModel = this.semanticsModel() === 'SimCSE' ? 'Roberta' : 'SimCSE';
    this.semanticsModel.set(semanticsModel);
    localStorage.setItem('discovery', semanticsModel);
    this.updateUserPreferences('semanticsModel', semanticsModel);
  }

  connectAccount(value: string) {
    if (!this.user) {
      this.modalService.open(ModalType.LOGIN_REGISTER, {});
      return;
    }

    switch (value) {
      case 'spotify': {
        this.connectSpotify();
        break;
      }
      case 'youtube': {
        this.connectYoutube();
        break;
      }
    }
  }

  disconnectAccount(value: string) {
    switch (value) {
      case 'spotify': {
        this.spotifyUserAccountService.disconnectAccount();
        this.toast.success(`Spotify has been disconnected`);
        break;
      }
      case 'youtube': {
        this.youtubeUserAccountService.disconnectAccount();
        this.toast.success(`YouTube has been disconnected`);
        break;
      }
    }
  }

  showTooltip(tooltip: TippyDirective) {
    if (this.tooltip?.isVisible) {
      return;
    }
    this.tooltip = tooltip;
    this.tooltip.show();
  }

  goToAdminPanel() {
    this.globalStateService.showAdminPanel.set(true);
  }

  private connectSpotify() {
    this.spotifyUserAccountService.authenticateUser();
  }

  private connectYoutube() {
    this.youtubeUserAccountService.authenticateUser();
  }

  private updateUserPreferences(key: string, value: string) {
    if (this.user()) {
      this.httpReqService.updateUserPreference(key, value)
        .subscribe({
          next: data => {
          }
        });
    }
  }
}

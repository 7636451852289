import {animate, state, style, transition, trigger} from '@angular/animations';

export const expandFadeInAnimation = trigger('expandFadeIn', [
  state('void', style({
    opacity: 0,
    height: '0px',
    overflow: 'hidden'
  })),
  state('*', style({
    opacity: 1,
    height: '*',  // Auto height. Adjust if needed.
    overflow: 'hidden'
  })),
  transition('void <=> *', [
    animate('75ms ease-in-out')
  ])
]);

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, WritableSignal} from '@angular/core';
import {pixelateInOutDelay} from '../../../../animations/pixelateInOutDelay';

@Component({
  selector: 'mypart-upload-responses',
  templateUrl: './upload-responses.component.html',
  styleUrl: './upload-responses.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  animations: [
    pixelateInOutDelay
  ],
})
export class UploadResponsesComponent {
  @Input() fileUploadResponse!: WritableSignal<{ key: string; value: string }[] | undefined>;
  @Output() sendToMainEvent = new EventEmitter<string | undefined>();

}

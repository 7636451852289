<mypart-background
    [isDragging]="isDragging"
    [showBigSearchHomePage]="showBigSearchHomePage()">
</mypart-background>

<nav #navbar (dragleave)="onDragLeave($event)"
     (dragover)="onDragOver($event)"
     (drop)="onDrop($event)" [ngClass]="{'big-search-home-page': showBigSearchHomePage(),
     'dark-gradient': appTheme() === 'dark' && showBigSearchHomePage(),
     'light-gradient': appTheme() === 'light' && showBigSearchHomePage()}"
     class="section-border fixed z-40 top-0 left-0 custom-box-shadow px-4 h-fit">

  <div class="max-w-full flex flex-wrap items-center justify-between mx-auto pb-0">
    <!--Logo-->
    <mypart-logo
        (goBackToHomePageEvent)="goBackToHomePage()"
        (openMenuDropdownEvent)="openMenuDropdown($event)"
        [appTheme]="appTheme"
        [showBigSearchHomePage]="showBigSearchHomePage">
    </mypart-logo>

    <!--Login / Playlist-->
    <div [ngClass]="{'!mt-0': showBigSearchHomePage()}"
         class="flex items-baseline order-1 lg:-mt-[25px] lg:order-2 mb-0 ml-auto mr-2">
      <mypart-playlist-icon (showPlaylistEvent)="showPlaylist()"
                            [totalPlaylistSongs]="totalPlaylistSongs()"
                            class="-mt-[20px]">
      </mypart-playlist-icon>
    </div>

    <div [ngClass]="{'!mt-0': showBigSearchHomePage()}"
         class="flex items-baseline order-1 lg:-mt-[25px] lg:order-2 mb-0 mr-[-.5rem] md:mr-[0rem]">
      <!--Login-->
      <mypart-login-menu (toggleDiscoveryMode)="toggleDiscoveryMode()"
                         [isPlaylistActive]="totalPlaylistSongs() > 0"
                         class="-mt-[20px]"></mypart-login-menu>
    </div>

    <!--Search Bar-->
    <div [ngClass]="{
          'middle-to-top': !showBigSearchHomePage(),
          'top-to-middle lg:-ml-[7vw] xl:-ml-[2vw] lg:!w-3/4 sm:h-[60px] sm:!leading-[2.5]': showBigSearchHomePage()}"
         class="search-bar-container">

      <form (outsideClick)="outsideClickEvent()"
            [ngClass]="{'show-before': showBigSearchHomePage()}"
            class="flex flex-col items-center w-full xl:w-[75%] relative">
        @if (showBigSearchHomePage()) {
          <mypart-subtitle></mypart-subtitle>
        }
        <label class="sr-only" for="editableDiv">Search</label>
        <div (click)="showSearchDropDown()" class="relative w-full search-container" stopPropagation>
          <div class="flex items-center pointer-events-none icon-container">
            @if (isLoading()) {
              <svg aria-hidden="true"
                   class="w-4 h4 text-gray-200 animate-spin dark:text-gray-700 fill-gray-700 dark:fill-gray-200"
                   fill="none" viewBox="0 0 100 101" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"/>
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            } @else {
              <svg aria-hidden="true" class="w-4 h-4 text-gray-700 dark:text-gray-300" fill="none"
                   [ngClass]="{'!text-mypart_red-500': discoveryMode(), 'dark:!text-mypart_red-500': discoveryMode()}"
                   viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"/>
              </svg>
            }
          </div>

          <div #editableDiv
               (blur)="addPlaceholder()" (dragover)="$event.preventDefault()" (drop)="$event.preventDefault()"
               (focus)="removePlaceholder()" (input)="onDivInput($event)"
               (keydown)="handleKeydown($event)"
               (paste)="onPaste()"
               class="editable-div placeholder" contenteditable="true"
               enterkeyhint="search" id="editableDiv" spellcheck="false">
            {{ defaultSearchPlaceholder }}
          </div>
          <!--This div is for calculating the animation-->
          <div #editableCopyDiv
               class="copy-search-div editable-div placeholder"
               id="editableCopyDiv">Copy
          </div>

          <div class="flex flex-row gap-2 justify-center items-center">
            @if (editableDiv.innerText !== defaultSearchPlaceholder && editableDiv.innerText !== '') {
              <svg (click)="clearSearchBar(true)"
                   aria-hidden="true"
                   class="cursor-pointer w-[14px] h-[14px] sm:w-[20px] sm:h-[20px] text-gray-800 dark:text-gray-300"
                   fill="none"
                   stopPropagation
                   tp="Clear search"
                   viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"/>
              </svg>
              <div class="h-8 mx-3 border-l border-gray-400 dark:border-gray-500"></div>
            }

            <mypart-file-upload
                (changeFileEvent)="changeFile($event)"
                (showSignUpPopupEvent)="showSignUpPopup()"></mypart-file-upload>

            @if (prompterIsActive() && !isMobileDevice()) {
              <svg class="w-6 h-6 text-gray-700 dark:text-gray-300 cursor-pointer" [@pixelateInOut]="true"
                   aria-hidden="true"
                   [ngClass]="{'light-mode': appTheme() === 'light', 'dark-mode': appTheme() === 'dark'}"
                   xmlns="http://www.w3.org/2000/svg"
                   width="24" height="24" fill="none" viewBox="0 0 24 24"
                   (click)="searchIconEvent()"
                   [tp]="isMobileDevice() ? null : file() ? 'Upload file' : 'Search'">
                <path class="path-animation" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"/>
              </svg>
            }

            <!--Sorting-->
            @if (!isQuickTags) {
              <div #tippy="tippy" [tp]="tpl" stopPropagation
                   class="relative w-[20px] h-[20px] sm:w-[24px] sm:h-[24px]"
                   tpClassName="custom-popper"
                   tpPlacement="bottom-end"
                   tpVariation="popper">

                <img [attr.alt]="'The image shows an icon designed for sorting functionality. ' +
             'It features three horizontal red bars of varying lengths, stacked vertically, with a red arrow' +
              ' pointing downward on the right side of the bars. Each element has a white outline, making the ' +
               'icon stand out against a black background. The three bars represent sortable items, and the ' +
                'downward-pointing arrow indicates the sorting direction, likely descending. This design is clear ' +
                 'and intuitive, effectively conveying its purpose as a sorting button.'"
                     class="block cursor-pointer z-30" fill="true"
                     ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/sort.webp"
                     [tp]="isMobileDevice() ? null : 'Define similarity type'">
              </div>
              <ng-template #tpl>
                <div class="inline-block w-[250px] bg-zinc-100 dark:bg-zinc-800
              shadow-xl rounded-md overflow-hidden max-h-[250px]">
                  <div
                      class="px-3 py-2 bg-zinc-200 border-b border-zinc-400 rounded-t-lg dark:border-gray-600 dark:bg-zinc-700">
                    <h3 class="font-semibold text-gray-900 dark:text-white">Sort songs by:</h3>
                  </div>
                  <ul class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200 overscroll-contain">
                    @for (sortBy of sortByArray(); track sortBy.name) {
                      <li (click)="selectSortBy(sortBy, tippy)"
                          class="cursor-pointer"
                          stopPropagation>
                        <div class="flex items-center pl-2 rounded hover:bg-gray-200 dark:hover:bg-gray-600">
                <span [ngClass]="{'!text-green-500 underline': sortBy.selected}"
                      class="w-full py-2 ml-2 text-sm font-medium text-gray-900 rounded
                            max-[600px]:whitespace-normal dark:text-gray-300 ellipsis" isEllipsis>
                  {{ sortBy.name }}
                </span>
                        </div>
                      </li>
                    }
                  </ul>
                </div>
              </ng-template>
            }
          </div>

          @if (prompterIsActive() && isMobileDevice()) {
            <div class="flex items-center
                      cursor-pointer icon-container">
              <svg class="w-6 h-6 text-gray-700 dark:text-gray-300 cursor-pointer" [@pixelateInOut]="true"
                   aria-hidden="true"
                   [ngClass]="{'light-mode': appTheme() === 'light', 'dark-mode': appTheme() === 'dark'}"
                   xmlns="http://www.w3.org/2000/svg"
                   width="24" height="24" fill="none" viewBox="0 0 24 24"
                   (click)="prompterEnterPressed()"
                   [tp]="isMobileDevice() ? null : file() ? 'Upload file' : 'Search'">
                <path class="path-animation" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 12H5m14 0-4 4m4-4-4-4"/>
              </svg>
            </div>
          }
        </div>

        <div class="w-full relative z-50">
          <div #searchDropdown (click)="closeSearchDropdownAndHideNothingFound()"
               (outsideClick)="closeSearchDropdownAndHideNothingFound()"
               [ngClass]="{hidden: searchDropDownHidden(),
                  '!max-h-[350px]': showBigSearchHomePage()}"
               class="w-full z-10 h-auto max-h-[500px] absolute top-1 left-0 bg-gray-50
                        border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-500
                        focus:border-blue-500 block dark:bg-dark_input-500 dark:border-gray-600 overflow-y-hidden
                        dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            @if (!showNothingFound()) {
              <section class="overflow-y-scroll max-h-[440px] pl-2.5 pr-0 sm:pr-2.5 py-2.5"
                       [ngClass]="{'!max-h-[340px]': showBigSearchHomePage()}">
                @for (artwork of artworks; track artwork.id) {
                  <mypart-search-list-item (click)="selectArtwork(artwork)"
                                           [artwork]="artwork"
                                           stopPropagation>
                  </mypart-search-list-item>
                }
              </section>
            }
            @if (isLoading() && artworks.length === 0 && !showNothingFound()) {
              <section class="pl-2.5 pr-0 sm:pr-2.5 py-2.5">
                @for (i of skeletonArray; track i) {
                  <mypart-search-list-skeleton></mypart-search-list-skeleton>
                }
              </section>
            }
            @if (errorMessageFromPrompt() || showLinkHasBeenEntered) {
              <div class="error-message-container pl-2.5 pr-0 sm:pr-2.5 py-2.5"
                   (outsideClick)="closeSearchDropdownAndHideNothingFound()">
                @if (showLinkHasBeenEntered) {
                  <p>
                    Please enter a song title, artist name or descriptive text instead of a link.
                  </p>
                }
              </div>
            }

            <div class="h-[50px] flex justify-center items-center sticky left-0 bottom-0 bg-gray-50 dark:bg-dark_input-500 rounded-lg
                          sm:flex sm:flex-row w-full sm:justify-center border-t border-t-gray-300 dark:border-t-gray-700">
              <p class="text-[18px]">
                Can’t find a song/artist you’re looking for?
                <a (click)="submitSong()"
                   class="underline font-bold hover:text-mypart_red-500 w-[20%] cursor-pointer">Add it here</a>
              </p>
            </div>

          </div>
        </div>

        @if (!showBigSearchHomePage()) {
          <div class="w-[100vw] sm:w-full my-1 flex justify-between sm:justify-end items-center px-2 py-1.5 sm:p-1.5">
            <div class="w-fit mr-auto ml-2 text-start">
                <span (click)="addTopTwentySongsToPlaylist('SH_TOP_20_ADD')"
                      class="text-[.8rem] sm:text-sm font-bold text-black dark:text-white underline cursor-pointer">Add Top 20 to Playlist</span>
            </div>
            <mypart-social-sharing
                [link]="getSearchLink()" class="flex items-center -ml-[8px] w-[40px] h-full"
                source="search"></mypart-social-sharing>

            @if (!isIphone()) {
              <svg xmlns="http://www.w3.org/2000/svg" (click)="togglePlayAllSongs()" aria-hidden="true"
                   class="text-mypart_red-500 mr-2 cursor-pointer h-4" fill="currentColor" stopPropagation
                   viewBox="0 0 358.000000 293.000000" preserveAspectRatio="xMidYMid meet"
                   [attr.alt]="'Play All Songs'"
                   [tp]="isMobileDevice() ? null : playAllSongs() ? 'Stop Play All' : 'Play All Songs'">
                <g transform="translate(0.000000,293.000000) scale(0.100000,-0.100000)"
                   fill="currentColor" stroke="none">
                  <path d="M81 2834 c-45 -37 -53 -79 -49 -246 3 -164 11 -186 79 -220 32 -17
                      120 -18 1419 -18 1323 0 1387 1 1419 18 64 34 72 65 69 255 l-3 169 -37 34
                      -38 34 -1414 0 -1414 0 -31 -26z"/>
                  <path d="M85 1898 c-46 -36 -55 -73 -55 -230 0 -158 10 -193 63 -232 28 -21
                      36 -21 756 -21 678 0 729 1 754 18 51 33 61 60 65 188 6 175 -2 226 -45 266
                      l-34 33 -738 0 -738 0 -28 -22z"/>
                  @if (playAllSongs()) {
                    <rect x="2100" y="0" width="600" height="1826" rx="70" ry="70"/>
                    <rect x="2900" y="0" width="600" height="1826" rx="70" ry="70"/>
                  } @else {
                    <path d="M2185 1826 c-40 -17 -84 -63 -101 -103 -11 -26 -14 -174 -14 -792 0
                      -512 3 -769 11 -788 24 -64 109 -122 179 -123 19 0 58 11 85 25 28 13 293 181
                      590 373 614 397 598 383 603 505 3 83 -17 130 -78 178 -50 39 -1108 719 -1137
                      730 -35 13 -103 10 -138 -5z"/>
                  }
                  <path d="M92 960 c-55 -34 -64 -71 -60 -249 5 -254 -22 -241 503 -241 437 0
                      437 0 475 63 18 28 20 51 20 190 0 176 -7 203 -63 237 -30 19 -53 20 -437 20
                      -389 0 -406 -1 -438 -20z"/>
                </g>
              </svg>
            }
            @if (!isMobileDevice()) {
              <div>
                <span (click)="toggleFilters()"
                      class="w-fit text-end text-[.8rem] sm:text-sm text-gray-500 dark:text-gray-400 mx-2 cursor-pointer select-none">
              {{
                    isQuickTags
                      ? isMobileDevice() ? 'Search with Filters' : 'Search with lyrical and musical filters'
                      : 'Refine search'
                  }}
              </span>
              </div>
            }

            <button (click)="toggleFilters()"
                    [ngClass]="{'!bg-mypart_red-500': !filtersVisible() && prompterText}"
                    class="rounded-full text-gray-900 bg-white border border-gray-300 focus:outline-none
                           hover:bg-gray-100 font-medium text-xs p-1.5
                           dark:bg-gray-700 dark:text-white dark:border-gray-500 dark:hover:bg-gray-700
                           dark:hover:border-gray-600"
                    type="button">
              <svg [class.rotate-180]="filtersVisible()"
                   [ngClass]="{'!text-white': !filtersVisible() && prompterText}"
                   aria-hidden="true"
                   class="w-3 h-3 text-gray-800 dark:text-white arrow" fill="none"
                   viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg">
                <path d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2"/>
              </svg>
              <span class="sr-only">Toggle filters</span>
            </button>
            @if (isMobileDevice()) {
              <div>
                <span (click)="toggleFilters()"
                      class="w-fit text-end text-[.8rem] sm:text-sm text-gray-500 dark:text-gray-400 mx-2 cursor-pointer select-none">
              {{
                    isQuickTags
                      ? (isMobileDevice()) ? 'Search with Filters' : 'Search with Lyrical and Musical Filters'
                      : 'Refine search'
                  }}
              </span>
              </div>
            }
          </div>
        }
        @if (showBigSearchHomePage() && !(navBarService.fileUploadComplete() || navBarService.uploadStarted() || navBarService.uploadFailed())) {
          <div class="absolute top-[50px] mt-8 md:mt-0 md:top-[72px] w-full">
            <div #filtersScrollContainer
                 class="filters-container w-full flex items-center justify-between whitespace-nowrap py-4 big-search-filters">
              @for (filter of filters(); track filter.title; let first = $first; let index = $index) {
                <div #filterElement [ngClass]="{hidden: index === 1 || index === 2 || index === 3}"
                     class="custom-filter-container">
                  @if (first) {
                    <span class="select-none">Metadata</span>
                    <div (click)="openFilterDropdown(filterElement, filter, true)"
                         class="user absolute top-0 left-0 right-0 bottom-0 bg-transparent"></div>
                  } @else {
                    @if (!user() && lockedFilters().includes(filter.title)) {
                      <section class="flex" (click)="showSignUpPopup()">
                        <span class="select-none flex-grow" [title]="filter.title">{{ filter.title }}
                        </span>
                        <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg"
                             width="20" height="20" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 14v3m-3-6V7a3 3 0 1 1 6 0v4m-8 0h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Z"/>
                        </svg>
                      </section>
                    } @else {
                      <span class="select-none">{{ filter.title }}</span>
                      <div (click)="openFilterDropdown(filterElement, filter)"
                           class="user absolute top-0 left-0 right-0 bottom-0 bg-transparent"></div>
                    }
                  }
                </div>
              }
            </div>
          </div>
        }
        @if (showBigSearchHomePage()) {
          @if (navBarService.uploadStarted()) {
            <div class="absolute top-[120px] md:top-[72px] w-full text-center" [@pixelateInOut]="true">
              <div class="flex justify-center items-center">
                <p class="text-white">Creating the best prompts to match your visuals, hang tight!</p>
              </div>
              <div class="flex justify-center items-center">
                @if (uploadProgress() !== 100) {
                  <p class="text-white"> Upload is at - {{ uploadProgress() }}%</p>
                } @else {
                  <p class="text-white">
                    Analyzing visual...
                  </p>
                }
                <div class="ml-2 border-t-4 border-white border-solid rounded-full w-6 h-6 animate-spin"></div>
              </div>
            </div>
          }
          @if (navBarService.uploadFailed() && !navBarService.uploadStarted() && !fileUploadResponse()) {
            <div class="absolute top-[120px] md:top-[72px] w-full text-center">
              <p class="text-white">Upload failed. try again or try a different image/video.</p>
              <p class="mt-1 text-sm text-mypart_red-300">{{ uploadError() }}</p>
            </div>
          }
          @if (fileUploadResponse() && !navBarService.uploadStarted()) {
            <div [@pixelateInOutDelay]="true" class="absolute top-[75px] sm:top-[92px] w-full text-center">
              <p class="text-white sm:text-xl">Select a prompt below to explore song matches</p>
            </div>
          }
        }

      </form>

    </div>

  </div>

  @if (showBigSearchHomePage() && showPromptCuratedPlaylists() &&
  !fileUploadResponse() && !navBarService.uploadStarted()) {
    <mypart-featured-and-curated
        [curatedPromptPlaylists]="curatedPromptPlaylists"
        [featuredArtist]="featuredArtist"
        [isMobileDevice]="isMobileDevice"
        [searchDropDownHidden]="searchDropDownHidden"
        (promptClickedEvent)="promptClicked($event.songhuntLink, $event.featuredArtistClicked)">
    </mypart-featured-and-curated>
  }

  @if ((fileUploadResponse()) && !navBarService.uploadStarted() && showBigSearchHomePage()) {
    <mypart-upload-responses
        [fileUploadResponse]="fileUploadResponse"
        (sendToMainEvent)="sendToMain($event)">
    </mypart-upload-responses>
  }

  <!--Filters-->
  @if (filtersVisible() && !showBigSearchHomePage()) {
    <div [@expandFadeIn]="true" class="max-w-full relative z-[-1]"
         [ngClass]="{'hidden': showBigSearchHomePage()}">
      <div #filtersScrollContainer (scroll)="onFiltersScroll()"
           class="max-w-full flex items-center justify-between overflow-x-auto whitespace-nowrap
           pb-4 pt-2 results-filters-container filters-container">
        @for (filter of filters(); track filter.title; let first = $first; let last = $last; let index = $index) {
          <div #filterElement
               [ngClass]="{hidden: index === 1 || index === 2 || index === 3, '!mr-0': last}"
               class="custom-filter-container text-gray-900 dark:text-white w-full min-w-fit md:min-w-[110px]
                      px-3 py-1.5 md:py-2.5 mr-4 relative
                      bg-white hover:bg-gray-100 dark:bg-zinc-800 dark:hover:bg-gray-700 rounded-lg">
            @if (first) {
              <span class="select-none">Metadata</span>
              <div (click)="openFilterDropdown(filterElement, filter, true)"
                   class="user absolute top-0 left-0 right-0 bottom-0 bg-transparent"></div>
              @if (filter.totalActiveFilters > 0 || filters()[1].totalActiveFilters > 0 || filters()[2].totalActiveFilters > 0 || filters()[3].totalActiveFilters > 0) {
                <div (click)="clearSubFiltersFromMetadata(filters())"
                     class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500
                            border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900"
                     title="remove all filters from Metadata">
                  {{ filter.totalActiveFilters + filters()[1].totalActiveFilters + filters()[2].totalActiveFilters + filters()[3].totalActiveFilters }}
                </div>
              }
            } @else {
              @if (!user() && lockedFilters().includes(filter.title)) {
                <section class="flex" (click)="showSignUpPopup()">
                  <span class="select-none flex-grow" [title]="filter.title">{{ filter.title }}
                </span>
                  <svg class="w-5 h-5 text-gray-800 dark:text-white" aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg"
                       width="20" height="20" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M12 14v3m-3-6V7a3 3 0 1 1 6 0v4m-8 0h10a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1Z"/>
                  </svg>
                </section>

              } @else {
                <span class="select-none">{{ filter.title }}</span>
                <div (click)="openFilterDropdown(filterElement, filter)"
                     class="user absolute top-0 left-0 right-0 bottom-0 bg-transparent"></div>

                @if (filter.totalActiveFilters > 0 || (filter.title === 'Lyrical Theme' && lyricsContains())) {
                  <div (click)="clearSubFiltersFromFilter(filter)"

                       class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500
                            border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900"
                       title="remove all filters from {{filter.title}}">
                    {{ filter.totalActiveFilters | totalActiveFilters:filter.title:lyricsContains() }}
                  </div>
                }

              }
            }
          </div>
        }
      </div>

      @if (showScrollLeft) {
        <button (click)="scroll('left')"
                class="hidden md:flex absolute top-0 left-0 z-30 items-center justify-center h-full px-2 pl-0 pb-4 pt-2
             cursor-pointer group focus:outline-none"
                type="button">
        <span
            class="inline-flex items-center justify-center w-6 h-6 rounded-full dark:bg-white/40 bg-gray-800/30
                   group-hover:bg-gray-800/60 dark:group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-gray-800/70
                   dark:group-focus:ring-white group-focus:outline-none
                   dark:text-white text-gray-800 hover:text-white">
            <svg aria-hidden="true" class="w-2.5 h-2.5" fill="none"
                 viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 1 1 5l4 4" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
            </svg>
            <span class="sr-only">Scroll Left</span>
        </span>
        </button>
      }
      @if (showScrollRight) {
        <button (click)="scroll('right')"
                class="hidden md:flex absolute top-0 right-0 z-30 items-center justify-center h-full px-2 pr-0 pb-4 pt-2
            cursor-pointer group focus:outline-none"
                type="button">
        <span
            class="inline-flex items-center justify-center w-6 h-6 rounded-full dark:bg-white/30 bg-gray-800/30
                   group-hover:bg-gray-800/60 dark:group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-gray-800/70
                   dark:group-focus:ring-white group-focus:outline-none
                   dark:text-white text-gray-800 hover:text-white">
            <svg aria-hidden="true" class="w-2.5 h-2.5" fill="none"
                 viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
                <path d="m1 9 4-4-4-4" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
            </svg>
            <span class="sr-only">Scroll Right</span>
        </span>
        </button>
      }
    </div>
  }
</nav>

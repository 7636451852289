import {ChangeDetectionStrategy, Component, inject, signal} from '@angular/core';
import {GlobalStateService} from '../../../../services/global-state.service';
import {HttpReqService} from '../../../../services/http-req.service';
import {toggleTheme} from '../../../../helper/theme';
import {UserStateService} from '../../../../services/user-state.service';
import {Router} from '@angular/router';

interface PreferenceOption {
  name: string;
  options: Array<{
    value: string;
    label: string;
  }>;
  selected: string;
}

type PreferenceKey = 'theme' | 'discovery' | 'semanticsModel';

interface SelectedPreferences {
  theme: string;
  discovery: string;
  semanticsModel: string;
}

@Component({
  selector: 'mypart-profile-content-settings',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './profile-content-settings.component.html',
  styleUrl: './profile-content-settings.component.scss'
})
export class ProfileContentSettingsComponent {
  selectedPreferences: SelectedPreferences;
  preferences: PreferenceOption[] = [];
  isSaving = signal(false);
  saveSuccess = signal(false);

  private userStateService = inject(UserStateService);
  private globalStateService = inject(GlobalStateService);
  private httpReqService = inject(HttpReqService);
  private router = inject(Router);

  private preferenceUpdates = [
    {
      key: 'theme' as PreferenceKey,
      getValue: () => this.selectedPreferences.theme,
      updateState: (value: string) => this.globalStateService.appTheme.set(value as 'dark' | 'light'),
      transformValue: (value: string) => value
    },
    {
      key: 'discovery' as PreferenceKey,
      getValue: () => this.selectedPreferences.discovery,
      updateState: (value: string) => this.globalStateService.discoveryMode.set(value === 'true'),
      transformValue: (value: string) => value
    },
    {
      key: 'semanticsModel' as PreferenceKey,
      getValue: () => this.selectedPreferences.semanticsModel,
      updateState: (value: string) => this.globalStateService.semanticsModel.set(value as 'SimCSE' | 'Roberta'),
      transformValue: (value: string) => value === 'true' ? 'Roberta' : 'SimCSE'
    }
  ];

  constructor() {
    this.initializePreferences();
    this.selectedPreferences = {
      theme: this.userStateService.user()?.preferences['theme'] || 'light',
      discovery: this.userStateService.user()?.preferences['discovery'] || 'false',
      semanticsModel: this.userStateService.user()?.preferences['semanticsModel'] === 'Roberta' ? 'true' : 'false'
    };
  }

  togglePreference(preference: PreferenceOption, value: string) {
    preference.selected = value;
    const key = preference.name.toLowerCase() as keyof SelectedPreferences;
    this.selectedPreferences[key] = value;

    // Apply theme change immediately if this is a theme toggle
    if (key === 'theme') {
      const transformedValue = this.preferenceUpdates.find(p => p.key === 'theme')?.transformValue(value);
      if (transformedValue) {
        toggleTheme(transformedValue === 'light');
      }
    }

    // Apply other changes immediately
    const update = this.preferenceUpdates.find(p => p.key === key);
    if (update) {
      update.updateState(value);
    }

    // Save changes automatically
    this.savePreferences();
  }

  signOut() {
    // Clear user data
    localStorage.clear();
    this.userStateService.setUser(null);

    // Navigate to login page
    this.router.navigate(['/login']);
  }

  getSubtitle(name: string) {
    if (name === 'Theme') {
      return 'Change the theme of the app';
    }
    if (name === 'Discovery') {
      return 'Get exposed to more emerging artists and less popular songs.';
    }
    if (name === 'Multilingual') {
      return 'Discover global artists and songs in multiple languages when searching for similar lyrical themes and narratives.';
    }
    return '';
  }

  private savePreferences() {
    this.isSaving.set(true);
    this.saveSuccess.set(false);

    const preferencesToUpdate = this.preferenceUpdates.map(({key, getValue, transformValue}) => ({
      key,
      value: transformValue(getValue())
    }));

    // Update localStorage
    preferencesToUpdate.forEach(({key, value}) => {
      localStorage.setItem(key, value);
    });

    // Update server with all preferences at once
    this.httpReqService.updateUserPreferences(preferencesToUpdate)
      .subscribe({
        next: (response: any) => {
          console.log('Preferences updated successfully:', response);
          this.isSaving.set(false);
          this.saveSuccess.set(true);

          // Reset success message after 3 seconds
          setTimeout(() => {
            this.saveSuccess.set(false);
          }, 3000);
        },
        error: (error: any) => {
          console.error('Failed to update preferences:', error);
          this.isSaving.set(false);
        }
      });
  }

  private initializePreferences() {
    const cloudTheme = this.userStateService.user()?.preferences['theme'] || 'light';
    const cloudDiscovery = this.userStateService.user()?.preferences['discovery'] || 'false';
    const cloudSemanticsModel = this.userStateService.user()?.preferences['semanticsModel'] || 'SimCSE';

    this.preferences = [
      {
        name: 'Theme',
        options: [
          {value: 'dark', label: 'Dark'},
          {value: 'light', label: 'Light'}
        ],
        selected: cloudTheme
      },
      {
        name: 'Discovery',
        options: [
          {value: 'true', label: 'Enabled'},
          {value: 'false', label: 'Disabled'}
        ],
        selected: cloudDiscovery
      },
      {
        name: 'Multilingual',
        options: [
          {value: 'false', label: 'Enabled'},
          {value: 'true', label: 'Disabled'}
        ],
        selected: cloudSemanticsModel === 'Roberta' ? 'true' : 'false'
      }
    ];
  }
}

<div (outsideClick)="outsideClick()" class="menu-dropdown-container">
  <ul>
    <li>
      <a href="https://www.mypart.net/songhunt" target="_blank">About Songhunt</a>
    </li>
    <li>
      <a href="https://www.mypart.net/" target="_blank">About MyPart</a>
    </li>
    <li>
      <a href="https://www.mypart.net/request-api-access" target="_blank">API Access</a>
    </li>
    <li>
      <a (click)="navigateToPlaylists()">Top Playlists</a>
    </li>
    @if (user()) {
      <li>
        <a (click)="showAffiliationModal()">Ambassador Program</a>
      </li>
    }
    <li>
      <a href="https://www.mypart.net/contact" target="_blank">Contact</a>
    </li>
  </ul>
</div>

import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {UserStateService} from '../../../services/user-state.service';
import {Router} from '@angular/router';
import {GlobalStateService} from '../../../services/global-state.service';

interface StatCard {
  value: string | number;
  label: string;
  iconClass: string;
}

@Component({
  selector: 'mypart-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileHeaderComponent implements OnInit {
  username: string = 'Liraz';
  joinDate: string = 'October 2022';
  stats: StatCard[] = [
    {value: 4, label: 'PLAYLISTS', iconClass: 'playlist-icon'},
    {value: 4, label: 'SEARCHES', iconClass: 'search-icon'}
  ];

  private router = inject(Router);

  constructor(private userStateService: UserStateService,
              private globalStateService: GlobalStateService) {
  }

  ngOnInit(): void {
    // Get user data from UserStateService
    const user = this.userStateService.user();
    if (user) {
      this.username = user.name || user.email.split('@')[0];

      // Since we don't have createdAt in the User interface, we'll use mock data for now
      // In a real implementation, this would come from the user object
      this.joinDate = 'October 2022';
    }
  }

  getUserInitial(): string {
    return this.username ? this.username.charAt(0).toUpperCase() : 'U';
  }

  closeProfile(): void {
    this.globalStateService.showProfilePage.set(false);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NavBarComponent} from './nav-bar.component';
import {SearchListItemComponent} from './search/search-list-item/search-list-item.component';
import {MenuDropdownComponent} from './dropdowns/menu-dropdown/menu-dropdown.component';
import {FilterDropdownComponent} from './dropdowns/filters/filter-dropdown/filter-dropdown.component';
import {
  MetadataFilterDropdownComponent
} from './dropdowns/filters/metadata-filter-dropdown/metadata-filter-dropdown.component';
import {SharedModule} from '../../shared/shared.module';
import {PipeModule} from '../../pipes/pipe.module';
import {DirectiveModule} from '../../directives/directive.module';
import {TippyDirective} from '@ngneat/helipopper';
import {ReactiveFormsModule} from '@angular/forms';
import {BackgroundComponent} from './background/background.component';
import {RouterLink} from '@angular/router';
import {SearchBarComponent} from './search/search-bar/search-bar.component';
import {LogoComponent} from './logo/logo.component';
import {LoginMenuComponent} from './login-menu/login-menu.component';
import {PlaylistIconComponent} from './playlist-icon/playlist-icon.component';
import {FeaturedAndCuratedComponent} from './home/featured-and-curated/featured-and-curated.component';
import {UploadResponsesComponent} from './home/upload-responses/upload-responses.component';
import {SubtitleComponent} from './home/subtitle/subtitle.component';
import {FiltersContainerComponent} from './filters-container/filters-container.component';
import {ImageLoaderDirective} from '../../directives/image-loader.directive';
import {ProfileModule} from '../profile/profile.module';
import {FileUploadComponent} from './file-upload/file-upload.component';

@NgModule({
  declarations: [
    BackgroundComponent,
    NavBarComponent,
    SearchListItemComponent,
    MenuDropdownComponent,
    FilterDropdownComponent,
    MetadataFilterDropdownComponent,
    SearchBarComponent,
    LogoComponent,
    LoginMenuComponent,
    PlaylistIconComponent,
    FeaturedAndCuratedComponent,
    UploadResponsesComponent,
    SubtitleComponent,
    FiltersContainerComponent,
    FileUploadComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DirectiveModule,
    PipeModule,
    NgOptimizedImage,
    TippyDirective,
    ReactiveFormsModule,
    RouterLink,
    ImageLoaderDirective,
    ProfileModule
  ],
  exports: [
    NavBarComponent,
  ]
})
export class NavBarModule {
}

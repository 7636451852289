<div class="flex items-center">
  @if (user()) {
    <span (click)="showTooltip(loginTooltip)" isEllipsis
          class="cursor-pointer mb-1 text-gray-500 dark:text-gray-100 hover:underline text-sm sm:text-base">
    {{ user()!.name | titlecase }}</span>
  } @else {
    @if (!isPlaylistActive) {
      <span (click)="signIn()"
            class="cursor-pointer mb-1 text-gray-500 dark:text-gray-100 hover:underline text-sm sm:text-base">
    {{ signInOrUpgradeText() }}</span>
    }
  }

  <button #loginTooltip="tippy" (click)="showTooltip(loginTooltip)" [tp]="userMenu"
          class="flex justify-center items-center text-gray-500 dark:text-gray-100 text-sm p-2 pr-0 md:px-2"
          tpClassName="login-popper" tpTrigger="manual" tpVariation="popper" type="button">
    <span class="sr-only">Login</span>

    <svg class="w-8 h-8" fill="currentColor" preserveAspectRatio="xMidYMid meet"
         viewBox="0 0 2200.000000 2200.000000" xmlns="http://www.w3.org/2000/svg">
      @switch (userImage()) {
        @case ('login') {
          <g fill="currentColor"
             stroke="none" transform="translate(0.000000,2200.000000) scale(0.100000,-0.100000)">
            <path d="M12086 19149 c-2557 -116 -4890 -1305 -6421 -3274 -572 -736 -1039
              -1634 -1305 -2510 -42 -138 -113 -403 -134 -497 l-6 -28 573 0 574 0 22 83
              c100 379 290 865 492 1262 1074 2110 3204 3574 5654 3884 376 48 510 55 985
              55 475 0 609 -7 985 -55 2868 -363 5251 -2293 6080 -4924 139 -442 231 -899
              282 -1405 25 -249 25 -931 0 -1180 -50 -499 -142 -959 -277 -1390 -59 -189
              -161 -470 -171 -470 -3 0 -18 33 -33 73 -36 95 -184 398 -248 506 -167 284
              -411 560 -635 716 -213 148 -491 274 -713 324 -30 6 -73 16 -95 21 -102 24
              -264 40 -405 42 -227 3 -251 -7 -768 -341 -374 -241 -398 -253 -787 -399 -297
              -112 -707 -165 -1030 -133 -109 11 -300 45 -400 71 -22 6 -57 16 -79 21 -21 5
              -75 23 -120 40 -80 30 -92 35 -151 59 -16 6 -50 19 -75 27 -86 29 -263 124
              -423 226 -433 277 -497 317 -567 353 -139 73 -167 79 -337 76 -137 -2 -330
              -24 -448 -51 -22 -5 -60 -14 -85 -19 -56 -13 -225 -72 -305 -107 -108 -48
              -129 -59 -237 -122 -295 -174 -540 -424 -752 -768 -54 -88 -246 -463 -246
              -482 0 -6 -11 -38 -24 -70 -34 -80 -91 -239 -106 -293 -18 -65 -27 -94 -54
              -190 -26 -88 -100 -436 -117 -550 -6 -36 -15 -92 -20 -125 -64 -410 -79 -646
              -85 -1355 -5 -558 0 -710 32 -897 31 -188 89 -378 159 -521 19 -40 35 -74 35
              -76 0 -10 112 -184 158 -245 43 -58 49 -71 33 -71 -26 0 -279 76 -477 144
              -1804 615 -3294 1886 -4131 3526 -121 237 -222 467 -327 747 l-28 73 -594 0
              c-514 0 -594 -2 -594 -15 0 -8 32 -109 70 -225 515 -1550 1496 -2903 2835
              -3910 1798 -1351 4085 -1946 6370 -1655 2377 302 4509 1561 5871 3467 367 514
              682 1096 919 1698 517 1309 661 2755 415 4158 -177 1007 -564 1989 -1131 2870
              -657 1021 -1574 1912 -2639 2564 -1470 901 -3217 1346 -4964 1267z"/>
            <path d="M14705 16010 c-11 -5 -47 -9 -80 -9 -33 -1 -89 -6 -125 -11 -36 -6
              -99 -15 -140 -20 -66 -8 -294 -62 -375 -88 -625 -203 -1188 -653 -1543 -1234
              -66 -108 -173 -318 -192 -378 -5 -14 -24 -69 -44 -123 -41 -113 -102 -355
              -116 -460 -6 -40 -15 -110 -21 -157 -7 -47 -12 -188 -12 -315 0 -126 5 -268
              12 -315 6 -47 15 -117 21 -157 14 -110 77 -353 120 -471 22 -59 40 -110 40
              -113 0 -4 25 -60 56 -125 72 -151 186 -341 282 -469 74 -98 292 -348 317 -363
              7 -4 50 -42 96 -84 372 -340 877 -585 1359 -657 41 -7 104 -17 140 -23 87 -15
              602 -15 698 0 39 7 105 17 145 23 39 6 97 17 127 24 30 8 85 21 122 30 169 40
              453 155 614 249 240 140 412 274 614 476 283 283 445 518 615 895 56 125 141
              417 164 565 5 36 13 83 16 105 4 22 12 122 18 223 16 241 -1 535 -43 752 -30
              158 -106 406 -160 525 -109 241 -205 405 -347 591 -127 167 -388 429 -552 553
              -218 166 -473 308 -691 386 -41 15 -97 35 -125 45 -62 23 -333 85 -440 100
              -213 30 -532 47 -570 30z"/>
            <path d="M7475 13843 c-16 -3 -34 -8 -40 -13 -5 -4 -34 -11 -63 -15 -33 -4
              -63 -16 -81 -31 -16 -13 -44 -27 -63 -31 -20 -4 -47 -21 -69 -45 -20 -21 -40
              -38 -46 -38 -9 0 -59 -52 -131 -135 -9 -11 -24 -36 -33 -56 -10 -20 -22 -39
              -27 -43 -18 -11 -43 -69 -49 -116 -3 -25 -11 -54 -16 -65 -6 -11 -10 -27 -9
              -37 2 -9 -2 -19 -8 -23 -12 -7 -14 -187 -1 -200 9 -11 26 -77 36 -138 6 -39
              33 -93 66 -136 11 -13 19 -30 19 -37 0 -7 6 -17 14 -21 8 -4 31 -30 53 -58 22
              -27 235 -245 473 -482 238 -238 429 -433 424 -433 -5 0 -893 0 -1974 0 -1283
              0 -1966 4 -1968 10 -5 13 -2509 13 -2639 1 -50 -5 -102 -16 -115 -24 -12 -9
              -41 -19 -63 -23 -39 -7 -95 -33 -125 -59 -8 -7 -24 -15 -35 -19 -21 -6 -181
              -160 -226 -218 -15 -18 -33 -53 -40 -76 -11 -38 -15 -43 -36 -37 l-23 5 0
              -305 0 -306 22 3 c18 2 25 -5 38 -38 20 -53 49 -90 145 -186 77 -77 153 -138
              173 -138 5 0 15 -7 22 -14 6 -8 37 -20 68 -27 31 -6 66 -18 78 -26 37 -24 189
              -32 592 -33 250 0 382 -3 382 -10 0 -7 941 -10 2860 -10 1573 0 2861 -2 2862
              -5 4 -8 -92 -105 -103 -105 -5 0 -9 -6 -9 -12 0 -7 -16 -24 -35 -38 -18 -14
              -39 -35 -46 -46 -6 -12 -22 -27 -35 -33 -13 -5 -34 -26 -46 -46 -13 -19 -29
              -35 -36 -35 -7 0 -16 -8 -19 -17 -7 -19 -112 -123 -124 -123 -4 0 -10 -8 -13
              -17 -4 -10 -22 -28 -41 -41 -20 -12 -41 -34 -47 -47 -6 -14 -19 -27 -30 -30
              -10 -4 -18 -11 -18 -16 0 -11 -107 -117 -132 -130 -10 -5 -18 -15 -18 -22 0
              -7 -18 -25 -40 -40 -22 -16 -40 -34 -40 -42 0 -7 -7 -15 -15 -18 -8 -4 -26
              -25 -40 -48 -15 -23 -35 -44 -46 -46 -10 -3 -19 -10 -19 -17 0 -6 -20 -45 -44
              -87 -28 -50 -47 -99 -54 -139 -6 -34 -18 -75 -27 -92 -18 -36 -21 -193 -5
              -203 6 -4 10 -14 9 -23 -1 -10 3 -28 9 -42 6 -14 14 -51 17 -82 4 -32 11 -58
              16 -58 5 0 9 -6 9 -14 0 -7 7 -20 17 -27 9 -8 25 -33 35 -57 26 -58 231 -262
              263 -262 13 0 37 -12 54 -26 17 -14 46 -28 66 -31 19 -3 35 -9 35 -13 0 -4 29
              -11 65 -15 36 -3 65 -11 65 -16 0 -5 45 -9 100 -9 56 0 100 4 100 9 0 6 20 13
              45 17 25 3 48 10 51 15 3 5 18 9 33 9 33 0 87 25 94 44 3 7 23 17 44 21 24 4
              55 23 88 52 27 25 548 543 1157 1152 609 608 1123 1122 1142 1142 20 19 36 42
              36 51 0 8 14 34 30 56 17 23 30 45 30 48 0 3 6 20 14 37 8 18 22 65 32 105 10
              39 21 72 26 72 11 0 10 197 -1 204 -5 3 -16 38 -26 78 -9 40 -23 87 -31 105
              -8 17 -14 34 -14 37 0 3 -13 25 -30 48 -16 22 -30 49 -30 58 0 18 -30 50 -46
              50 -5 0 -15 13 -22 28 -6 16 -30 41 -52 58 -22 16 -40 31 -40 34 0 9 -968 974
              -979 978 -6 2 -11 7 -11 12 0 10 -122 130 -132 130 -5 0 -8 4 -8 8 0 12 -997
              1005 -1017 1013 -10 4 -29 19 -42 33 -24 26 -75 56 -93 56 -5 0 -26 13 -46 29
              -26 20 -53 30 -91 34 -29 4 -60 12 -69 19 -15 13 -206 21 -267 11z"/>
          </g>
        }
        @case ('explorer') {
          <g fill="currentColor"
             stroke="none" transform="translate(0.000000,2200.000000) scale(0.100000,-0.100000)">
            <path d="M10766 19149 c-2673 -121 -5103 -1419 -6613 -3533 -290 -406 -586
              -925 -788 -1381 -669 -1511 -836 -3189 -478 -4805 405 -1832 1482 -3480 3038
              -4650 1798 -1351 4085 -1946 6370 -1655 2377 302 4509 1561 5871 3467 367 514
              682 1096 919 1698 517 1309 661 2755 415 4158 -177 1007 -564 1989 -1131 2870
              -657 1021 -1574 1912 -2639 2564 -1470 901 -3217 1346 -4964 1267z m1084
              -1043 c765 -71 1442 -226 2115 -483 2452 -939 4184 -3050 4539 -5533 52 -361
              61 -496 61 -940 0 -444 -9 -579 -61 -940 -192 -1340 -782 -2582 -1716 -3615
              -142 -156 -456 -470 -464 -462 -2 2 -6 206 -8 453 -5 527 -21 780 -66 1084 -5
              36 -14 97 -20 135 -19 134 -29 190 -45 260 -8 39 -20 93 -25 120 -18 94 -40
              190 -65 280 -14 49 -30 108 -36 130 -66 243 -232 628 -371 864 -167 284 -411
              560 -635 716 -213 148 -491 274 -713 324 -30 6 -73 16 -95 21 -102 24 -264 40
              -405 42 -227 3 -251 -7 -768 -341 -374 -241 -398 -253 -787 -399 -297 -112
              -707 -165 -1030 -133 -109 11 -300 45 -400 71 -22 6 -57 16 -79 21 -21 5 -75
              23 -120 40 -80 30 -92 35 -151 59 -16 6 -50 19 -75 27 -87 30 -263 124 -426
              228 -433 277 -494 315 -564 351 -139 73 -167 79 -337 76 -137 -2 -330 -24
              -448 -51 -22 -5 -60 -14 -85 -19 -56 -13 -225 -72 -305 -107 -108 -48 -129
              -59 -237 -122 -295 -174 -540 -424 -752 -768 -54 -88 -246 -463 -246 -482 0
              -6 -11 -38 -24 -70 -29 -70 -89 -234 -103 -283 -6 -19 -15 -53 -21 -75 -6 -22
              -22 -78 -36 -125 -25 -88 -99 -435 -117 -550 -6 -36 -14 -90 -19 -120 -30
              -188 -66 -536 -77 -740 -10 -185 -10 -1269 -1 -1333 l8 -49 -30 19 c-16 10
              -90 67 -162 125 -1396 1113 -2306 2682 -2552 4398 -52 361 -61 496 -61 940 0
              444 9 579 61 940 355 2483 2087 4594 4539 5533 749 286 1512 448 2370 501 158
              9 880 -3 1045 -18z"/>
            <path d="M11255 16190 c-11 -5 -47 -9 -80 -9 -33 -1 -89 -6 -125 -11 -36 -6
              -99 -15 -140 -20 -66 -8 -294 -62 -375 -88 -625 -203 -1188 -653 -1543 -1234
              -66 -108 -173 -318 -192 -378 -5 -14 -24 -69 -44 -123 -41 -113 -102 -355
              -116 -460 -6 -40 -15 -110 -21 -157 -7 -47 -12 -188 -12 -315 0 -126 5 -268
              12 -315 6 -47 15 -117 21 -157 14 -110 77 -353 120 -471 22 -59 40 -110 40
              -113 0 -4 25 -60 56 -125 72 -151 186 -341 282 -469 74 -98 292 -348 317 -363
              7 -4 50 -42 96 -84 372 -340 877 -585 1359 -657 41 -7 104 -17 140 -23 87 -15
              602 -15 698 0 39 7 105 17 145 23 39 6 97 17 127 24 30 8 85 21 122 30 169 40
              453 155 614 249 240 140 412 274 614 476 283 283 445 518 615 895 56 125 141
              417 164 565 5 36 13 83 16 105 4 22 12 122 18 223 16 241 -1 535 -43 752 -30
              158 -106 406 -160 525 -109 241 -205 405 -347 591 -127 167 -388 429 -552 553
              -218 166 -473 308 -691 386 -41 15 -97 35 -125 45 -62 23 -333 85 -440 100
              -213 30 -532 47 -570 30z"/>
          </g>
        }
        @case ('hunter') {
          <g fill="currentColor"
             stroke="none" transform="translate(0.000000,2200.000000) scale(0.100000,-0.100000)">
            <path d="M10766 19149 c-2673 -121 -5103 -1419 -6613 -3533 -290 -406 -586
              -925 -788 -1381 -669 -1511 -836 -3189 -478 -4805 405 -1832 1482 -3480 3038
              -4650 1798 -1351 4085 -1946 6370 -1655 2377 302 4509 1561 5871 3467 367 514
              682 1096 919 1698 517 1309 661 2755 415 4158 -177 1007 -564 1989 -1131 2870
              -657 1021 -1574 1912 -2639 2564 -1470 901 -3217 1346 -4964 1267z m1084
              -1043 c765 -71 1442 -226 2115 -483 2452 -939 4184 -3050 4539 -5533 52 -361
              61 -496 61 -940 0 -444 -9 -579 -61 -940 -192 -1340 -782 -2582 -1716 -3615
              -142 -156 -456 -470 -464 -462 -2 2 -6 206 -8 453 -5 527 -21 780 -66 1084 -5
              36 -14 97 -20 135 -19 134 -29 190 -45 260 -8 39 -20 93 -25 120 -18 94 -40
              190 -65 280 -14 49 -30 108 -36 130 -66 243 -232 628 -371 864 -167 284 -411
              560 -635 716 -213 148 -491 274 -713 324 -30 6 -73 16 -95 21 -102 24 -264 40
              -405 42 -227 3 -251 -7 -768 -341 -374 -241 -398 -253 -787 -399 -297 -112
              -707 -165 -1030 -133 -109 11 -300 45 -400 71 -22 6 -57 16 -79 21 -21 5 -75
              23 -120 40 -80 30 -92 35 -151 59 -16 6 -50 19 -75 27 -87 30 -263 124 -426
              228 -433 277 -494 315 -564 351 -139 73 -167 79 -337 76 -137 -2 -330 -24
              -448 -51 -22 -5 -60 -14 -85 -19 -56 -13 -225 -72 -305 -107 -108 -48 -129
              -59 -237 -122 -295 -174 -540 -424 -752 -768 -54 -88 -246 -463 -246 -482 0
              -6 -11 -38 -24 -70 -29 -70 -89 -234 -103 -283 -6 -19 -15 -53 -21 -75 -6 -22
              -22 -78 -36 -125 -25 -88 -99 -435 -117 -550 -6 -36 -14 -90 -19 -120 -30
              -188 -66 -536 -77 -740 -10 -185 -10 -1269 -1 -1333 l8 -49 -30 19 c-16 10
              -90 67 -162 125 -1396 1113 -2306 2682 -2552 4398 -52 361 -61 496 -61 940 0
              444 9 579 61 940 355 2483 2087 4594 4539 5533 749 286 1512 448 2370 501 158
              9 880 -3 1045 -18z"/>
            <path d="M11255 16190 c-11 -5 -47 -9 -80 -9 -33 -1 -89 -6 -125 -11 -36 -6
              -99 -15 -140 -20 -66 -8 -294 -62 -375 -88 -191 -62 -427 -171 -590 -273 -288
              -180 -583 -448 -779 -706 l-47 -63 -279 0 -278 0 -56 -57 c-31 -32 -56 -66
              -56 -76 0 -10 -7 -20 -15 -23 -13 -5 -15 -64 -15 -445 0 -282 4 -439 10 -439
              5 0 16 -15 24 -34 8 -18 19 -37 25 -40 6 -4 11 -16 11 -27 0 -12 7 -19 18 -19
              11 0 27 -9 38 -21 12 -13 26 -18 40 -15 46 12 55 -52 13 -90 -12 -11 -16 -21
              -10 -28 5 -6 12 -135 15 -286 3 -151 10 -306 15 -345 5 -38 14 -105 20 -147
              16 -115 77 -355 121 -476 22 -59 40 -110 40 -113 0 -4 25 -60 56 -125 72 -151
              186 -341 282 -469 74 -98 292 -348 317 -363 7 -4 50 -42 96 -84 372 -340 877
              -585 1359 -657 41 -7 104 -17 140 -23 87 -15 602 -15 698 0 39 7 105 17 145
              23 39 6 97 17 127 24 30 8 85 21 122 30 169 40 453 155 614 249 240 140 412
              274 614 476 283 283 445 518 615 895 59 130 143 423 165 575 6 41 15 100 20
              130 9 53 17 209 25 434 2 59 7 112 13 118 5 5 25 8 43 6 26 -2 35 -8 37 -25 2
              -12 23 -41 48 -65 24 -23 44 -51 44 -60 0 -10 36 -54 80 -97 44 -43 80 -86 80
              -96 0 -24 59 -80 93 -89 15 -3 27 -11 27 -16 0 -17 106 -12 120 5 7 8 20 15
              29 15 21 0 61 41 61 62 0 9 7 21 15 28 8 7 15 20 15 29 0 10 14 30 30 46 17
              16 30 36 30 46 0 9 7 22 15 29 8 7 15 20 15 30 0 10 7 23 15 30 8 7 15 23 15
              35 0 13 7 25 15 29 8 3 15 17 15 31 0 14 7 28 15 31 8 4 15 17 15 30 0 14 7
              27 15 30 8 4 15 14 15 24 0 10 6 25 13 32 8 7 17 26 21 41 4 17 13 27 25 27
              25 0 33 11 20 26 -16 19 -4 44 21 44 18 0 19 3 8 20 -11 17 -8 24 20 53 30 31
              32 38 32 103 l0 70 -53 52 c-29 29 -60 52 -69 52 -8 0 -21 7 -28 15 -7 8 -20
              15 -29 15 -10 0 -32 16 -51 35 -19 19 -41 35 -51 35 -9 0 -22 7 -29 15 -7 8
              -20 15 -30 15 -10 0 -23 7 -30 15 -7 8 -23 15 -35 15 -13 0 -25 7 -29 15 -3 8
              -14 15 -25 15 -11 0 -38 18 -61 40 -35 33 -40 43 -30 55 7 8 20 15 29 15 9 0
              25 7 35 15 11 8 31 15 45 15 14 0 35 7 45 15 11 8 26 15 34 15 7 0 20 7 28 15
              9 8 24 15 34 15 10 0 20 7 24 15 3 8 16 15 29 15 14 0 30 7 37 15 7 8 20 15
              30 15 10 0 23 7 30 15 7 8 20 15 29 15 9 0 40 24 69 53 51 52 52 53 52 114 0
              45 -4 62 -15 67 -8 3 -15 17 -15 31 0 14 -7 28 -15 31 -8 4 -15 14 -15 24 0
              10 -7 25 -15 34 -8 8 -15 21 -15 29 0 8 -16 31 -35 51 -19 21 -35 46 -35 55 0
              10 -7 24 -15 31 -8 7 -15 20 -15 30 0 10 -7 23 -15 30 -8 7 -15 20 -15 30 0
              10 -7 23 -15 30 -8 7 -15 20 -15 30 0 10 -7 23 -15 30 -8 7 -15 20 -15 29 -1
              19 -34 61 -49 61 -6 0 -11 11 -11 24 0 13 -7 29 -15 36 -8 7 -15 20 -15 30 0
              10 -7 23 -15 30 -8 7 -15 21 -15 31 0 10 -5 21 -11 25 -6 3 -19 26 -30 50 -10
              25 -25 47 -34 50 -8 4 -15 14 -15 24 0 10 -7 25 -15 34 -8 8 -19 26 -25 40 -6
              16 -17 26 -30 26 -11 0 -23 7 -26 15 -5 11 -23 15 -74 15 -51 0 -69 -4 -74
              -15 -3 -8 -17 -15 -31 -15 -19 0 -25 -5 -25 -23 0 -13 -13 -33 -30 -47 -16
              -14 -30 -32 -30 -41 0 -9 -22 -40 -50 -68 -39 -41 -50 -59 -50 -84 0 -24 -9
              -41 -30 -62 -16 -16 -30 -37 -30 -47 0 -10 -7 -21 -15 -24 -8 -4 -15 -16 -15
              -29 0 -12 -7 -28 -15 -35 -8 -7 -15 -20 -15 -29 0 -10 -13 -30 -30 -46 -16
              -16 -30 -36 -30 -46 0 -9 -7 -22 -15 -29 -8 -7 -15 -19 -15 -28 0 -8 -16 -32
              -35 -53 -19 -22 -35 -46 -35 -55 0 -9 -11 -25 -25 -36 -14 -11 -25 -26 -25
              -34 0 -11 -28 -14 -158 -14 l-158 0 -64 87 c-94 126 -381 410 -529 522 -219
              166 -473 308 -691 386 -41 15 -97 35 -125 45 -62 23 -333 85 -440 100 -213 30
              -532 47 -570 30z m3380 -965 c29 -30 56 -66 59 -80 4 -14 10 -25 15 -25 5 0
              12 -13 16 -29 3 -16 13 -32 21 -35 8 -3 14 -17 14 -31 0 -14 4 -25 10 -25 5 0
              13 -12 16 -27 4 -16 14 -33 21 -38 26 -19 4 -45 -37 -45 -21 0 -43 -7 -50 -15
              -7 -8 -21 -15 -31 -15 -10 0 -20 -4 -24 -9 -7 -12 -64 -41 -80 -41 -7 0 -24
              -9 -38 -20 -14 -11 -31 -20 -38 -20 -12 0 -80 -28 -121 -51 -39 -21 -65 0 -36
              29 9 9 9 15 0 24 -27 27 -14 110 30 188 4 8 12 25 17 37 6 11 24 35 40 52 17
              18 31 38 31 46 0 8 14 28 30 45 17 17 30 40 30 50 0 11 4 20 10 20 5 0 13 12
              16 28 8 32 13 42 21 42 3 0 30 -25 58 -55z m-657 -784 c2 -161 0 -198 -13
              -217 -9 -13 -20 -24 -26 -24 -5 0 -19 -9 -31 -20 -22 -20 -35 -20 -2527 -20
              l-2506 1 -37 32 -37 32 -7 181 c-4 100 -4 193 0 208 l6 26 2588 -2 2587 -3 3
              -194z m434 -271 c8 0 23 -9 33 -20 10 -11 26 -20 35 -20 10 0 23 -7 30 -15 7
              -8 20 -15 29 -15 10 0 30 -13 46 -30 16 -16 37 -30 46 -30 10 0 20 -4 23 -9 7
              -11 87 -51 102 -51 17 0 64 -51 64 -69 0 -10 -7 -24 -15 -31 -8 -7 -15 -20
              -15 -29 0 -9 -4 -21 -9 -26 -16 -16 -61 -105 -61 -121 0 -9 -10 -14 -27 -14
              -21 0 -45 17 -96 68 -38 37 -79 84 -92 106 -13 21 -41 57 -64 81 -23 24 -41
              49 -41 57 0 8 -13 27 -30 43 -26 25 -30 35 -30 86 0 61 13 77 41 53 8 -8 22
              -14 31 -14z"/>
          </g>
        }
      }
    </svg>
  </button>
</div>


<ng-template #userMenu>
  <div (click)="loginTooltip.hide()" (outsideClick)="loginTooltip.hide()" class="login-menu-container">
    <ul>
      @if (!user()?.isSubscribed) {
        <li>
          <a (click)="signIn()">{{ signInOrUpgradeText() }}</a>
        </li>
      }

      @if (!isProd && user()) {
        <li>
          <a (click)="showProfilePage()">Profile</a>
        </li>
      }

      <li>
        <a (click)="toggleDarkMode()">
          {{ appTheme() === 'light' ? 'Dark Mode' : 'Light Mode' }}</a>
      </li>

      <li>
        <div class="flex items-center justify-between space-x-2">
          <a (click)="discoveryModeClicked()">
            {{ discoveryMode() ? 'Disable Discovery Mode' : 'Enable Discovery Mode' }}
          </a>
          <span class="cursor-pointer text-gray-400 hover:text-gray-600"
                stopPropagation
                tp="Get exposed to more emerging artists and less popular songs."
                tpClassName="mypart-tooltip"
                tpPlacement="left"
                tpTrigger="mouseenter click">?
          </span>
        </div>
      </li>

      <li>
        <div class="flex items-center justify-between space-x-2">
          <a (click)="switchAiModel()">
            {{ semanticsModel() === 'SimCSE' ? 'Enable Multilingual Mode' : 'Disable Multilingual Mode' }}
          </a>
          <span class="cursor-pointer text-gray-400 hover:text-gray-600"
                stopPropagation
                tp="Discover global artists and songs in multiple languages when searching for similar lyrical themes and narratives."
                tpClassName="mypart-tooltip"
                tpPlacement="left"
                tpTrigger="mouseenter click">?
          </span>
        </div>
      </li>

      @if (user() && (user()!.email.toLowerCase() === 'liraz@shtest.com'
        || user()!.email.toLowerCase() === 'songhunt@mypart.com'
        || user()!.email.toLowerCase() === 'songmine@mypart.com')) {
        <li>
          @if (user()!.isSpotifyConnected) {
            <a (click)="disconnectAccount('spotify')">Disconnect My Spotify</a>
          } @else {
            <a (click)="connectAccount('spotify')">Connect My Spotify</a>
          }
        </li>
        <!--        <li>-->
            <!--          @if (user && user.isYoutubeConnected) {-->
            <!--            <a (click)="disconnectAccount('youtube')">Disconnect My YouTube</a>-->
            <!--          } @else {-->
            <!--            <a (click)="connectAccount('youtube')">Connect My YouTube</a>-->
            <!--          }-->
            <!--        </li>-->
      }

      @if (user()?.isAdmin) {
        <li>
          <a (click)="goToAdminPanel()">Admin Panel</a>
        </li>
      }

      <li>
        <a (click)="logout()">Sign Out</a>
      </li>
    </ul>
  </div>
</ng-template>

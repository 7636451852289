import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

interface Playlist {
  id: string;
  title: string;
  songCount: number;
  covers: string[];
  date: string;
}

@Component({
  selector: 'mypart-profile-content-playlists',
  templateUrl: './profile-content-playlists.component.html',
  styleUrls: ['./profile-content-playlists.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileContentPlaylistsComponent implements OnInit {
  viewMode: 'grid' | 'list' = 'grid';
  playlists: Playlist[] = [
    {
      id: '1',
      title: 'Summer Vibes',
      songCount: 24,
      covers: [
        'https://images.unsplash.com/photo-1530968464165-7a1861cbaf9a?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1483412033650-1015ddeb83d1?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1493225457124-a3eb161ffa5f?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1524368535928-5b5e00ddc76b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Mar 2, 2025'
    },
    {
      id: '2',
      title: 'Chill Beats',
      songCount: 18,
      covers: [
        'https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1459749411175-04bf5292ceea?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Feb 15, 2025'
    },
    {
      id: '3',
      title: 'Workout Mix',
      songCount: 32,
      covers: [
        'https://images.unsplash.com/photo-1571019613454-1cb2f99b2d8b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1598387993281-cecf8b71a8f8?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1498038432885-c6f3f1b912ee?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Jan 30, 2025'
    },
    {
      id: '4',
      title: 'Road Trip',
      songCount: 45,
      covers: [
        'https://images.unsplash.com/photo-1493225457124-a3eb161ffa5f?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1557672172-298e090bd0f1?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1518609878373-b8491531f28b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Jan 12, 2025'
    },
    {
      id: '5',
      title: 'Late Night Vibes',
      songCount: 16,
      covers: [
        'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1504898770365-14faca6a7320?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1482442120256-9c4a5ab72d4c?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Dec 28, 2024'
    },
    {
      id: '6',
      title: 'Indie Discoveries',
      songCount: 27,
      covers: [
        'https://images.unsplash.com/photo-1510915361894-db8b60106cb1?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1514525253161-7a46d19cd819?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1494232410401-ad00d5433cfa?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1484755560615-a4c64e778a6c?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Dec 15, 2024'
    },
    {
      id: '7',
      title: 'Jazz Classics',
      songCount: 21,
      covers: [
        'https://images.unsplash.com/photo-1507838153414-b4b713384a76?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1415201364774-f6f0bb35f28f?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1511379938547-c1f69419868d?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Nov 30, 2024'
    },
    {
      id: '8',
      title: '90s Throwbacks',
      songCount: 38,
      covers: [
        'https://images.unsplash.com/photo-1496293455970-f8581aae0e3b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1516223725307-9c4a5ab72d4c?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1487180144351-b8472da7d491?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1458560871784-56d23406c091?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Nov 15, 2024'
    },
    {
      id: '9',
      title: 'Acoustic Sessions',
      songCount: 19,
      covers: [
        'https://images.unsplash.com/photo-1510915361894-db8b60106cb1?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1477233534935-f5e6fe7c1159?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1511379938547-c1f69419868d?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1460036521480-ff49c08c2781?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Oct 28, 2024'
    },
    {
      id: '10',
      title: 'Electronic Mix',
      songCount: 29,
      covers: [
        'https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1505740420928-5e560c06d30e?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1514525253161-7a46d19cd819?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1571330735066-03aaa9429d89?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
      ],
      date: 'Oct 10, 2024'
    }
  ];

  activeFilter: string = 'all';
  searchQuery: string = '';
  private readonly STORAGE_KEY = 'songhunt_playlist_view_mode';

  ngOnInit(): void {
    this.loadViewModeFromStorage();
  }

  toggleViewMode(): void {
    this.viewMode = this.viewMode === 'grid' ? 'list' : 'grid';
    this.saveViewModeToStorage();
  }

  createPlaylist(): void {
    console.log('Creating new playlist');
    // Implementation for creating a new playlist
  }

  playPlaylist(id: string): void {
    console.log(`Playing playlist with id: ${id}`);
    // Implementation for playing a playlist
  }

  showMoreOptions(id: string, event: Event): void {
    event.stopPropagation();
    console.log(`Showing more options for playlist with id: ${id}`);
    // Implementation for showing more options (edit, share, delete, etc.)
  }

  searchPlaylists(query: string): void {
    this.searchQuery = query;
    console.log(`Searching playlists with query: ${query}`);
    // Implementation for searching playlists
  }

  private loadViewModeFromStorage(): void {
    try {
      const savedViewMode = localStorage.getItem(this.STORAGE_KEY);
      if (savedViewMode && (savedViewMode === 'grid' || savedViewMode === 'list')) {
        this.viewMode = savedViewMode as 'grid' | 'list';
      }
    } catch (error) {
      console.error('Error loading view mode from storage:', error);
    }
  }

  private saveViewModeToStorage(): void {
    try {
      localStorage.setItem(this.STORAGE_KEY, this.viewMode);
    } catch (error) {
      console.error('Error saving view mode to storage:', error);
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {ToastService} from '../../../services/toast.service';
import {NavBarService} from '../nav-bar.service';
import {MobileDeviceService} from '../../../services/mobile-device.service';
import {UserStateService} from '../../../services/user-state.service';
import tippy from 'tippy.js';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mypart-file-upload',
  standalone: false,
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {
  @ViewChild('thumbnail', {static: false, read: ElementRef}) thumbnail: ElementRef | undefined;
  @ViewChild('popupContent', {static: false, read: ElementRef}) popupContent: ElementRef | undefined;
  @ViewChild('fileInput', {static: false, read: ElementRef}) fileInput: ElementRef | undefined;

  @Output() showSignUpPopupEvent = new EventEmitter<void>();
  @Output() changeFileEvent = new EventEmitter<any>();

  isMobileDevice = this.mobileDeviceService.isMobileDevice;
  user = this.userStateService.user;
  showUploadOptions = signal(false);

  // Maximum file sizes in bytes
  private readonly MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10MB
  private readonly MAX_VIDEO_SIZE = 50 * 1024 * 1024; // 50MB

  constructor(private toast: ToastService,
              private mobileDeviceService: MobileDeviceService,
              private userStateService: UserStateService,
              public navBarService: NavBarService) {
    effect(() => {
      const createPopupSignal = this.navBarService.createPopupSignal();
      if (createPopupSignal) {
        this.createPopUp();
        this.navBarService.createPopupSignal.set(false);
      }
    });
  }

  onFileChange(event: any): void {
    // if (!this.user()) {
    //   this.showSignUpPopupEvent.emit();
    //   return;
    // }

    const fileInput = event.target;

    // Check if files exist and handle mobile device issues
    if (fileInput.files && fileInput.files.length > 0) {
      this.navBarService.resetFileState();

      // Get the file and check it's valid
      const file = fileInput.files[0];

      // Log file details for debugging
      console.log('File selected:', file.name, file.type, file.size);

      // Mobile devices sometimes return empty files or files with zero size
      if (!file || file.size === 0) {
        console.error('File is empty or invalid');
        this.toast.error('The file selection failed. Please try again.');
        fileInput.value = '';
        return;
      }

      // Check file size limits
      if (file.type.startsWith('image/') && file.size > this.MAX_IMAGE_SIZE) {
        this.toast.error(`Image file is too large. Maximum size is ${this.MAX_IMAGE_SIZE / (1024 * 1024)}MB.`);
        fileInput.value = '';
        return;
      }

      if (file.type.startsWith('video/') && file.size > this.MAX_VIDEO_SIZE) {
        this.toast.error(`Video file is too large. Maximum size is ${this.MAX_VIDEO_SIZE / (1024 * 1024)}MB.`);
        fileInput.value = '';
        return;
      }

      // Reset upload progress
      this.navBarService.uploadProgress.set(0);

      // Simulate upload progress (in a real app, this would be based on actual upload progress)
      this.simulateUploadProgress();

      this.changeFileEvent.emit(file);

      // Reset the file input after handling the file
      fileInput.value = ''; // Clear the file input field

      // Hide upload options menu if it was open
      this.showUploadOptions.set(false);
    } else {
      console.error('No files received from input');
      this.toast.warning('No file was selected. Please try again.');
    }
  }

  fileUploadClicked(event: MouseEvent) {
    // if (!this.user()) {
    //   event.preventDefault();
    //   this.showSignUpPopupEvent.emit();
    //   return;
    // }

    // For mobile devices, show options menu instead of directly opening file picker
    if (this.isMobileDevice()) {
      event.preventDefault();
      this.showUploadOptions.set(!this.showUploadOptions());
    }
  }

  // Method to select from gallery (no capture)
  selectFromGallery(): void {
    this.showUploadOptions.set(false);

    // Create a temporary file input to bypass the event prevention
    const tempFileInput = document.createElement('input');
    tempFileInput.type = 'file';
    tempFileInput.accept = 'image/*,video/*';
    tempFileInput.style.display = 'none';
    document.body.appendChild(tempFileInput);

    // Add change event listener to handle file selection
    tempFileInput.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        // Process the selected file using the existing onFileChange logic
        this.onFileChange({target} as unknown as Event);
      }
      // Clean up the temporary input
      document.body.removeChild(tempFileInput);
    });

    // Trigger the file selection dialog
    tempFileInput.click();
  }

  // Method to capture a photo using native device camera
  capturePhoto(): void {
    this.showUploadOptions.set(false);

    // Create a temporary file input with camera capture
    const tempFileInput = document.createElement('input');
    tempFileInput.type = 'file';
    tempFileInput.accept = 'image/*';
    tempFileInput.capture = 'environment'; // Use the environment-facing camera
    tempFileInput.style.display = 'none';
    document.body.appendChild(tempFileInput);

    // Add change event listener to handle file selection
    tempFileInput.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        // Process the selected file using the existing onFileChange logic
        this.onFileChange({target} as unknown as Event);
      }
      // Clean up the temporary input
      document.body.removeChild(tempFileInput);
    });

    // Trigger the file selection dialog
    tempFileInput.click();
  }

  // Method to capture a video using native device camera
  captureVideo(): void {
    this.showUploadOptions.set(false);

    // Create a temporary file input with camera capture
    const tempFileInput = document.createElement('input');
    tempFileInput.type = 'file';
    tempFileInput.accept = 'video/*';
    tempFileInput.capture = 'environment'; // Use the environment-facing camera
    tempFileInput.style.display = 'none';
    document.body.appendChild(tempFileInput);

    // Add change event listener to handle file selection
    tempFileInput.addEventListener('change', (event) => {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        // Process the selected file using the existing onFileChange logic
        this.onFileChange({target} as unknown as Event);
      }
      // Clean up the temporary input
      document.body.removeChild(tempFileInput);
    });

    // Trigger the file selection dialog
    tempFileInput.click();
  }

  // Legacy methods kept for browsers that support MediaDevices API
  // These will be used as fallbacks if the native capture doesn't work
  takePhoto(): void {
    // Check if we're on iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

    if (isIOS) {
      // Use the native iOS approach
      this.capturePhoto();
      return;
    }

    this.showUploadOptions.set(false);
    // Use the MediaDevices API to access the camera
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({video: {facingMode: 'environment'}})
        .then(stream => {
          // Create a video element to show the camera feed
          const video = document.createElement('video');
          video.srcObject = stream;
          video.style.position = 'fixed';
          video.style.top = '0';
          video.style.left = '0';
          video.style.width = '100%';
          video.style.height = '100%';
          video.style.zIndex = '9999';
          video.style.backgroundColor = '#000';
          video.autoplay = true;

          // Create a canvas to capture the image
          const canvas = document.createElement('canvas');
          canvas.style.display = 'none';

          // Create a capture button
          const captureBtn = document.createElement('button');
          captureBtn.setAttribute('type', 'button'); // Set button type to prevent form submission
          captureBtn.textContent = 'Capture Photo';
          captureBtn.style.position = 'fixed';
          captureBtn.style.bottom = '20px';
          captureBtn.style.left = '50%';
          captureBtn.style.transform = 'translateX(-50%)';
          captureBtn.style.zIndex = '10000';
          captureBtn.style.padding = '12px 24px';
          captureBtn.style.backgroundColor = '#c42727';
          captureBtn.style.color = 'white';
          captureBtn.style.border = 'none';
          captureBtn.style.borderRadius = '24px';
          captureBtn.style.fontWeight = 'bold';
          // Add transition for a smoother feel
          captureBtn.style.transition = 'all 200ms ease';

          // Create a close button
          const closeBtn = document.createElement('button');
          closeBtn.setAttribute('type', 'button'); // Set button type to prevent form submission
          closeBtn.textContent = '×';
          closeBtn.style.position = 'fixed';
          closeBtn.style.top = '20px';
          closeBtn.style.right = '20px';
          closeBtn.style.zIndex = '10000';
          closeBtn.style.width = '40px';
          closeBtn.style.height = '40px';
          closeBtn.style.backgroundColor = 'rgba(0,0,0,0.5)';
          closeBtn.style.color = 'white';
          closeBtn.style.border = 'none';
          closeBtn.style.borderRadius = '50%';
          closeBtn.style.fontSize = '24px';
          // Add transition for a smoother feel
          closeBtn.style.transition = 'all 200ms ease';

          // Add elements to the DOM
          document.body.appendChild(video);
          document.body.appendChild(canvas);
          document.body.appendChild(captureBtn);
          document.body.appendChild(closeBtn);

          // Handle capture button click
          captureBtn.onclick = (e) => {
            e.preventDefault(); // Prevent any default behavior
            // Set canvas dimensions to match video
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Draw the current video frame to the canvas
            const ctx = canvas.getContext('2d');
            if (ctx) {
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

              // Convert canvas to blob
              canvas.toBlob((blob) => {
                if (blob) {
                  // Create a File object from the blob
                  const file = new File([blob], `photo_${Date.now()}.jpg`, {type: 'image/jpeg'});

                  // Clean up
                  this.cleanupCameraUI(video, canvas, captureBtn, closeBtn, stream);

                  // Process the file
                  this.processFile(file);
                }
              }, 'image/jpeg', 0.9);
            }
          };

          // Handle close button click
          closeBtn.onclick = (e) => {
            e.preventDefault(); // Prevent any default behavior
            this.cleanupCameraUI(video, canvas, captureBtn, closeBtn, stream);
          };
        })
        .catch(err => {
          console.error('Error accessing camera:', err);
          this.toast.error('Could not access camera. Please check permissions and try again.');

          // Fall back to file input
          this.capturePhoto();
        });
    } else {
      this.toast.warning('Camera access is not supported on this device or browser.');

      // Fall back to file input
      this.capturePhoto();
    }
  }

  // Method to record a video using camera
  recordVideo(): void {
    // Check if we're on iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

    if (isIOS) {
      // Use the native iOS approach
      this.captureVideo();
      return;
    }

    this.showUploadOptions.set(false);

    // Use the MediaDevices API to access the camera
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({video: {facingMode: 'environment'}, audio: true})
        .then(stream => {
          // Create a video element to show the camera feed
          const video = document.createElement('video');
          video.srcObject = stream;
          video.style.position = 'fixed';
          video.style.top = '0';
          video.style.left = '0';
          video.style.width = '100%';
          video.style.height = '100%';
          video.style.zIndex = '9999';
          video.style.backgroundColor = '#000';
          video.autoplay = true;

          // Create a record button
          const recordBtn = document.createElement('button');
          recordBtn.setAttribute('type', 'button'); // Set button type to prevent form submission
          recordBtn.textContent = 'Start Recording';
          recordBtn.style.position = 'fixed';
          recordBtn.style.bottom = '20px';
          recordBtn.style.left = '50%';
          recordBtn.style.transform = 'translateX(-50%)';
          recordBtn.style.zIndex = '10000';
          recordBtn.style.padding = '12px 24px';
          recordBtn.style.backgroundColor = '#c42727';
          recordBtn.style.color = 'white';
          recordBtn.style.border = 'none';
          recordBtn.style.borderRadius = '24px';
          recordBtn.style.fontWeight = 'bold';
          // Add transition for a smoother feel
          recordBtn.style.transition = 'all 200ms ease';

          // Create a close button
          const closeBtn = document.createElement('button');
          closeBtn.setAttribute('type', 'button'); // Set button type to prevent form submission
          closeBtn.textContent = '×';
          closeBtn.style.position = 'fixed';
          closeBtn.style.top = '20px';
          closeBtn.style.right = '20px';
          closeBtn.style.zIndex = '10000';
          closeBtn.style.width = '40px';
          closeBtn.style.height = '40px';
          closeBtn.style.backgroundColor = 'rgba(0,0,0,0.5)';
          closeBtn.style.color = 'white';
          closeBtn.style.border = 'none';
          closeBtn.style.borderRadius = '50%';
          closeBtn.style.fontSize = '24px';
          // Add transition for a smoother feel
          closeBtn.style.transition = 'all 200ms ease';

          // Add elements to the DOM
          document.body.appendChild(video);
          document.body.appendChild(recordBtn);
          document.body.appendChild(closeBtn);

          let mediaRecorder: MediaRecorder | null = null;
          let recordedChunks: BlobPart[] = [];
          let isRecording = false;

          // Handle record button click
          recordBtn.onclick = (e) => {
            e.preventDefault(); // Prevent any default behavior
            if (!isRecording) {
              // Start recording
              mediaRecorder = new MediaRecorder(stream);
              recordedChunks = [];

              mediaRecorder.ondataavailable = (e) => {
                if (e.data.size > 0) {
                  recordedChunks.push(e.data);
                }
              };

              mediaRecorder.onstop = () => {
                // Create a blob from the recorded chunks
                const blob = new Blob(recordedChunks, {type: 'video/mp4'});

                // Create a File object from the blob
                const file = new File([blob], `video_${Date.now()}.mp4`, {type: 'video/mp4'});

                // Clean up
                this.cleanupCameraUI(video, null, recordBtn, closeBtn, stream);

                // Process the file
                this.processFile(file);
              };

              mediaRecorder.start();
              isRecording = true;
              recordBtn.textContent = 'Stop Recording';
              recordBtn.style.backgroundColor = '#f44336';
            } else {
              // Stop recording
              if (mediaRecorder) {
                mediaRecorder.stop();
              }
            }
          };

          // Handle close button click
          closeBtn.onclick = (e) => {
            e.preventDefault(); // Prevent any default behavior
            this.cleanupCameraUI(video, null, recordBtn, closeBtn, stream);
          };
        })
        .catch(err => {
          console.error('Error accessing camera:', err);
          this.toast.error('Could not access camera. Please check permissions and try again.');

          // Fall back to file input
          this.captureVideo();
        });
    } else {
      this.toast.warning('Camera access is not supported on this device or browser.');

      // Fall back to file input
      this.captureVideo();
    }
  }

  // Helper method to clean up camera UI elements
  private cleanupCameraUI(video: HTMLVideoElement, canvas: HTMLCanvasElement | null, actionBtn: HTMLButtonElement, closeBtn: HTMLButtonElement, stream: MediaStream): void {
    // Remove UI elements
    if (video && video.parentNode) video.parentNode.removeChild(video);
    if (canvas && canvas.parentNode) canvas.parentNode.removeChild(canvas);
    if (actionBtn && actionBtn.parentNode) actionBtn.parentNode.removeChild(actionBtn);
    if (closeBtn && closeBtn.parentNode) closeBtn.parentNode.removeChild(closeBtn);

    // Stop all tracks in the stream
    stream.getTracks().forEach(track => track.stop());
  }

  // Helper method to process a file after capture/recording
  private processFile(file: File): void {
    // Reset file state
    this.navBarService.resetFileState();

    // Reset upload progress
    this.navBarService.uploadProgress.set(0);

    // Simulate upload progress
    this.simulateUploadProgress();

    // Emit the file change event
    this.changeFileEvent.emit(file);
  }

  // Fallback to standard file input when camera access fails
  private fallbackToFileInput(accept: string): void {
    setTimeout(() => {
      const fileInputElement = document.getElementById('file-upload') as HTMLInputElement;
      if (fileInputElement) {
        fileInputElement.accept = accept;
        fileInputElement.click();
      }
    }, 100);
  }

  // Simulate upload progress (in a real app, this would be based on actual upload progress)
  private simulateUploadProgress(): void {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 5;
      this.navBarService.uploadProgress.set(progress);
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 200);
  }

  private createPopUp() {
    setTimeout(() => {
      if (this.thumbnail && this.popupContent) {
        tippy(this.thumbnail.nativeElement, {
          content: this.popupContent.nativeElement.innerHTML,
          allowHTML: true,
          placement: 'bottom',
          theme: 'dark',
          interactive: true,
          appendTo: document.body,
        });
      }
    }, 300);
  }
}

<div [ngClass]="{
     'dark-gradient': appTheme() === 'dark',
     'light-gradient': appTheme() === 'light'}"
     class="profile-container w-full h-full flex flex-col overflow-hidden absolute inset-0 z-50">
  <div class="w-full h-full full mx-auto overflow-y-auto">
    <div class="w-full h-full max-w-full lg:max-w-[85vw] mx-auto">
      <mypart-profile-header></mypart-profile-header>
      <mypart-profile-content class="flex-1"></mypart-profile-content>
    </div>
  </div>
</div>

<div class="profile-header w-full animate-fade-in">
  <!-- Gradient background with animated pattern -->
  <div class="relative">
    <!-- Close button -->
    <button
        (click)="closeProfile()"
        class="absolute z-20 top-3 right-2.5 text-white bg-transparent hover:bg-gray-200 hover:text-gray-900
       rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        type="button">
      <svg aria-hidden="true" class="w-4 h-4" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"/>
      </svg>
      <span class="sr-only">Close modal</span>
    </button>

    <div class="header-banner h-80 w-full overflow-hidden rounded-3xl">
      <!-- Gradient background -->
      <div class="absolute inset-0 bg-gradient"></div>

      <!-- Animated pattern overlay -->
      <div class="absolute inset-0 opacity-20 pattern-overlay"></div>

      <!-- Vinyl record decorations -->
      <div class="vinyl-record vinyl-left"></div>
      <div class="vinyl-record vinyl-right"></div>
      <div class="vinyl-record vinyl-center"></div>
      <div class="vinyl-record vinyl-small-left"></div>
      <div class="vinyl-record vinyl-small-right"></div>

      <!-- Music note decorations -->
      <div class="music-note-container">
        <div class="music-note note-1">♪</div>
        <div class="music-note note-2">♫</div>
        <div class="music-note note-3">♩</div>
        <div class="music-note note-4">♬</div>
        <div class="music-note note-5">♪</div>
        <div class="music-note note-6">♫</div>
      </div>
    </div>

    <!-- Profile info section -->
    <div class="profile-info">
      <div class="avatar-container">
        <div class="avatar-glow"></div>
        <div class="avatar">
          <span class="avatar-text">{{ getUserInitial() }}</span>
        </div>
      </div>
      <div class="user-info">
        <h1 class="username">
          {{ username }}
          <span class="badge">
            <span class="badge-icon"></span>
            <span class="badge-text">Hunter</span>
          </span>
        </h1>
        <div class="member-since">
          <svg class="calendar-icon" fill="none" stroke="currentColor" stroke-linecap="round"
               stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <rect height="18" rx="2" ry="2" width="18" x="3" y="4"/>
            <line x1="16" x2="16" y1="2" y2="6"/>
            <line x1="8" x2="8" y1="2" y2="6"/>
            <line x1="3" x2="21" y1="10" y2="10"/>
          </svg>
          <p>Member since {{ joinDate }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Stats section -->
  <!--  <div class="stats-section">-->
  <!--    @for (stat of stats; track stat) {-->
  <!--      <div class="stat-card">-->
  <!--        <div [ngClass]="stat.iconClass" class="stat-icon">-->
  <!--          <span class="icon-placeholder"></span>-->
  <!--        </div>-->
  <!--        <div class="stat-info">-->
  <!--          <p class="stat-value">{{ stat.value }}</p>-->
  <!--          <p class="stat-label">{{ stat.label }}</p>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    }-->
  <!--  </div>-->
</div>

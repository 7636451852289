@if (showBigSearchHomePage) {
  <div class="bg-image-container">
    <div class="bg-image"></div>
  </div>
}
@if (isDragging()) {
  <div class="drag-overlay">
    <p class="text-[2rem] lg:text-[3rem]">Just drop your image or video here...</p>
  </div>
}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VisitService {
  private readonly key = 'resultsComponentLastVisit';

  saveVisitTimestamp(): void {
    localStorage.setItem(this.key, new Date().getTime().toString());
  }

  hasFiveDaysPassed(): boolean {
    const lastVisit = localStorage.getItem(this.key);
    if (!lastVisit) {
      return true; // Treat it as the first visit
    }

    const lastVisitTimestamp = parseInt(lastVisit, 10);
    const now = new Date().getTime();
    const fiveDaysInMs = 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds

    return now - lastVisitTimestamp > fiveDaysInMs;
  }
}

@if (totalPlaylistSongs > 0) {
  <div (click)="showPlaylistEvent.emit()"
       class="w-full flex items-center cursor-pointer text-gray-700 dark:text-gray-400
            hover:text-gray-900 hover:underline dark:hover:text-white relative mr-2"
       stopPropagation>
    <figure class="w-6 h-5 relative">
      <img imageLoader [attr.alt]="'The image shows a red icon with three horizontal bars stacked vertically on the left ' +
               'side and a downward-pointing arrow on the right side. Each element is outlined in white, making ' +
                'them stand out against the black background. The three bars represent a list, and the downward ' +
                 'arrow indicates an action related to viewing or expanding this list, such as opening or viewing ' +
                  'a playlist. The design is clean and modern, clearly communicating its function.'"
           fill="true"
           ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/mobile_playlist.png"
           priority>
    </figure>
    <div class="absolute inline-flex items-center justify-center w-5 h-5 text-[0.625rem] font-bold text-white bg-red-500
                    border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
      {{ totalPlaylistSongs }}
    </div>
  </div>
}

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

export type ProfileTab = 'playlists' | 'history' | 'insights' | 'settings';

@Component({
  selector: 'mypart-profile-content',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './profile-content.component.html',
  styleUrls: ['./profile-content.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(0)'}),
        animate('180ms ease', style({opacity: 1, transform: 'translateY(0)'}))
      ]),
      transition(':leave', [
        animate('120ms ease', style({opacity: 0, transform: 'translateY(0)'}))
      ])
    ])
  ]
})
export class ProfileContentComponent implements OnInit {
  activeTab: ProfileTab = 'playlists';

  constructor() {
  }

  ngOnInit(): void {
    // Initialize with playlists tab active
  }

  setActiveTab(tab: ProfileTab): void {
    this.activeTab = tab;
  }

  isTabActive(tab: ProfileTab): boolean {
    return this.activeTab === tab;
  }
}

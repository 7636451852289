import {animate, state, style, transition, trigger} from '@angular/animations';

export const pixelateInOutSearchBoxes = trigger('pixelateInOutSearchBoxes', [
  state('in', style({opacity: 1, filter: 'blur(0px)'})),
  transition(':enter', [
    style({opacity: 0, filter: 'blur(5px)'}),
    animate('1s ease-in', style({opacity: 1, filter: 'blur(0px)'}))
  ]),
  transition(':leave', [
    animate('1ms ease-out', style({opacity: 0, filter: 'blur(5px)'}))
  ])
]);

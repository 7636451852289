<div class="settings-container">
  <div class="preferences-list">
    @for (pref of preferences; track pref.name) {
      <div class="preference-item">
        <div class="preference-content">
          <div class="preference-info">
            <p class="preference-name">{{ pref.name }}</p>
            <p class="preference-description">{{ getSubtitle(pref.name) }}</p>
          </div>

          <div class="toggle-switch"
               (click)="togglePreference(pref, pref.selected === pref.options[0].value ? pref.options[1].value : pref.options[0].value)">
            <div class="toggle-track" [class.active]="pref.selected === pref.options[1].value">
              <div class="toggle-thumb">
                <span
                    class="toggle-text">{{ pref.selected === pref.options[0].value ? pref.options[0].label : pref.options[1].label }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  <!-- Sign Out Button -->
  <button (click)="signOut()" class="sign-out-button">Sign Out</button>
</div>

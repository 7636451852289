import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  NgZone,
  OnInit,
  QueryList,
  Renderer2,
  signal,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {HttpReqService} from '../../services/http-req.service';
import {debounceTime, distinctUntilChanged, filter, Subject, tap} from 'rxjs';
import {Artwork} from '../../interfaces/artwork';
import {LoaderService} from '../../services/loader.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {GlobalStateService} from '../../services/global-state.service';
import {Filter} from '../../interfaces/filter';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType} from '@angular/cdk/portal';
import {FilterDropdownComponent} from './dropdowns/filters/filter-dropdown/filter-dropdown.component';
import {SortBy} from '../../interfaces/sort-by';
import {FiltersStateService} from '../../services/filters-state.service';
import {getPositionForDropdown, isElementInView} from '../../helper/dropdown.helper';
import {MenuDropdownComponent} from './dropdowns/menu-dropdown/menu-dropdown.component';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {TippyDirective} from '@ngneat/helipopper';
import {MobileDeviceService} from '../../services/mobile-device.service';
import {IFrameAudioPlayerStateService} from '../../services/i-frame-audio-player-state.service';
import {
  MetadataFilterDropdownComponent
} from './dropdowns/filters/metadata-filter-dropdown/metadata-filter-dropdown.component';
import {UserStateService} from '../../services/user-state.service';
import {PrompterResult, Tags} from '../../interfaces/prompter-result';
import {gsap} from 'gsap';
import {FinalSong} from '../../interfaces/final-song';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../environments/environment.prod';
import {pixelateInOut} from '../../animations/pixelateInOut';
import {expandFadeInAnimation} from '../../animations/expandFadeInAnimation';
import {pixelateInOutDelay} from '../../animations/pixelateInOutDelay';
import {getRandomItems} from '../../helper/nav-bar-helper';
import {HttpEventType} from '@angular/common/http';
import {CuratedPlaylist} from '../../interfaces/curated-playlist';
import 'tippy.js/dist/tippy.css';
import {ToastService} from '../../services/toast.service';
import {VisitService} from '../../services/visit.service';
import {ModalType} from '../../interfaces/modal-types.enum';
import {ModalService} from '../../services/modal.service';
import {NavBarService} from './nav-bar.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    pixelateInOut,
    pixelateInOutDelay,
    expandFadeInAnimation
  ],
  standalone: false
})
export class NavBarComponent implements OnInit, AfterViewInit {
  @ViewChild('searchDropdown', {static: false, read: ElementRef}) searchDropdown: ElementRef | undefined;
  @ViewChild('editableDiv', {static: false, read: ElementRef}) editableDiv: ElementRef | undefined;
  @ViewChild('editableCopyDiv', {static: false, read: ElementRef}) editableCopyDiv: ElementRef | undefined;

  // this property is for scrolling purposes on the app.component.ts
  @ViewChild('navbar', {static: false}) navbar: ElementRef | undefined;
  @ViewChild('filtersScrollContainer', {static: false}) filtersScrollContainer: ElementRef | undefined;
  @ViewChildren('filterElement') filtersElements: QueryList<ElementRef> | undefined;

  showNothingFound = signal(false);
  curatedPromptPlaylists = signal<CuratedPlaylist[] | undefined>(undefined);
  showPromptCuratedPlaylists = signal(false);
  uploadProgress = signal<number>(0);
  isDragging = signal(false);
  totalPlaylistSongs = computed(() => {
    return this.globalStateService.playlistSongs().size;
  });
  prompterIsActive = signal(false);
  errorMessageFromPrompt = signal<string | undefined>(undefined);
  promptFromNewHomeScreen = signal<string | undefined>(undefined);


  readonly TOTAL_REQUESTS_BEFORE_POPUP = 3;
  current_requests_before_popup = 0;
  activeUrlLocation = '';
  skeletonArray = Array.from({length: 3}, (_, i) => ({[i]: i}));
  manuallySelectedArtwork = false;
  artworks: Artwork[] = [];
  showLinkHasBeenEntered = false;
  showScrollLeft = false;
  showScrollRight = false;
  exactMatchToastShown = false;
  isQuickTags = true;
  prompterText: SafeHtml | undefined;
  isTextInsideSearch = false;
  defaultSearchPlaceholder = 'Add a text description, song title, artist name, image, or video.';
  clearSearchClicked: boolean = false;

  prompterEnterPressedOccurred = false;

  isLoading = this.loader.isLoading;
  isMobileDevice = this.mobileDeviceService.isMobileDevice;
  user = this.userStateService.user;
  searchDropDownHidden = this.navBarService.searchDropDownHidden;
  fileUploadResponse = this.navBarService.fileUploadResponse;
  imagePreview = this.navBarService.imagePreview;
  videoPreview = this.navBarService.videoPreview;
  uploadError = this.navBarService.uploadError;
  file = this.navBarService.file;

  lockedFilters = this.filtersStateService.lockedFilters;
  selectedArtwork = this.filtersStateService.selectedArtwork;
  filters = this.filtersStateService.filters;
  metadataFilters = this.filtersStateService.metadataFilters;
  sortByArray = this.filtersStateService.sortByArray;
  lyricsContains = this.filtersStateService.lyricsContains;
  artistSearchStr = this.filtersStateService.artistSearchStr;
  multipleArtworksIds = this.filtersStateService.multipleArtworksIds;
  searchOnThisArtist = this.filtersStateService.searchOnThisArtist;
  narrative = this.filtersStateService.narrative;
  filtersVisible = this.filtersStateService.filtersVisible;
  artistFromUrl = this.httpReqService.artistFromUrl;
  songFromUrl = this.httpReqService.songFromUrl;
  showBigSearchHomePage = this.globalStateService.showBigSearchHomePage;
  appTheme = this.globalStateService.appTheme;
  discoveryMode = this.globalStateService.discoveryMode;
  playAllSongs = this.globalStateService.playAllSongs;
  featuredArtist = this.globalStateService.featuredArtist;

  private overlayRef: OverlayRef = this.overlay.create();
  private lastFilterClicked: string | null = null;
  private inputEvents = new Subject<string>();
  private matchingSpansStartPositions: { id: string, top: number, left: number }[] = [];
  private matchingSpansEndPositions: { id: string, top: number, left: number }[] = [];
  private nonMatchingSpansEndPositions: { id: string, top: number, left: number }[] = [];
  private currentSearchStr: string = '';
  private currentFileName: string = '';
  private animationHasStarted = false;
  private stopGetResultsFinalFlow = false;
  private artworkFromUrl: Artwork | undefined;
  private currentShowBigSearchHomePageValue = false;
  private addTopTwentySongsToPlaylistFromPrompt = false;
  private addedTopTwentySongsToPlaylistFromFirstSearch = false;
  private tempPrompterResult: PrompterResult | undefined = undefined;
  private tempSkipAnimation: boolean | undefined = undefined;

  constructor(private httpReqService: HttpReqService,
              private loader: LoaderService,
              private globalStateService: GlobalStateService,
              private filtersStateService: FiltersStateService,
              private modalService: ModalService,
              private iFrameAudioPlayerStateService: IFrameAudioPlayerStateService,
              private overlay: Overlay,
              private viewContainerRef: ViewContainerRef,
              private sanitizer: DomSanitizer,
              private mobileDeviceService: MobileDeviceService,
              private userStateService: UserStateService,
              private router: Router,
              private renderer: Renderer2,
              private toast: ToastService,
              private visitService: VisitService,
              public navBarService: NavBarService,
              private readonly ngZone: NgZone,
  ) {
    const combined = computed(() => [this.artistFromUrl(), this.songFromUrl()]);

    effect(() => {
      if (this.globalStateService.initialLoadingProcessComplete()) {
        // When the signal is true, subscribe to the latest values
        // from artistFromUrl$ and songFromUrl$
        const [searchInArtist, referenceSongIds] = combined();

        if (searchInArtist || referenceSongIds) {
          this.httpReqService.cancelRequests();
          this.getResults(false);
        }
      }
    });

    effect(() => {
      const show = this.showBigSearchHomePage();

      // Only run if the value is defined.
      if (show !== undefined) {
        if (show) {
          this.hideSearchDropdown(true);
        }
        if (show && this.promptFromNewHomeScreen()) {
          return;
        }
        if (show !== this.currentShowBigSearchHomePageValue) {
          this.currentShowBigSearchHomePageValue = show;
        } else {
          return;
        }

        this.httpReqService.cancelRequests();
        this.globalStateService.setSongs([]);
        this.stopGetResultsFinalFlow = true;

        this.hideSearchDropdown(true);

        if (show) {
          this.resetAllToStart();
        } else {
          // This is done manually to avoid glitching.
          if (this.navbar?.nativeElement.classList.contains('big-search-home-page')) {
            this.renderer.removeStyle(this.navbar!.nativeElement, 'background');
          }
          this.showPromptCuratedPlaylists.set(false);
          this.resetFile();
        }
      } else {
        this.showBigSearchHomePage.set(true);
      }
    });

    effect(() => {
      if (this.globalStateService.loadMoreSongsEvent()) {
        this.loadMore();
        this.globalStateService.loadMoreSongsEvent.set(false);
      }
    });

    effect(() => {
      if (this.globalStateService.scrollEventOnMobile() && this.filtersStateService.filtersVisible()) {
        this.toggleFilters();
        this.globalStateService.scrollEventOnMobile.set(false);
      }
    });

    effect(() => {
      if (this.globalStateService.curatedPlaylists() && !this.curatedPromptPlaylists()) {
        this.rollBigScreenPrompts(this.globalStateService.curatedPlaylists()!);
      }
    });

    effect(() => {
      const result = this.promptFromNewHomeScreen();
      if (result) {
        this.prompterEnterPressed(result);
      }
    });

    effect(() => {
      if (this.user()) {
        this.checkIfWhiteLabelDomainAndReset();
      }
    });

    effect(() => {
      const referenceSongFromUrl = this.globalStateService.referenceSongFromUrl();
      const updateGlobalStateSignal = this.globalStateService.updateGlobalStateSignal();


      if (referenceSongFromUrl && updateGlobalStateSignal) {
        if (referenceSongFromUrl > 0) {
          this.httpReqService.getArtwork(referenceSongFromUrl.toString())
            .then((artwork) => {
              this.manuallySelectedArtwork = true;
              this.filtersStateService.changeSelectedArtwork(artwork);
              this.setSearchDivValue(undefined, this.prompterIsActive());
              this.hideSearchDropdown(true);
              this.isQuickTags = false;
              this.filtersStateService.filtersVisible.set(!this.isMobileDevice());
              this.getResults();
            })
            .catch((error) => {
              this.toast.warning(`Sorry, we could not find this song, ID: ${referenceSongFromUrl.toString()}`);
              this.isQuickTags = true;
              this.clearSearchBar();
            })
            .finally(() => {
              this.getResults();
            });
          return;
        }

        if (referenceSongFromUrl === -1) {
          this.filtersStateService.filtersVisible.set(!this.isMobileDevice());
          setTimeout(() => {
            this.onFiltersScroll();
          });
        }

        if (!this.songFromUrl()) {
          this.getResults();
        }
      }
    });

    effect(() => {
      if (this.globalStateService.songs().length > 0) {
        this.checkAndAddTopSongs();
      }
    });
  }

  ngOnInit() {
    window.addEventListener('popstate', (event: any) => {
      const origin = location.origin + '/';
      const eventHref = event?.target?.location?.href;
      const currentUrl = window.location.href;

      if (eventHref === origin || eventHref === origin + 'songhunt/') {
        window.location.href = '/';
      } else {
        const topPlaylistsIncluded = currentUrl.includes('top-playlists');
        this.globalStateService.showCuratedPlaylists.set(topPlaylistsIncluded);
        this.globalStateService.setShowBigSearchHomePage(topPlaylistsIncluded, 'Navbar - popstate');
      }
    });
    window.addEventListener('resize', () => {
      // timeout is needed to initialize el element
      setTimeout(() => {
        this.onFiltersScroll();
      }, 100);
    }, true);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        this.globalStateService.resetPageNumber();
        this.checkIfIsHomePage();
        const pathParts = event.urlAfterRedirects.split('/')[1];
        if (pathParts.length > 0 && pathParts.includes('?') && !this.checkUtmTags()) {
          this.globalStateService.setShowBigSearchHomePage(false, '');
        }
        this.globalStateService.logoLocationLink.set(location.origin + '/' + pathParts);
      });
    const currentUrl = window.location.href;

    if (currentUrl.includes('top-playlists')) {
      this.globalStateService.showCuratedPlaylists.set(true);
      this.globalStateService.setShowBigSearchHomePage(false, 'Navbar - top-playlists');
    }

    if (currentUrl.includes('/similar/')) {
      const searchStr = this.extractLastSegment(currentUrl).replaceAll('-', ' ');
      this.httpReqService.getSongs(searchStr)
        .then((artworks) => {
          if (artworks.length === 0) {
            this.httpReqService.sendEventToServer('SONG_MISSING', searchStr);
          }
          this.removePlaceholder();
          this.artworkFromUrl = artworks[0];
          this.setDivSearchValueFromUrl(this.artworkFromUrl.artist + ' - ' + this.artworkFromUrl.title);

          this.isQuickTags = false;
          this.songFromUrl.set(this.artworkFromUrl.id.toString());
        });
    }

    if (currentUrl.includes('/artist/')) {
      const artist = this.extractLastSegment(currentUrl).replaceAll('-', ' ');
      this.httpReqService.freeTextQuery(artist)
        .then((prompterResult) => {
          Promise.resolve()
            .then(() => {
              const currentTag = prompterResult.tags.find((tag) => tag.isArtistSearch);
              this.artistFromUrl.set(currentTag?.replacedWith);
              if (prompterResult.errorMessage) {
                this.toast.warning(prompterResult.errorMessage);
                try {
                  this.startPrompterMagic(prompterResult);
                } catch (err) {
                  this.sendPromptError(err);
                }
                this.httpReqService.cancelRequests();
                this.getResults(false);
              }
            });
        });
    }

    this.inputEvents
      .pipe(
        debounceTime(500), // wait 1s after the last event before emitting last event
        distinctUntilChanged(), // only emit if value is different from previous value
        tap(value => {
          if (this.currentSearchStr !== value) {
            this.prompterIsActive.set(false);
          }
        }),
      )
      .subscribe(value => {
        if (this.isUrl(value)) {
          return;
        }
        if (this.file()) {
          this.uploadFile();
          return;
        }

        this.artworkFromUrl = undefined;
        this.isTextInsideSearch = value.trim() !== '';
        if (this.artworks.length > 0) {
          const artwork = this.selectedArtwork();
          if (`${artwork?.artist} - ${artwork?.title}` !== value) {
            this.artworks = [];
            this.hideSearchDropdown(true);
            this.manuallySelectedArtwork = false;
          }
        }
        if (value.trim() === '') {
          this.resetPrompter();
        }

        // Check if the user has deleted all text or value is null
        if (value && value.trim()) {
          // Handle valid value
          this.showNothingFound.set(false);
          this.getSongs(value);
          this.hideSearchDropdown(false);
        } else {

        }
      });
  }

  ngAfterViewInit() {
    this.globalStateService.navBar = this.navbar;

    this.filtersStateService.prompterText$
      .pipe(distinctUntilChanged())
      .subscribe((text) => {
        this.prompterText = text ? this.sanitizer.bypassSecurityTrustHtml(text) : undefined;
        this.isTextInsideSearch = !!(text && text !== '');

        if (this.tempPrompterResult) {
          this.finishPrompterMagic(this.tempPrompterResult, this.tempSkipAnimation);
        }

        if (!this.animationHasStarted) {
          this.setSearchDivValue(undefined, this.prompterIsActive());
          if (this.editableCopyDiv!.nativeElement.innerHTML !== '') {
            this.showNewPromptOnDiv();
          }
        }
      });

    this.addPlaceholder();
  }

  toggleDiscoveryMode() {
    this.getResults();
  }

  onDivInput(event: any): void {
    if (this.editableDiv!.nativeElement.innerText.trim() === '' &&
      (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward')) {
      return;
    }

    const value: string = event.target.innerText;

    if (value.length > 150) {
      // Truncate the text to the max length
      this.editableDiv!.nativeElement.innerText = value.substring(0, 100);
      // Move the cursor to the end
      this.moveCursorToEnd(event.target);
      return;
    }

    this.inputEvents.next(value);
  }

  onFiltersScroll() {
    if (this.filtersScrollContainer) {
      setTimeout(() => {
        const container = this.filtersScrollContainer!.nativeElement;
        const scrollLeftPos = container.scrollLeft;
        const scrollRightPos = container.scrollWidth - container.clientWidth - scrollLeftPos;

        this.showScrollLeft = scrollLeftPos > 0;
        this.showScrollRight = scrollRightPos > 1;
      });
    }
  }

  scroll(direction: 'left' | 'right'): void {
    if (this.filtersScrollContainer) {
      const container = this.filtersScrollContainer.nativeElement;

      // Define the amount to scroll in pixels
      const scrollAmount = 100;

      // Calculate the new scroll position
      let newScrollLeft: number;

      if (direction === 'left') {
        newScrollLeft = container.scrollLeft - scrollAmount;
        // Ensure we don't scroll past the start
        if (newScrollLeft < 0) {
          newScrollLeft = 0;
        }
      } else {
        newScrollLeft = container.scrollLeft + scrollAmount;
        // Ensure we don't scroll past the end
        const maxScrollLeft = container.scrollWidth - container.clientWidth;
        if (newScrollLeft > maxScrollLeft) {
          newScrollLeft = maxScrollLeft;
        }
      }

      // Scroll the container to the new position
      container.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });

      // Update any scroll-dependent logic
      this.onFiltersScroll();
    }
  }

  outsideClickEvent() {
    this.hideSearchDropdown(true);
  }

  showSearchDropDown() {
    this.closeOverlay();
    if (this.isTextInsideSearch) {
      if (!this.errorMessageFromPrompt() && !this.showLinkHasBeenEntered) {
        if (this.artworks.length === 0) {
          this.showNothingFound.set(true);
        }
        this.hideSearchDropdown(false);
      }
    }
  }

  resetData(sortByChanged = false) {
    this.globalStateService.resetPageNumber();
    if (!sortByChanged) {
      this.filtersStateService.resetSelectedFilters();
    }
  }

  selectArtwork(artwork: Artwork) {
    this.globalStateService.artistPages = -1;
    this.multipleArtworksIds.set(undefined);
    if (this.prompterIsActive()) {
      this.changeArtworkInPrompter(artwork);
      return;
    }
    if (!this.filtersStateService.filtersVisible()) {
      this.toggleFilters();
    }
    const selected = this.selectedArtwork();
    if (selected?.id === artwork.id && !this.isQuickTags) {
      this.hideSearchDropdown(true);
      return;
    }

    this.filtersStateService.changeSelectedArtwork(artwork);

    this.setSearchDivValue(undefined);
    this.manuallySelectedArtwork = true;
    this.hideSearchDropdown(true);
    this.isQuickTags = false;

    this.resetData();

    this.filtersStateService.resetSortBy();
    this.filtersStateService.resetFilters();

    this.httpReqService.constructFiltersAndNavigate();
  }

  getResults(isLoadMore = false, comesFromSort = false) {
    setTimeout(() => {
      Promise.resolve().then(() => {
          if (!this.globalStateService.showBigSearchHomePage()) {
            const searchInArtist = this.artistFromUrl();
            const referenceSongIds = this.songFromUrl();

            if (searchInArtist) {
              this.httpReqService.clearArtistFromUrl.set(false);
            }
            if (referenceSongIds) {
              this.httpReqService.clearSongFromUrl.set(false);
            }

            if (comesFromSort && this.artworkFromUrl) {
              this.httpReqService.clearSongFromUrl.set(true);
              this.artworkFromUrl = undefined;
            }

            this.playAllSongs.set(false);
            if (!isLoadMore) {
              this.iFrameAudioPlayerStateService.setSongAndPosition(undefined, undefined);
              this.globalStateService.highlightedSong.set(undefined);
            }
            let selectedId =
              this.multipleArtworksIds() || this.selectedArtwork()?.id.toString();

            if (referenceSongIds) {
              selectedId = referenceSongIds;
            }

            this.globalStateService.setSongs([]);
            // Use a microtask
            Promise.resolve().then(() => {
              this.stopGetResultsFinalFlow = false;
              const req = selectedId
                ? this.httpReqService.getResultsOs(selectedId)
                : this.httpReqService.getQuickTags(searchInArtist);
              req
                .then((finalSongs) => {
                  if (!this.stopGetResultsFinalFlow) {
                    this.startFinalSongsFlow(finalSongs, isLoadMore, selectedId!);
                  }
                })
                .catch((err: any) => {
                  console.log('Get Results Error:');
                  let logError = true;
                  if (err.name) {
                    console.log(err.name);
                    logError = false;
                  }
                  if (err.message) {
                    console.log(err.message);
                    logError = false;
                  }
                  if (logError) {
                    console.log(err);
                  }

                  if (err.name && err.name !== 'EmptyError' && err.name !== 'Cancelled') {
                    this.globalStateService.setSongsLoading(false);
                  } else if (err.message === 'results is undefined') {
                    this.globalStateService.setSongsLoading(false);
                    this.globalStateService.noMoreResultsMessage.set('We have some problems with the search. Try tweaking your search.');
                    this.globalStateService.showNoMoreResults.set(true);
                  }
                })
                .finally(() => {
                  this.promptFromNewHomeScreen.set(undefined);
                  this.checkRequestsForPopup();
                  if (searchInArtist) {
                    this.httpReqService.clearArtistFromUrl.set(true);
                  }
                  if (referenceSongIds) {
                    this.httpReqService.clearSongFromUrl.set(true);
                  }
                });
            });
          }
        }
      );
    }, 10);
  }

  loadMore() {
    this.globalStateService.addPageNumber();
    this.getResults(true);
  }

  openFilterDropdown(filterElement: HTMLElement, filter: Filter, isMetadata = false, isInputFilter = false) {
    const isSameFilter = this.lastFilterClicked === filter.title;

    if (this.overlayRef && isSameFilter) {
      return;
    }
    this.lastFilterClicked = filter.title;

    // Create the overlay
    this.overlayRef = this.createOverlay();

    // Check if the filter element is in view
    const isInView = isElementInView(this.filtersScrollContainer!.nativeElement, filterElement);

    if (!isInputFilter && (!isInView || this.mobileDeviceService.isMobileDevice())) {
      // Scroll the filter element into view
      filterElement.parentElement!.scrollTo({left: filterElement.offsetLeft, behavior: 'smooth'});
    }

    const filterComponent = isMetadata ? MetadataFilterDropdownComponent : FilterDropdownComponent;

    // Attach the dropdown and get the component
    const component = this.attachDropdown(this.overlayRef, filterComponent);

    // Set initial off-screen position to avoid visual glitch
    component.instance.setPositionOffScreen();

    const timeout = isInputFilter ? 0 : this.mobileDeviceService.isMobileDevice() ? 300 : isInView ? 0 : 300;
    setTimeout(() => {
      const rect = filterElement.getBoundingClientRect();
      const dropdownPosition = getPositionForDropdown(this.filtersScrollContainer!.nativeElement, filterElement);

      if (isMetadata && !isInputFilter) {
        component.instance.setPositionLeft(rect.bottom, rect.left);
      } else {
        switch (dropdownPosition) {
          case 'left':
            component.instance.setPositionRight(rect.bottom, window.innerWidth - rect.right);
            break;
          case 'right':
            component.instance.setPositionLeft(rect.bottom, rect.left);
            break;
          default:
            component.instance.setPositionCenter(rect.bottom, rect.left, rect.width);
        }
      }
    }, timeout);

    if (isMetadata) {
      component.instance.filters = this.metadataFilters();
    } else {
      component.instance.filter = filter;
    }

    if (isInputFilter) {
      component.changeDetectorRef.markForCheck();
      component.instance.isInputFilter = true;
    }

    component.instance.filtersClicked
      .pipe(debounceTime(150))
      .subscribe(() => {
        this.filtersWereClicked();
      });
  }

  openMenuDropdown(menu: HTMLElement) {
    this.overlayRef = this.createOverlay();

    const component = this.attachDropdown(this.overlayRef, MenuDropdownComponent);

    const rect = menu.getBoundingClientRect();
    component.instance.setPosition(rect.bottom, 0);
  }

  selectSortBy(sortBy: SortBy, tippy: TippyDirective) {
    tippy.hide();
    if (this.artworkFromUrl) {
      this.filtersStateService.changeSelectedArtwork(this.artworkFromUrl);
    }
    this.filtersStateService.changeSelectedSort(sortBy);
    this.resetData(true);
    this.getResults(false, true);
  }

  clearSubFiltersFromFilter(filter: Filter) {
    if (filter.title === 'Lyrical Theme') {
      this.filtersStateService.lyricsContains.set(undefined);
    }
    this.filtersStateService.clearSubFiltersFromFilter(filter);
    this.filtersClickedEvent();
    this.showNewPromptOnDiv();
  }

  clearSubFiltersFromMetadata(filters: Filter[]) {
    this.metadataFilters().forEach((f) => {
      if (f.totalActiveFilters > 0) {
        this.clearSubFiltersFromFilter(f);
      }
    });
  }

  toggleFilters() {
    this.filtersStateService.filtersVisible.set(!this.filtersStateService.filtersVisible());
    if (this.filtersStateService.filtersVisible()) {
      setTimeout(() => {
        this.onFiltersScroll();
      });
    }
  }

  addPlaceholder(): void {
    const div = this.editableDiv!.nativeElement;
    if (!div.innerText.trim()) {
      div.classList.add('placeholder');
      div.innerText = this.defaultSearchPlaceholder;
      div.blur();
    }
  }

  removePlaceholder(): void {
    const div = this.editableDiv?.nativeElement;
    if (div?.classList.contains('placeholder')) {
      div.classList.remove('placeholder');
      div.innerText = '';
    }
  }

  clearSearchBar(getResults = false, text = '') {
    const isInHomePage = window.location.pathname === '/' && window.location.search === '';
    this.clearSearchClicked = true;
    if (isInHomePage) {
      this.navBarService.resetFileState();
      this.fileUploadResponse.set(undefined);
      this.imagePreview.set(undefined);
      this.videoPreview.set(undefined);
      this.resetAllToStart(text);
      return;
    }
    this.router.navigateByUrl('/').then(() => {
      this.globalStateService.setShowBigSearchHomePage(false, 'clearSearchBar');
      this.removeAllFilters();
      this.globalStateService.resetPageNumber();
      this.clearSearchClicked = true;

      if (this.httpReqService.searchOrigin !== 'photo_upload' && this.httpReqService.searchOrigin !== 'video_upload') {
        this.fileUploadResponse.set(undefined);
        this.imagePreview.set(undefined);
        this.videoPreview.set(undefined);
      }

      this.resetAllToStart();
      if (getResults) {
        this.getResults();
      }
    });
  }

  addTopTwentySongsToPlaylist(origin: string) {
    this.globalStateService.addTopTwentySongsToPlaylist();

    const user = this.userStateService.user();

    this.httpReqService.sendEventToServer(origin, {
      'email': user ? user.email : 'no user',
      'artworkIds': this.globalStateService.getArtworkIdsFromPlaylist(),
      'url': window.location.href
    });

    if (this.globalStateService.totalPlaylistSongs > 80) {
      this.toast.warning('You can add a maximum of 100 songs to a playlist');
      return;
    }
  }

  getSearchLink(): string {
    return window.location.href;
  }

  submitSong() {
    if (!this.user()) {
      this.openUpgradeLogin('Sign Up for FREE to search for ANY song, even if it\'s not in our library', false);
      return;
    }

    if (this.user()!.isSubscribed || this.user()!.uploadedArtworkIds.length < 3) {
      this.modalService.open(ModalType.YOUTUBE_SUGGESTIONS, {
        inputs: {
          searchStr: this.editableDiv!.nativeElement.innerText
        }
      });
    } else {
      this.modalService.open(ModalType.LOGIN_REGISTER, {});
    }
  }

  removeAllFilters() {
    this.resetPrompter();
    this.setSearchDivValue('');
    this.filtersStateService.resetSelectedFilters();
    this.filtersStateService.resetSortBy();
    this.httpReqService.constructFiltersAndNavigate();
  }

  togglePlayAllSongs() {
    if (this.playAllSongs()) {
      this.playAllSongs.set(false);
      this.toast.info('Play all songs is not active');
    } else {
      this.playAllSongs.set(true);
      this.toast.info('Play all songs is active');
    }
  }

  onPaste() {
    setTimeout(() => {
      if (this.isUrl(this.editableDiv!.nativeElement.innerText)) {
        return;
      }
    });
  }

  handleKeydown(event?: KeyboardEvent): void {
    if (event?.key === 'Enter') {
      // Prevent the default Enter behavior which is inserting a new line
      event.preventDefault();
    }
    setTimeout(() => {
      if (event) {
        if (event.key === 'Enter') {
          // Prevent the default Enter behavior which is inserting a new line
          event.preventDefault();
          if (this.isUrl(this.editableDiv!.nativeElement.innerText)) {
            return;
          }
          if (this.file()) {
            this.uploadFile();
            return;
          }
          this.httpReqService.searchOrigin = 'free_text';
          this.prompterEnterPressed();
          return;
        }
      } else {
        if (this.isUrl(this.editableDiv!.nativeElement.innerText)) {
          return;
        }
        // Prevent the default Enter behavior which is inserting a new line
        this.prompterEnterPressed();
        return;
      }
    });
  }

  prompterEnterPressed(textFromUploadedImage?: string) {
    this.prompterEnterPressedOccurred = true;
    setTimeout(() => {
      this.closeSearchDropdownAndHideNothingFound();
    }, 300);

    const text = textFromUploadedImage ? textFromUploadedImage : this.editableDiv!.nativeElement.textContent.trim();

    if (this.file()) {
      this.uploadFile();
      this.prompterEnterPressedOccurred = false;
      return;
    }
    if (this.isUrl(text)) {
      this.prompterEnterPressedOccurred = false;
      return;
    }

    this.globalStateService.artistPages = -1;

    this.clearSearchBar(!this.showBigSearchHomePage(), text);
    Promise.resolve().then(() => {
      if (this.showBigSearchHomePage()) {
        this.showNothingFound.set(false);
      }
      this.hideSearchDropdown(true);
      this.httpReqService.freeTextQuery(text)
        .then((prompterResult) => {
          this.prompterEnterPressedOccurred = false;
          this.selectedArtwork.set(undefined);
          if (prompterResult.artworkNotAvailable) {
            if (this.user()) {
              this.showNothingFound.set(true);
              this.hideSearchDropdown(false);
            } else {
              this.openUpgradeLogin('Sign Up for FREE to search for ANY song, even if it\'s not in our library', false);
              return;
            }
          }
          if (prompterResult.errorMessage) {
            this.toast.warning(prompterResult.errorMessage);
          }
          // this.editableDiv!.nativeElement.style.height = '';
          try {
            this.startPrompterMagic(prompterResult);
          } catch (err) {
            this.sendPromptError(err);
          }
        })
        .catch((err) => {
          if (err.message.startsWith('HTTP error:')) {
            // This is specifically an HTTP error
            try {
              this.toast.warning('Oops, we can’t understand your query');
            } catch (err) {
              console.log(err);
            }
          } else {
            // Handle non-HTTP errors differently if necessary
            console.error('Processing error:', err);
          }
          // If we want error loges uncomment this.
          // this.sendPromptError(err)
        });
    });
  }

  closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.lastFilterClicked = null;
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    this.isDragging.set(true);
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.isDragging.set(false);
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isDragging.set(false);

    // if (!this.user()) {
    //   this.showSignUpPopup();
    //   return;
    // }

    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const files = event.dataTransfer?.files;
      if (files) {
        this.navBarService.resetFileState();
        this.changeFile(files[0]);
      }
    }
  }

  uploadFile(): void {
    const file = this.file();
    if (file) {
      let uploadUrl = environment.baseUrlWithApi;
      if (this.navBarService.isImageFile()) {
        uploadUrl += 'free-text/find-by-image';
      } else if (this.navBarService.isVideoFile()) {
        uploadUrl += 'free-text/find-by-video';
      } else {
        if (this.navBarService.isAudioFile()) {
          const format = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();

          this.toast.warning(`${format.toUpperCase()} Format not supported. Type in song title and artist to find your song.`);
        } else {
          this.toast.error('This file is not supported');
        }
        this.resetFile();
        this.setSearchDivValue('');
        this.setContentEditable(true);
        setTimeout(() => {
          this.addPlaceholder();
        });
        return;
      }

      this.navBarService.uploadStarted.set(true);
      if (!this.showBigSearchHomePage()) {
        this.globalStateService.setShowBigSearchHomePage(true, 'Navbar - uploadFile');
      }
      this.editableDiv!.nativeElement.innerText = this.truncateFileName(file.name);
      this.editableDiv!.nativeElement.classList.add('placeholder');
      const formData = new FormData();
      formData.append('file', file);

      this.httpReqService.uploadFile(uploadUrl, formData)
        .subscribe({
          next: event => {
            if (event.type === HttpEventType.UploadProgress) {
              if (event.total) {
                const progress = Math.round(100 * event.loaded / event.total);
                this.uploadProgress.set(progress);
              }
            } else if (event.type === HttpEventType.Response) {
              const response: any = event.body;

              this.navBarService.uploadStarted.set(false);
              this.navBarService.fileUploadComplete.set(true);

              this.showPromptCuratedPlaylists.set(false);

              this.fileUploadResponse.set([
                {key: response.data.reasoning1, value: response.data.description1},
                {key: response.data.reasoning2, value: response.data.description2},
                {key: response.data.reasoning3, value: response.data.description3}
              ]);
            }
          },
          error: err => {
            console.error('File upload failed: ', err);
            this.navBarService.uploadFailed.set(true);
            this.navBarService.uploadStarted.set(false);
            this.uploadError.set(err.error.error.message || err.message);

            this.imagePreview.set(undefined);
            this.videoPreview.set(undefined);
            this.navBarService.fileUploadComplete.set(false);
            this.uploadProgress.set(0);
            this.fileUploadResponse.set(undefined);
            this.file.set(null);
            this.setSearchDivValue('');
            this.setContentEditable(true);
            setTimeout(() => {
              this.addPlaceholder();
            });

            this.showPromptCuratedPlaylists.set(true);
          },
          complete: () => {
            this.file.set(null);
          }
        });
      return;
    }
  }

  sendToMain(shortPrompt: string | undefined) {
    if (this.navBarService.isImageFile()) {
      this.httpReqService.searchOrigin = 'photo_upload';
    }
    if (this.navBarService.isVideoFile()) {
      this.httpReqService.searchOrigin = 'video_upload';
    }

    this.file.set(null);
    this.filtersStateService.prompterText$.next(shortPrompt);
    this.promptFromNewHomeScreen.set(shortPrompt);
    this.globalStateService.setShowBigSearchHomePage(false, 'Navbar - sendToMain');
  }

  promptClicked(songhuntLink: string, featuredArtistClicked = false) {
    this.httpReqService.searchOrigin = 'playlist_clicked';
    this.closeSearchDropdownAndHideNothingFound();
    this.setSearchDivValue('');
    this.setContentEditable(true);
    this.addPlaceholder();
    let queryString = '';

    if (featuredArtistClicked) {
      queryString = 'searchInArtist=' + this.featuredArtist()!.artistName;
    } else {
      songhuntLink = songhuntLink.replace(/%20/g, ' ');
      songhuntLink = songhuntLink.replace(/%22/g, '"');
      queryString = songhuntLink.split('?')[1];
    }
    // Split the query string into individual parameters
    const queryParams = queryString.split('&').reduce((acc, part) => {
      const [key, value] = part.split('=');
      acc[key] = value;
      return acc;
    }, {} as any);

    // this.globalStateService.setShowBigSearchHomePage(false, 'Navbar - promptClicked');
    this.showPromptCuratedPlaylists.set(false);

    this.router.navigate([''], {queryParams}).then();
  }

  closeSearchDropdownAndHideNothingFound() {
    this.showNothingFound.set(false);
    this.hideSearchDropdown(true);
  }

  showPlaylist() {
    this.closeOverlay();
    this.globalStateService.showPlaylist.set(true);
  }

  goBackToHomePage() {
    window.location.href = '/';
  }

  showSignUpPopup() {
    if (!this.user()) {
      this.openUpgradeLogin('Sign Up for FREE for unlimited access to Songhunt', false, true);
    }
  }

  searchIconEvent() {
    this.httpReqService.searchOrigin = 'free_text';
    this.prompterEnterPressed();
  }

  isIphone() {
    return /iPhone/.test(navigator.userAgent) && !(window as any).MSStream;
  }

  changeFile(file?: File) {
    const changeFileHappened = this.navBarService.changeFile(this.isMobileDevice, this.toast, file);
    if (changeFileHappened) {
      this.editableDiv!.nativeElement.innerText = this.truncateFileName(file!.name);
      this.setContentEditable(false);
      this.prompterEnterPressed();
    }
  }

  private openUpgradeLogin(loginPopupTitle: string, onlyExplorer: boolean, hideCloseButton: boolean = false): void {
    this.modalService.open(ModalType.LOGIN_REGISTER, {
      inputs: {
        loginPopupTitle,
        onlyExplorer,
        hideCloseButton
      }
    });
  }

  private resetAllToStart(text = '') {
    this.globalStateService.artistPages = -1;
    this.globalStateService.totalTimesNoSongsFound = 0;
    // this is done manually to avoid glitching
    setTimeout(() => {
      if (this.navbar?.nativeElement.classList.contains('big-search-home-page')) {
        this.renderer.setStyle(this.navbar.nativeElement, 'background', 'transparent');
      }
    }, 300);
    this.closeSearchDropdownAndHideNothingFound();

    this.errorMessageFromPrompt.set(undefined);
    this.globalStateService.highlightedSong.set(undefined);
    this.iFrameAudioPlayerStateService.selectedSongSignal.set(undefined);

    this.globalStateService.setSongs([]);
    setTimeout(() => {
      this.globalStateService.setSongsLoading(false);
    }, 0);

    if (this.filtersStateService.filtersVisible()) {
      this.toggleFilters();
    }
    this.globalStateService.showNoMoreResults.set(false);
    this.globalStateService.artistPages = -1;

    if (!this.clearSearchClicked || this.showBigSearchHomePage()) {
      this.showPromptCuratedPlaylists.set(true);
    }
    if (!this.navBarService.uploadStarted()) {
      if (this.fileUploadResponse()) {
        setTimeout(() => {
          this.editableDiv!.nativeElement.innerText = this.truncateFileName(this.file()?.name);
          this.editableDiv!.nativeElement.classList.add('placeholder');
          this.setContentEditable(false);
        }, 1000);
      } else {
        if (text === '') {
          this.setSearchDivValue('');
          this.setContentEditable(true);
        }
        setTimeout(() => {
          this.addPlaceholder();
        });
      }
    }
    this.isTextInsideSearch = false;
  }

  private truncateFileName(fileName?: string, maxLength: number = 15): string {
    if (!fileName) {
      return this.currentFileName;
    }
    if (fileName.length <= maxLength) {
      this.currentFileName = fileName;
    } else {
      this.currentFileName = fileName.substring(0, maxLength) + '...';
    }
    return this.currentFileName;
  }

  private setContentEditable(isEditable: boolean): void {
    if (this.editableDiv) {
      this.renderer.setAttribute(this.editableDiv!.nativeElement, 'contenteditable', isEditable.toString());
    }
  }

  private createOverlay(): OverlayRef {
    this.closeOverlay();

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    overlayRef.backdropClick().subscribe(() => {
      this.closeOverlay();
    });

    return overlayRef;
  }

  private attachDropdown(overlayRef: OverlayRef, portal: ComponentType<any>) {
    const dropdownPortal = new ComponentPortal(portal, this.viewContainerRef);
    const component = overlayRef.attach(dropdownPortal);

    component.instance.outsideDropdownClick.subscribe(() => {
      this.closeOverlay();
    });

    return component;
  }

  private hideSearchDropdown(hide: boolean) {
    this.navBarService.setSearchDropDownHidden(hide);
    if (!hide) {
      setTimeout(() => {
        if (this.searchDropdown) {
          this.searchDropdown.nativeElement.scrollTo(0, 0);
        }
      }, 100);
    }
  }

  private getSongs(searchStr: string | null) {
    if (this.prompterEnterPressedOccurred) {
      return;
    }
    // version with Frontend prompter
    if (searchStr) {
      this.currentSearchStr = searchStr;
      this.httpReqService.getSongs(searchStr)
        .then((songs) => {
          if (songs.length === 0) {
            this.prompterIsActive.set(true);
            this.hideSearchDropdown(true);
            this.checkInput(searchStr);
            this.httpReqService.sendEventToServer('SONG_MISSING', searchStr);
          }
          this.artworks = songs;
        });
    } else {
      this.artworks = [];
    }
  }

  private startPrompterMagic(prompterResult: PrompterResult) {
    this.ngZone.runOutsideAngular(() => {
      requestAnimationFrame(() => {
        this.animationHasStarted = true;
        this.resetData();
        let skipAnimation: boolean | undefined = false;
        let artistSearchStr: string | undefined;
        let multipleArtworksIds: string | undefined;
        let searchOnThisArtist: string | undefined;
        if (prompterResult.sortById) {
          const sortBy = this.filtersStateService.getSortById(prompterResult.sortById);
          this.filtersStateService.changeSelectedSort(sortBy);
        }
        this.errorMessageFromPrompt.set(prompterResult.errorMessage);
        if (prompterResult.errorMessage) {
          this.showNothingFound.set(true);
          this.toast.warning(prompterResult.errorMessage);
        }
        if (prompterResult.lyricsContains) {
          this.lyricsContains.set(prompterResult.lyricsContains);
        }
        prompterResult.tags.map((tag) => {
          if (tag.genresOperator) {
            this.filtersStateService.tagsWithOperator.push(tag);
          }
          if (tag.filterId) {
            const filterFromDictionary = this.filtersStateService.filtersDictionaryForPrompter[+tag.filterId!];
            if (!filterFromDictionary) {
              return;
            }
            if (tag.originalValueFromSearch || tag.originalValueFromSearch === '') {
              tag.replacedWith = filterFromDictionary.name.replace(/.*?:\s*/, '');
              tag.filterFamilyName = filterFromDictionary.familyName;
            }
            const filter = this.filters().find((f) => f.title === filterFromDictionary.familyName)!;
            const subFilter = filter.filters.find((f) => f.id === +tag.filterId!)!;
            this.filtersStateService.addOrChangeSubFilterSelectedFilters(
              filter, subFilter, tag.state ? 'green' : 'red', false, tag.genresOperator);
          }

          if (tag.selectedArtwork) {
            tag.replacedWith = `${tag.selectedArtwork.artist} - ${tag.selectedArtwork.title}`;
            this.manuallySelectedArtwork = true;
            this.filtersStateService.changeSelectedArtwork(tag.selectedArtwork);
            if (!tag.originalValueFromSearch) {
              skipAnimation = true;
            }
          }

          if (tag.isArtistSearch) {
            artistSearchStr = tag.replacedWith;
          }

          if (tag.multipleArtworksIds) {
            multipleArtworksIds = tag.multipleArtworksIds;
            searchOnThisArtist = tag.replacedWith;
          }
        });
        this.artistSearchStr.set(artistSearchStr);
        this.multipleArtworksIds.set(multipleArtworksIds);
        this.searchOnThisArtist.set(searchOnThisArtist);
        this.narrative.set(prompterResult.narrative);
        this.filtersStateService.setRandomUpdateSearchPayloadSignal();

        this.httpReqService.constructFiltersAndNavigate();

        this.finishPrompterMagic(prompterResult, skipAnimation);
      });
    });
  }

  private htmlToPlainText(html: SafeHtml) {
    // Create a new DOMParser instance
    const parser = new DOMParser();

    // Parse the HTML string into a new document
    const doc = parser.parseFromString(html.toString(), 'text/html');

    // Use the document's body textContent to get the plain text
    if (doc.body.textContent) {
      return doc.body.textContent.split(': ')[1].trim().split('(see ')[0].trim();
    }

    return '';
  }

  private textToSpansForRealPrompt(div: HTMLDivElement, tags: Tags[], useOriginal: boolean): void {
    let innerText = div.innerText;

    // Determine which property to use based on the flag
    const property = useOriginal ? 'originalValueFromSearch' : 'replacedWith';

    // Wrap each filterTag in a span
    tags.forEach(tag => {
      const escapedValue = this.escapeRegExp(tag[property]);
      const regex = new RegExp(`(${escapedValue})`, 'gi');
      innerText = innerText.replace(regex, '<span class="prompter-tag">$1</span>');
    });

    // Now wrap remaining text not matched by filterTags
    const nonMatchedParts = innerText.split(/(<span class="prompter-tag">.+?<\/span>)/);
    const fadeClass = useOriginal ? 'class="tag-fade-out"' : 'class="tag-fade-in tag-animation"';
    div.innerHTML = nonMatchedParts.map(part => {
      // If part is not a highlighted span, split further into words and wrap them, excluding empty strings.
      return part.startsWith('<span')
        ? part
        : part.split(' ').map(word => word.trim()
          ? `<span ${fadeClass}>${word}</span>`
          : '')
          .join(' ');
    }).join('');

    // After innerText has been updated
    tags.forEach((tag, index) => {
      div.querySelectorAll('.prompter-tag').forEach((span, spanIndex) => {
        if (span.textContent === tag[property]) {
          const selectedArtwork = tag.selectedArtwork;
          if (selectedArtwork) {
            span.setAttribute('artwork-id', selectedArtwork.id.toString());
            span.classList.add('input-artwork');
          } else if (tag.isArtistSearch || tag.multipleArtworksIds) {
            span.setAttribute('artist-name', tag.replacedWith);
            span.classList.add('input-artist');
          } else {
            span.setAttribute('filter-family-name', tag.filterFamilyName!);
            span.setAttribute('filter-id', tag.filterId!);
            span.setAttribute('filter-state', tag.state ? 'green' : 'red');
          }

          span.setAttribute('id', useOriginal ? `original-value-${index}` : `replaced-value-${index}`);
          span.classList.add('input-filter-container');

          if (!useOriginal) {
            span.classList.add('tag-animation');
            if (!selectedArtwork && !tag.isArtistSearch && !tag.multipleArtworksIds) {
              span.classList.add(tag.state ? 'text-green-500' : 'text-red-600');
            }
          }
          span.setAttribute('contenteditable', 'false');
        }
      });
    });

    if (useOriginal) {
      this.moveCursorToEnd(div);
    }
  }

  private escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  private calculateSpanPositions(div: HTMLDivElement, selector: string) {
    const spans: any = div.querySelectorAll(selector);
    return Array.from(spans).map((span: any) => {
      return {
        id: selector === '.tag-fade-in' ? span.innerHTML : span.id,
        top: span.offsetTop,
        left: span.offsetLeft
      };
    });
  }

  private startPrompterMovingAnimations() {
    const editableDiv = this.editableDiv!.nativeElement;
    const editableCopyDiv = this.editableCopyDiv!.nativeElement;

    const tl = gsap.timeline();

    editableDiv.querySelectorAll('.tag-fade-out').forEach((element: HTMLElement) => {
      // Step 1: Fade out non-matched spans in `editableDiv`
      tl.to(element,
        {
          duration: 1,
          opacity: 0,
          ease: 'power1.inOut'
        }, 0);
    });

    // Step 2: Move matched spans to their new positions
    this.matchingSpansStartPositions.forEach((startPos, index) => {
      const endPos = this.matchingSpansEndPositions.find(pos =>
        startPos.id.split('original-value-')[1] === pos.id.split('replaced-value-')[1]);
      if (endPos) {
        const originalElement = editableDiv.querySelector(`#${startPos.id}`);
        const replacementElement = editableCopyDiv.querySelector(`#${endPos.id}`);

        const animationDuration = 1.5; // Duration of the animation
        const classChangeTime = animationDuration - 1.2; // Schedule class change to occur 1.2 seconds before the animation ends

        if (originalElement) {
          originalElement.style.position = 'absolute';
          originalElement.style.zIndex = '15';
          tl.to(originalElement, {
            duration: 1,
            left: endPos.left,
            top: endPos.top - 2,
            ease: 'power1.inOut',
            onUpdate: () => {
              // Check if we're close to the end of the animation and class change hasn't been applied yet
              const currentTime = tl.time();
              if (currentTime >= classChangeTime && !originalElement.classList.contains('updated')) {
                // this.editableDiv!.nativeElement.style.height = this.editableCopyDiv?.nativeElement.offsetHeight + 'px';

                // Apply class changes based on conditions
                if (replacementElement.className.includes('text-green-500')) {
                  originalElement.classList.add('text-green-500');
                }
                if (replacementElement.className.includes('text-red-600')) {
                  originalElement.classList.add('text-red-600');
                }
                // Mark element to avoid multiple class changes
                originalElement.classList.add('updated');
                originalElement.textContent = replacementElement.textContent;
              }
            }
          }, 0);
        }
      }
    });

    // Assuming nonMatchingSpansEndPositions have been calculated correctly
    this.nonMatchingSpansEndPositions.forEach(pos => {
      const spanContent = pos.id; // Assuming this holds the text content for non-matching spans
      const clone = document.createElement('span');
      clone.textContent = spanContent;
      clone.style.opacity = '0'; // Start invisible
      clone.style.position = 'absolute';
      clone.style.left = `${pos.left}px`;
      clone.style.top = `${pos.top}px`;

      // Append to editableDiv
      editableDiv.appendChild(clone);

      // Animate opacity to fade in
      tl.to(clone, {duration: 1, opacity: 1, ease: 'power1.inOut'}, .3);
    });

    tl.add(() => {
      // Cleanup step: Remove 'updated' class from all elements
      editableDiv.querySelectorAll('.updated').forEach((element: HTMLElement) => {
        element.classList.remove('updated');
      });

    }, '+=0'); // Adds the cleanup call at the end of the timeline

    this.moveCursorToEnd(editableCopyDiv);

    // Finalize your timeline with an onComplete callback for cleanup
    tl.eventCallback('onComplete', () => {
      // Apply the combined text back to the editableCopyDiv
      editableCopyDiv.innerHTML = this.combineTextInDivNodes(editableCopyDiv.childNodes);

      // After recombining text, remove the `.tag-animation` classes
      editableCopyDiv.querySelectorAll('.tag-animation').forEach((element: HTMLElement) => {
        element.classList.remove('tag-animation');
      });

      this.showNewPromptOnDiv(this.editableCopyDiv!.nativeElement.innerHTML);
      this.animationHasStarted = false;
    });

    // Ensure animations are managed correctly
    tl.play();
  }

  private filtersClickedEvent() {
    this.globalStateService.resetPageNumber();
    this.httpReqService.constructFiltersAndNavigate();
  }

  private getSongsFamilies(referenceSongIds: string, songIds: number[]) {
    this.httpReqService.getResultsOsFamilies(referenceSongIds, songIds)
      .then((finalSongs) => {
        this.globalStateService.setSongsLyricsMatch(finalSongs);
      }).finally(() => {
      this.filtersStateService.tagsWithOperator = [];
    });
  }

  private getNoMoreResultsMessage(artistNotFound = false) {
    if (artistNotFound) {
      this.globalStateService.noMoreResultsMessage.set('We couldn\'t find a perfect match. Try tweaking your search.');
      return;
    }
    if (this.globalStateService.songs().length > 0) {
      return;
    }

    if (this.filtersStateService.areFiltersEmpty()
      && this.filtersStateService.selectedArtwork()) {
      const selectedSort = this.filtersStateService.getSelectedSortBy();
      if (selectedSort) {
        let result = ' ';
        switch (selectedSort.name) {
          case 'Weighted Similarity':
            this.globalStateService.noMoreResultsMessage
              .set(`Oops! Looks like we can't use this reference song to find similar songs. Please try another song!`);
            break;
          case 'Production & Sound':
            result = ' sonically';
            break;
          case 'Music & Arrangement':
            result = ' musically';
            break;
          case 'Theme & Mood':
            result = ' lyrically';
            break;
          case 'Writing Style':
            result = ' stylistically';
            break;
        }
        const finalMessage =
          `Oops! Looks like we can't use this reference song to find${result} similar songs. Please try a different sorting category.`;
        this.globalStateService.noMoreResultsMessage.set(finalMessage);
      }
      return;
    }

    this.globalStateService.noMoreResultsMessage
      .set(this.globalStateService.getDefaultNoMoreResultsMessage());
  }

  private setSearchDivValue(value: string | undefined, isPrompterActive = false) {
    if (this.editableDiv && !isPrompterActive && !this.file()) {
      this.removePlaceholder();
      if (value || value === '') {
        this.editableDiv!.nativeElement.innerText = value;
        this.editableCopyDiv!.nativeElement.innerText = value;
      } else {
        const newText = this.generateNewTextForSearch();
        this.editableCopyDiv!.nativeElement.innerHTML = newText;
        this.showNewPromptOnDiv(newText);
      }
    }
  }

  private moveCursorToEnd(div: HTMLElement) {
    // Move the cursor to the end
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(div);
    range.collapse(false);
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  private attachClickEventsToInputSpans() {
    const spansWithId: HTMLSpanElement[] = this.editableDiv!.nativeElement.querySelectorAll('span[id]');

    spansWithId.forEach((span) => {
      if (span.id === 'narrative') {
        span.addEventListener('keydown', (event) => {
          event.stopPropagation();
          span.classList.remove('font-bold');
        });
        return;
      }

      span.addEventListener('click', (event) => {
        event.stopPropagation();
        if (span.classList.contains('input-artwork')) {
          this.inputArtworkAction();
          return;
        }

        if (span.classList.contains('input-artist')) {
          this.inputArtistAction(span.attributes.getNamedItem('artist-name'));
          return;
        }

        this.inputFilterAction(span);
      });
    });
  }

  private inputFilterAction(span: HTMLSpanElement) {
    const filterFamilyName = span.attributes.getNamedItem('filter-family-name')!.value;
    const foundFilter = this.filters().find((filter) => filter.title === filterFamilyName)!;
    if (!this.user() && this.lockedFilters().includes(filterFamilyName)) {
      this.showSignUpPopup();
      return;
    }
    if (filterFamilyName === 'Metadata') {
      this.openFilterDropdown(span, foundFilter, true, true);
    } else {
      this.openFilterDropdown(span, foundFilter, false, true);
    }
  }

  private inputArtworkAction() {
    this.getSongs(this.selectedArtwork()!.artist);
    this.hideSearchDropdown(false);
  }

  private inputArtistAction(namedItem: Attr | null) {
    const searchValue = this.selectedArtwork()?.artist || namedItem?.value;
    this.getSongs(searchValue!);
    this.hideSearchDropdown(false);
  }

  private resetPrompter() {
    this.filtersStateService.prompterText$.next(undefined);
    this.filtersStateService.resetParams();
    this.prompterIsActive.set(false);
    this.isTextInsideSearch = false;

    this.exactMatchToastShown = false;

    // if (!this.navBarService.uploadStarted) {
    //   this.editableDiv!.nativeElement.style.height = '';
    // }
    // this.editableCopyDiv!.nativeElement.style.height = '';
  }

  private generateNewTextForSearch() {
    let prompterText = this.filtersStateService.prompterText$.getValue();

    if (!prompterText) {
      return '';
    }

    // First, remove <strong> tags with no attributes or where the only attribute does not affect styling
    prompterText = prompterText.replace(/<strong>(.*?)<\/strong>/g, '$1');

    // Replace <strong> with <span> and apply the appropriate classes
    prompterText = prompterText.replace(/<strong(.*?)>(.*?)<\/strong>/g, (match, attributes, textContent) => {
      let classList = 'prompter-tag input-filter-container'; // Base class for all spans
      if (/artwork-id/.test(attributes)) {
        classList += ' input-artwork'; // Additional class for artwork-id
      }
      if (/artist-name/.test(attributes)) {
        classList += ' input-artist';
      }
      if (/narrative/.test(attributes)) {
        return `<span ${attributes.trim()} class="font-bold" tabindex="0">${textContent}</span>`;
      }
      if (/lyricscontains/.test(attributes)) {
        return `<span ${attributes.trim()} class="font-bold" tabindex="0">${textContent}</span>`;
      }
      if (/filter-state="green"/.test(attributes)) {
        classList += ' text-green-500'; // Class for green state
      } else if (/filter-state="red"/.test(attributes)) {
        classList += ' text-red-600'; // Class for red state
      }
      // Return the modified span with classes and content
      return `<span contentEditable="false" ${attributes.trim()} class="${classList}">${textContent}</span>`;
    });

    // Now, let's wrap text not inside a span with a mx-1 class span
    // We avoid wrapping text that's just whitespace or already inside a span
    prompterText = prompterText.replace(/(?<!<\/span>)([^<>]+)(?=<span|$)/g, (match, textContent) => {
      // Check if textContent is not just whitespace
      if (!textContent.trim()) return textContent; // Return as is if just whitespace
      return `<span class="mx-1">${textContent}</span>`; // Wrap and add margin class
    });

    prompterText += '<span class="mx-1">&nbsp</span>';
    return prompterText;
  }

  private combineTextInDivNodes(children: NodeListOf<ChildNode>) {
    // First, recombine spans and text content before removing classes
    let combinedText = '';
    let textAccumulator = ''; // Accumulates text until a prompter-tag is encountered

    children.forEach(child => {
      if (child.nodeType === Node.TEXT_NODE) {
        // Directly accumulate text content, including leading and trailing spaces
        textAccumulator += child.textContent;
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        const element = child as HTMLElement;
        // Check if the element has 'prompter-tag' class
        if (element.classList.contains('prompter-tag')) {
          // If there's accumulated non-tag text, wrap it in a span and add it
          if (textAccumulator) {
            combinedText += `<span class="mx-1">${textAccumulator}</span>`;
            textAccumulator = ''; // Reset the accumulator
          }
          // Add prompter-tag span directly
          combinedText += element.outerHTML;
        } else {
          // Accumulate text content of non-prompter-tag elements, including spaces
          textAccumulator += element.textContent;
        }
      }
    });

    // After the loop, wrap any remaining accumulated text in a span
    if (textAccumulator) {
      combinedText += `<span class="mx-1">${textAccumulator}</span>`;
    }

    return combinedText;
  }

  private startFakePrompterAnimationFlow() {
    const editableDiv = this.editableDiv!.nativeElement;
    const editableCopyDiv = this.editableCopyDiv!.nativeElement;
    editableCopyDiv.innerHTML = this.generateNewTextForSearch();
    this.textToSpanForFakePrompt(editableDiv);

    Promise.resolve().then(() => {
      this.startPrompterScrambleAnimation(editableDiv, editableCopyDiv);
    });
  }

  private startPrompterScrambleAnimation(editableDiv: HTMLDivElement, editableCopyDiv: HTMLDivElement) {
    const timeline = gsap.timeline({
      onComplete: () => {
        // Animation complete logic here
        this.animationHasStarted = false;
      }
    });

    // Target all spans in editableDiv for the "scramble away" effect
    gsap.utils.toArray(editableDiv.querySelectorAll('span')).forEach((span: any) => {
      timeline.to(span, {
        x: gsap.utils.random(-50, 50, 1),
        y: gsap.utils.random(-10, 10, 1),
        opacity: 0,
        duration: 1,
        ease: 'power1.inOut',
        onComplete: () => {
          // Prepare editableCopyDiv (which should initially be hidden and not taking up space)
          editableCopyDiv.style.position = 'absolute';
          editableCopyDiv.style.opacity = '0';

          // Make sure editableCopyDiv is visible and positioned correctly before animating
          gsap.set(editableCopyDiv, {
            opacity: 1,
            position: 'absolute',
            top: editableDiv.offsetTop,
            left: editableDiv.offsetLeft
          });
        }
      }, 0);
    });

    // Animate spans in editableCopyDiv to scramble in
    gsap.utils.toArray(editableCopyDiv.querySelectorAll('span')).forEach((span: any, index) => {
      timeline.fromTo(span, {
        x: gsap.utils.random(-50, 50, 1),
        y: gsap.utils.random(-20, 20, 1),
        opacity: 0,
      }, {
        x: 0,
        y: 0,
        opacity: 1,
        duration: 1,
        ease: 'power1.inOut',
      }, .75); // Adjust timing as needed
    });

    // Finally, replace editableDiv's content with editableCopyDiv's content
    timeline.add(() => {
      this.showNewPromptOnDiv();
      editableCopyDiv.style.opacity = '0'; // Hide copy div again
      this.editableCopyDiv!.nativeElement.innerHTML = '';
    });
  }

  private textToSpanForFakePrompt(div: HTMLDivElement) {
    // Split text by spaces to get words
    let words = div.innerText.split(/\s+/);
    div.innerHTML = ''; // Clear current text

    // Wrap each word in a span and append it back to the div
    words.forEach(word => {
      let span = document.createElement('span');
      span.textContent = word + ' '; // Add space after each word for natural spacing
      div.appendChild(span);
    });
  }

  private startRealPrompterAnimationFlow(prompterResult: PrompterResult) {
    this.textToSpansForRealPrompt(this.editableDiv!.nativeElement, prompterResult.tags, true);
    this.textToSpansForRealPrompt(this.editableCopyDiv!.nativeElement, prompterResult.tags, false);

    Promise.resolve().then(() => {
      this.matchingSpansStartPositions = this.calculateSpanPositions(this.editableDiv!.nativeElement, 'span[id]');
      this.matchingSpansEndPositions = this.calculateSpanPositions(this.editableCopyDiv!.nativeElement, 'span[id]');
      if (this.matchingSpansStartPositions.length !== this.matchingSpansEndPositions.length) {
        this.startFakePrompterAnimationFlow();
        return;
      }
      this.nonMatchingSpansEndPositions = this.calculateSpanPositions(this.editableCopyDiv!.nativeElement, '.tag-fade-in');
      this.startPrompterMovingAnimations();
    });
  }

  private changeArtworkInPrompter(artwork: Artwork) {
    let artworkSpan = this.editableDiv!.nativeElement.querySelector('span.input-artwork');
    if (!artworkSpan) {
      artworkSpan = this.editableDiv!.nativeElement.querySelector(`span[artist-name="${artwork.artist}"]`)!;
      artworkSpan.classList = 'prompter-tag input-filter-container input-artwork';
      artworkSpan.removeAttribute('artist-name');
      // this.filtersStateService.removeArtistFromMultipleArtists(artwork.artist);
      this.attachClickEventsToInputSpans();
    }
    artworkSpan.setAttribute('artwork-id', artwork.id.toString());
    artworkSpan.innerText = `${artwork.artist} - ${artwork.title}`;
    this.filtersStateService.changeSelectedArtwork(artwork);
    this.hideSearchDropdown(true);
    this.httpReqService.constructFiltersAndNavigate();
  }

  private startFinalSongsFlow(finalSongs: FinalSong[], isLoadMore: boolean, selectedId: string) {
    if (finalSongs.length === 0) {
      this.globalStateService.totalTimesNoSongsFound++;
    } else {
      this.checkExactArtistMatch(finalSongs[0]);
    }

    const noMoreResults = finalSongs.length === 0 && !isLoadMore;
    if (noMoreResults) {
      this.getNoMoreResultsMessage();
    }

    // if (this.globalStateService.songs$.getValue().length !== 0) {
    //   this.globalStateService.showNoMoreResults$.next(noMoreResults);
    // }

    // Check if needs to enter emptySong

    const isInArtistSearch = location.href.includes('searchInArtist=') || location.href.includes('/artist/');

    if (finalSongs.length < 20 && isInArtistSearch && this.globalStateService.songs().length !== 0) {
      const emptySong: FinalSong = {
        artist: '',
        id: '',
        imageUrl: '',
        large_image_url: '',
        songAddedToPlaylist: false,
        title: '',
        url: '',
        webp_url: '',
        youtube_id: '',
        showEmptyLine: true,
        timestamp: 0
      };
      finalSongs.push(emptySong);

      this.globalStateService.artistPages = this.globalStateService.resultsPageNumber;
      this.loadMore();
    } else {
      if (finalSongs.length < 25) {
        if (this.globalStateService.totalTimesNoSongsFound > 3) {
          if (finalSongs.length === 0) {
            const totalSongs = this.globalStateService.songs().length;
            this.getNoMoreResultsMessage(totalSongs === 0);
            this.globalStateService.showNoMoreResults.set(totalSongs === 0);
          }
          return;
        }
        this.loadMore();
      }
    }

    this.globalStateService.setSongs(finalSongs);
    this.globalStateService.setSongsLoading(false);
    if (!this.isQuickTags && !noMoreResults) {
      this.globalStateService.highlightedSong.set(finalSongs[0]);
      this.getSongsFamilies(selectedId, finalSongs.map((song) => song.id).map(Number));
    }
  }

  private filtersWereClicked() {
    this.httpReqService.searchOrigin = 'filter_click';

    this.httpReqService.cancelRequests(); // Cancel any ongoing request
    this.filtersClickedEvent();
    this.closeOverlay();
    this.globalStateService.filterWasClickedDontHideFilters.set(true);
    this.showNewPromptOnDiv();
  }

  private showNewPromptOnDiv(text: string | undefined = undefined) {
    this.editableDiv!.nativeElement.innerHTML = text
      ? text
      : this.generateNewTextForSearch();
    this.attachClickEventsToInputSpans();
  }

  private findDuplicateWord(newTextForSearch: string): boolean {
    // Convert the string to lowercase and split it into words
    const words = newTextForSearch.toLowerCase().match(/\b(\w+)\b/g) || [];

    // Words to exclude from the check
    const exclusions = new Set(['or', 'in', 'that', 'this', 'is', 'are', 'and', 'a', 'not', 'by']);

    // Use a Set to track seen words
    const seenWords = new Set();

    for (const word of words) {
      if (exclusions.has(word)) {
        continue; // Skip excluded words
      }
      if (seenWords.has(word)) {
        return true; // Duplicate found, return true
      }
      seenWords.add(word);
    }

    return false; // No duplicates found
  }

  private sendPromptError(err: any) {
    const errorObject = {
      prompt: this.editableDiv!.nativeElement.innerText,
      err
    };
    this.httpReqService.sendEventToServer('QUERY_ERROR', errorObject);
  }

  private extractLastSegment(url: string): string {
    // Trim the URL to remove any trailing slashes
    const trimmedUrl = url.endsWith('/') ? url.slice(0, -1) : url;

    // Split the URL by slashes and pop the last non-empty segment
    return decodeURIComponent(trimmedUrl.split('/').pop() || '');
  }

  private setDivSearchValueFromUrl(value: string) {
    document.title = value;
    this.removePlaceholder();
    const spanText = document.createElement('span');
    spanText.className = 'mx-1';
    spanText.textContent = 'Songs similar to ';
    this.editableDiv!.nativeElement.appendChild(spanText);

    const span = document.createElement('span');
    span.className = 'prompter-tag input-filter-container';
    span.textContent = value;

    this.editableDiv!.nativeElement.appendChild(span);
  }

  private isUrl(input: string): boolean {
    if (input.includes('https')) {
      this.showNothingFound.set(true);
      this.showLinkHasBeenEntered = true;
      this.hideSearchDropdown(false);
      return true;
    }

    try {
      new URL(input);
      this.showNothingFound.set(true);
      this.showLinkHasBeenEntered = true;
      this.hideSearchDropdown(false);
      return true;
    } catch {
      this.showNothingFound.set(false);
      this.showLinkHasBeenEntered = false;
      this.hideSearchDropdown(false);
      return false;
    }
  }

  private checkInput(input: string): void {
    const words = input.trim().split(/\s+/);
    const forbiddenWords = ['like', 'song', 'similar'];

    // if (words.length <= 5 && !words.some(word => forbiddenWords.includes(word))) {
    if (!words.some(word => forbiddenWords.includes(word))) {
      this.showNothingFound.set(true);
      this.hideSearchDropdown(false);
    } else {
      this.showNothingFound.set(false);
    }
  }

  private checkRequestsForPopup() {
    if (!this.user()) {
      const currentLocation = location.href;

      if (currentLocation !== this.activeUrlLocation) {
        this.activeUrlLocation = currentLocation;

        if (this.current_requests_before_popup++ >= this.TOTAL_REQUESTS_BEFORE_POPUP) {
          this.openUpgradeLogin('Sign Up for FREE for unlimited access to Songhunt', false, true);
          return true;
        }
      }
    }
    return false;
  }

  private checkIfIsHomePage() {
    // Get the origin and href
    const origin = window.location.origin;
    const href = window.location.href;

    // Add a trailing slash to the origin if it doesn't already have one
    const normalizedOrigin = origin.endsWith('/') ? origin : origin + '/';

    // Compare the normalized origin with the href
    if (href !== normalizedOrigin && href.startsWith(normalizedOrigin)) {
      if (this.showBigSearchHomePage()) {
        this.globalStateService.setShowBigSearchHomePage(false, 'Navbar - checkIfIsHomePage if this.showBigSearchHomePage()');
      }

      if (this.checkUtmTags()) {
        this.globalStateService.setShowBigSearchHomePage(true, 'Navbar - checkIfIsHomePage if this.checkUtmTags()');
      } else {
        this.showPromptCuratedPlaylists.set(false);
      }
    } else {
      if (!this.navBarService.uploadStarted && !this.clearSearchClicked) {
        if (!this.showBigSearchHomePage()) {
          this.globalStateService.setShowBigSearchHomePage(true, 'Navbar - checkIfIsHomePage if !this.showBigSearchHomePage()');
        }
        this.file.set(null);
      }
    }
    if (!this.navBarService.uploadStarted) {
      this.setContentEditable(true);
    }
    this.clearSearchClicked = false;
  }

  private checkUtmTags(): boolean {
    try {
      // Parse the current URL
      const url = new URL(window.location.href);

      // Define the base path
      const basePath = '/';

      // Get the path after '/songhunt'
      let pathAfterBase = url.pathname.substring(basePath.length);

      // Normalize pathAfterBase by ensuring it starts with '/'
      pathAfterBase = pathAfterBase.startsWith('/') ? pathAfterBase : `/${pathAfterBase}`;

      // Define allowed paths after '/songhunt'
      const allowedPaths = new Set(['', '/', '/signup/', '/register/', '/affiliate-program/', '/similar', '/artist']);

      // If there's a path and it's not one of the allowed ones, return false
      if (pathAfterBase && !allowedPaths.has(pathAfterBase)) {
        return false; // Indicates 'showResults' state
      }

      // Get query parameters
      const queryParams = url.searchParams;

      // Define known UTM tags
      const utmTags = new Set([
        'via',
        'ref',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_social',
      ]);

      // Flag to determine if only UTM tags are present
      let onlyUtmTags = true;

      // Object to hold extracted UTM parameters
      const extractedUtmParams: { [key: string]: string } = {};

      // Iterate over all query parameters
      queryParams.forEach((value, key) => {
        if (utmTags.has(key)) {
          // Save allowed UTM parameter
          extractedUtmParams[key] = value;
        } else {
          // Found a parameter that's not allowed
          onlyUtmTags = false;
        }
      });

      // Construct the UTM string
      let utmString: string | undefined = undefined;
      if (Object.keys(extractedUtmParams).length > 0) {
        // Encode each key and value to ensure URL safety
        const utmParamPairs = Object.entries(extractedUtmParams)
          .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);

        // Join the pairs with '&' to form a query-like string
        utmString = utmParamPairs.join('&');
      }

      if (utmString) {
        // Save the UTM string to the signal
        this.userStateService.utmTags = utmString;
      }

      return onlyUtmTags; // true for 'showSearch', false for 'showResults'
    } catch (error) {
      console.error('Error in checkUtmTags:', error);
      // In case of error, you might choose to return false or handle it differently
      this.userStateService.utmTags = undefined;
      return false;
    }
  }

  private rollBigScreenPrompts(curatedPlaylists: CuratedPlaylist[]) {
    // Remove when all images are there
    curatedPlaylists = curatedPlaylists
      .filter(playlist => playlist.shortPrompt !== null && playlist.promptImageUrl !== null);
    let totalPlaylistItems = this.featuredArtist() ? 4 : 5;

    this.curatedPromptPlaylists.set(getRandomItems(curatedPlaylists, totalPlaylistItems));
  }

  private resetFile() {
    this.navBarService.uploadStarted.set(false);
    this.navBarService.uploadFailed.set(false);
    this.uploadProgress.set(0);
    if (!this.fileUploadResponse() && !this.navBarService.uploadStarted) {
      this.fileUploadResponse.set(undefined);
      this.file.set(null);
    }
  }

  private checkIfWhiteLabelDomainAndReset() {
    const domain = location.host.split('.')[0];
    const isWhiteLabel = domain !== 'www' && !domain.includes('localhost:');
    if (isWhiteLabel) {
      this.getResults();
    }
  }

  private checkExactArtistMatch(finalSong: FinalSong) {
    if (!this.prompterText?.toString().includes('Songs similar to') && !this.exactMatchToastShown) {
      const artistName =
        this.artistFromUrl()
        || this.artistSearchStr()
        || this.searchOnThisArtist();
      const isEmpty = Object.keys(this.filtersStateService.selectedFilters).length === 0;
      let artistFromSongList = '';
      if (this.globalStateService.songs().length > 0) {
        artistFromSongList = this.globalStateService.songs()[0].artist.toLowerCase();
      } else {
        artistFromSongList = finalSong.artist.toLowerCase();
      }

      if (isEmpty && artistName && artistName.toLowerCase() !== artistFromSongList) {
        this.exactMatchToastShown = true;
        this.toast.warning('We couldn\'t find a perfect match. Try tweaking your search.');
      }
    }
  }

  private checkAndAddTopSongs() {
    let shouldAddSongs = false;
    let origin: string = '';

    if (this.addTopTwentySongsToPlaylistFromPrompt) {
      this.addTopTwentySongsToPlaylistFromPrompt = false;
      origin = 'SH_TOP_20_ADD_PROMPT';
      shouldAddSongs = true;
    } else if (!this.addedTopTwentySongsToPlaylistFromFirstSearch) {
      origin = 'SH_TOP_20_ADD_FIRST_SEARCH';
      this.addedTopTwentySongsToPlaylistFromFirstSearch = true;
      shouldAddSongs = true;
    }

    if (shouldAddSongs && !this.exactMatchToastShown) {
      this.addTopTwentySongsToPlaylist(origin);
      this.toast.info(`We added the first ${this.globalStateService.playlistSongs().size} songs to your playlist. Export it to Spotify or keep exploring your song recommendations!`, 8000);
      this.showPlaylist();
      if (this.isMobileDevice()) {
        setTimeout(() => {
          this.globalStateService.showPlaylist.set(false);
        }, 2000);
      }
    }
  }

  private finishPrompterMagic(prompterResult: PrompterResult, skipAnimation: boolean | undefined) {
    if (this.prompterText) {
      this.tempPrompterResult = undefined;
      this.tempSkipAnimation = undefined;
      prompterResult.newTextForSearch = this.htmlToPlainText(this.prompterText);

      this.editableCopyDiv!.nativeElement.innerText = prompterResult.newTextForSearch;

      skipAnimation = skipAnimation || this.findDuplicateWord(prompterResult.newTextForSearch);

      if (prompterResult.addTopTwentyToPlaylist) {
        this.addTopTwentySongsToPlaylistFromPrompt = true;
      }

      if (skipAnimation || prompterResult.skipAnimation || prompterResult.narrative) {
        this.startFakePrompterAnimationFlow();
        return;
      }

      this.startRealPrompterAnimationFlow(prompterResult);
    } else {
      this.tempPrompterResult = prompterResult;
      this.tempSkipAnimation = skipAnimation;
    }
  }
}

import {ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2} from '@angular/core';

interface ListeningStats {
  totalHours: number;
  songsPlayed: number;
  topGenre: string;
  favoriteArtist: string;
}

interface GenreData {
  name: string;
  percentage: number;
  color: string;
}

interface GenreDetail {
  topArtist: string;
  topTrack: string;
  listeningTime: string;
}

interface ArtistRecommendation {
  id: number;
  name: string;
  genre: string;
  imageUrl: string;
  similarTo: string;
  matchPercentage: number;
}

interface MoodInsight {
  mood: string;
  percentage: number;
  color: string;
  icon: string;
}

interface TimeInsight {
  timeOfDay: string;
  percentage: number;
  icon: string;
}

interface TopArtist {
  name: string;
  percentage: number;
  color: string;
  topSong: string;
  listeningHours: number;
}

@Component({
  selector: 'mypart-profile-content-insights',
  standalone: false,
  templateUrl: './profile-content-insights.component.html',
  styleUrl: './profile-content-insights.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileContentInsightsComponent implements OnInit {
  listeningStats: ListeningStats = {
    totalHours: 247,
    songsPlayed: 3842,
    topGenre: 'Indie Rock',
    favoriteArtist: 'Arctic Monkeys'
  };

  genreData: GenreData[] = [
    {name: 'Indie Rock', percentage: 32, color: '#c42727'},
    {name: 'Alternative', percentage: 24, color: '#a13d47'},
    {name: 'Pop', percentage: 18, color: '#3f51b5'},
    {name: 'Hip Hop', percentage: 12, color: '#8a2932'},
    {name: 'Electronic', percentage: 8, color: '#4caf50'},
    {name: 'Other', percentage: 6, color: '#ff9800'}
  ];
  topArtists: TopArtist[] = [
    {name: 'Arctic Monkeys', percentage: 40, color: '#c42727', topSong: 'Do I Wanna Know?', listeningHours: 78},
    {name: 'Radiohead', percentage: 20, color: '#a13d47', topSong: 'Creep', listeningHours: 59},
    {name: 'Dua Lipa', percentage: 15, color: '#3f51b5', topSong: 'Levitating', listeningHours: 44},
    {name: 'Kendrick Lamar', percentage: 15, color: '#8a2932', topSong: 'HUMBLE.', listeningHours: 30},
    {name: 'Daft Punk', percentage: 6, color: '#4caf50', topSong: 'Get Lucky', listeningHours: 20},
    {name: 'Various Artists', percentage: 4, color: '#ff9800', topSong: 'Mixed Tracks', listeningHours: 16}
  ];
  genreDetails: Record<string, GenreDetail> = {
    'Indie Rock': {
      topArtist: 'Arctic Monkeys',
      topTrack: 'Do I Wanna Know?',
      listeningTime: '78 hours'
    },
    'Alternative': {
      topArtist: 'Radiohead',
      topTrack: 'Creep',
      listeningTime: '59 hours'
    },
    'Pop': {
      topArtist: 'Dua Lipa',
      topTrack: 'Levitating',
      listeningTime: '44 hours'
    },
    'Hip Hop': {
      topArtist: 'Kendrick Lamar',
      topTrack: 'HUMBLE.',
      listeningTime: '30 hours'
    },
    'Electronic': {
      topArtist: 'Daft Punk',
      topTrack: 'Get Lucky',
      listeningTime: '20 hours'
    },
    'Other': {
      topArtist: 'Various Artists',
      topTrack: 'Mixed Tracks',
      listeningTime: '16 hours'
    }
  };

  selectedGenre: string | null = null;
  highlightedGenre: string | null = null;

  artistRecommendations: ArtistRecommendation[] = [
    {
      id: 1,
      name: 'Tame Impala',
      genre: 'Psychedelic Rock',
      imageUrl: 'assets/images/artists/tame-impala.jpg',
      similarTo: 'Arctic Monkeys',
      matchPercentage: 92
    },
    {
      id: 2,
      name: 'Glass Animals',
      genre: 'Indie Rock',
      imageUrl: 'assets/images/artists/glass-animals.jpg',
      similarTo: 'Tame Impala',
      matchPercentage: 88
    },
    {
      id: 3,
      name: 'The Strokes',
      genre: 'Indie Rock',
      imageUrl: 'assets/images/artists/the-strokes.jpg',
      similarTo: 'Arctic Monkeys',
      matchPercentage: 85
    },
    {
      id: 4,
      name: 'MGMT',
      genre: 'Psychedelic Pop',
      imageUrl: 'assets/images/artists/mgmt.jpg',
      similarTo: 'Tame Impala',
      matchPercentage: 82
    }
  ];

  moodInsights: MoodInsight[] = [
    {mood: 'Energetic', percentage: 42, color: '#ff4081', icon: 'bolt'},
    {mood: 'Relaxed', percentage: 28, color: '#4fc3f7', icon: 'spa'},
    {mood: 'Melancholic', percentage: 18, color: '#9575cd', icon: 'cloud'},
    {mood: 'Happy', percentage: 12, color: '#ffb74d', icon: 'sentiment_very_satisfied'}
  ];

  timeInsights: TimeInsight[] = [
    {timeOfDay: 'Evening (6PM-10PM)', percentage: 45, icon: 'nights_stay'},
    {timeOfDay: 'Afternoon (12PM-6PM)', percentage: 30, icon: 'wb_sunny'},
    {timeOfDay: 'Night (10PM-2AM)', percentage: 20, icon: 'dark_mode'},
    {timeOfDay: 'Morning (6AM-12PM)', percentage: 5, icon: 'morning_mode'}
  ];

  selectedArtist: string | null = null;
  highlightedArtist: string | null = null;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit(): void {
    // Add animation classes with slight delay for staggered effect
    this.animateSections();
  }

  // Helper methods
  getProgressBarStyle(percentage: number, color?: string): string {
    if (color) {
      return `width: ${percentage}%; background-color: ${color}`;
    }
    return `width: ${percentage}%`;
  }

  getMatchClass(percentage: number): string {
    if (percentage >= 90) return 'match-excellent';
    if (percentage >= 80) return 'match-great';
    if (percentage >= 70) return 'match-good';
    return 'match-average';
  }

  // Genre interaction methods
  selectGenre(genreName: string): void {
    this.selectedGenre = this.selectedGenre === genreName ? null : genreName;
  }

  highlightGenre(genreName: string): void {
    this.highlightedGenre = genreName;
  }

  selectArtist(artistName: string): void {
    this.selectedArtist = this.selectedArtist === artistName ? null : artistName;
  }

  highlightArtist(artistName: string): void {
    this.highlightedArtist = artistName;
  }

  unhighlightArtist(): void {
    this.highlightedArtist = null;
  }

  unhighlightGenre(): void {
    this.highlightedGenre = null;
  }

  getGenreTopArtist(genreName: string): string {
    return this.genreDetails[genreName]?.topArtist || 'Unknown';
  }

  getGenreTopTrack(genreName: string): string {
    return this.genreDetails[genreName]?.topTrack || 'Unknown';
  }

  getGenreListeningTime(genreName: string): string {
    return this.genreDetails[genreName]?.listeningTime || '0 hours';
  }

  getGenrePercentage(genreName: string): number {
    const genre = this.genreData.find(g => g.name === genreName);
    return genre ? genre.percentage : 0;
  }

  getPieSegmentStyle(genre: GenreData, index: number): string {
    // Calculate the start and end angles for the pie segment
    const totalPercentage = this.genreData.reduce((sum, g) => sum + g.percentage, 0);
    let startAngle = 0;

    // Sum up percentages of all previous segments
    for (let i = 0; i < index; i++) {
      startAngle += (this.genreData[i].percentage / totalPercentage) * 360;
    }

    const angle = (genre.percentage / totalPercentage) * 360;
    const endAngle = startAngle + angle;

    // Convert to conic-gradient syntax
    return `background: conic-gradient(${genre.color} ${startAngle}deg, ${genre.color} ${endAngle}deg, transparent ${endAngle}deg);`;
  }

  // Icon path methods
  getMoodIconPath(icon: string): string {
    switch (icon) {
      case 'bolt':
        return 'M7 2v11h3v9l7-12h-4l4-8z';
      case 'spa':
        return 'M12 22c4.97 0 9-4.03 9-9-4.97 0-9 4.03-9 9zm0-18c-4.97 0-9-4.03-9-9 4.97 0 9 4.03 9 9zm0 0c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3z';
      case 'cloud':
        return 'M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z';
      case 'sentiment_very_satisfied':
        return 'M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-5-6c.78 2.34 2.72 4 5 4s4.22-1.66 5-4H7zm1.5-5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z';
      default:
        return 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z';
    }
  }

  getTimeIconPath(icon: string): string {
    switch (icon) {
      case 'nights_stay':
        return 'M11.1 12.08c-2.33-4.51-.5-8.48.53-10.07C6.27 2.2 1.98 6.59 1.98 12c0 .14.02.28.02.42.62-.27 1.29-.42 2-.42 1.66 0 3.18.83 4.1 2.15A4.01 4.01 0 0111 18c0 1.52-.87 2.83-2.12 3.51.98.32 2.03.5 3.11.5 3.5 0 6.58-1.8 8.37-4.52-2.36.23-6.98-.97-9.26-5.41z';
      case 'wb_sunny':
        return 'M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zM4 10.5H1v2h3v-2zm9-9.95h-2V3.5h2V.55zm7.45 3.91l-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zM20 10.5v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2V19.5h-2v2.95zm-7.45-3.91l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z';
      case 'dark_mode':
        return 'M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-.46-.04-.92-.1-1.36-.98 1.37-2.58 2.26-4.4 2.26-2.98 0-5.4-2.42-5.4-5.4 0-1.81.89-3.42 2.26-4.4-.44-.06-.9-.1-1.36-.1z';
      case 'morning_mode':
        return 'M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm-1-6v3h2V1h-2zm0 19v3h2v-3h-2zM4.22 5.64l2.12 2.12 1.41-1.41-2.12-2.12-1.41 1.41zm15.56 12.72l-2.12-2.12-1.41 1.41 2.12 2.12 1.41-1.41zM1 12h3v2H1v-2zm19 0h3v2h-3v-2zM4.22 18.36l1.41 1.41 2.12-2.12-1.41-1.41-2.12 2.12zM19.78 5.64l-1.41-1.41-2.12 2.12 1.41 1.41 2.12-2.12z';
      default:
        return 'M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z';
    }
  }

  // Interactive methods
  listenToArtist(artist: ArtistRecommendation): void {
    // This would typically connect to a music service API
    console.log(`Playing music by ${artist.name}`);
    // In a real implementation, this would trigger the music player
  }

  addArtistToLibrary(artist: ArtistRecommendation, event: Event): void {
    event.stopPropagation(); // Prevent triggering the parent click event
    console.log(`Added ${artist.name} to your library`);
    // In a real implementation, this would add the artist to the user's library
  }

  // Method to format large numbers with commas for better readability
  formatNumber(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // Animation methods
  private animateSections(): void {
    const sections = this.elementRef.nativeElement.querySelectorAll('section');
    sections.forEach((section: HTMLElement, index: number) => {
      setTimeout(() => {
        this.renderer.addClass(section, 'animate-fade-in');
      }, index * 100); // Stagger the animations by 100ms
    });
  }
}

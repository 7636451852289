import {computed, Injectable, signal, WritableSignal} from '@angular/core';
import {ToastService} from '../../services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class NavBarService {
  fileUploadResponse = signal<{ key: string, value: string }[] | undefined>(undefined);
  imagePreview = signal<string | undefined>(undefined);
  videoPreview = signal<string | undefined>(undefined);
  uploadError = signal<string | undefined>(undefined);
  file = signal<File | null>(null);
  createPopupSignal = signal<boolean>(false);
  uploadStarted = signal<boolean>(false);
  isImageFile = signal<boolean>(false);
  isVideoFile = signal<boolean>(false);
  isAudioFile = signal<boolean>(false);
  uploadFailed = signal<boolean>(false);
  fileUploadComplete = signal<boolean>(false);
  uploadProgress = signal<number>(0);
  // Search Dropdown State
  private _searchDropDownHidden = signal<boolean>(true);
  readonly searchDropDownHidden = computed(() => this._searchDropDownHidden());
  private readonly MAX_FILE_SIZE = 25;

  constructor() {
  }

  setSearchDropDownHidden(value: boolean): void {
    this._searchDropDownHidden.set(value);
  }

  resetFileState() {
    this.file.set(null);
    this.isImageFile.set(false);
    this.isVideoFile.set(false);
    this.isAudioFile.set(false);
    this.uploadProgress.set(0);
  }

  changeFile(isMobileDevice: WritableSignal<boolean>, toast: ToastService, file: File | undefined): boolean {
    if (file && file.size <= this.MAX_FILE_SIZE * 1024 * 1024) {
      this.file.set(file);
      this.isImageFile.set(this.file()!.type.startsWith('image'));
      this.isVideoFile.set(this.file()!.type.startsWith('video'));
      this.isAudioFile.set(this.file()!.type.startsWith('audio'));

      if (this.isImageFile()) {
        this.previewImage(file);
      } else if (this.isVideoFile()) {
        this.captureVideoScreenshot(file);
        this.previewVideo(file);
      }

      if (!isMobileDevice()) {
        this.createPopupSignal.set(true);
      }

      return true;
    }

    toast.error('File size exceeds the limit of 25MB');
    return false;
  }

  private previewVideo(file: File) {
    this.videoPreview.set(URL.createObjectURL(file));
  }

  private previewImage(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imagePreview.set(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  private captureVideoScreenshot(file: File) {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    video.src = URL.createObjectURL(file);
    video.load();
    video.onloadeddata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.currentTime = 1; // Capture the screenshot at the 1-second mark
    };

    video.onseeked = () => {
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.imagePreview.set(canvas.toDataURL('image/png'));
        URL.revokeObjectURL(video.src); // Clean up
      }
    };
  }
}

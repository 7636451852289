import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, Observable, of, tap, throwError} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {switchMap} from 'rxjs/operators';
import {YoutubeTokensService} from './youtube-tokens.service';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class YoutubeBaseApiService {
  private youtubeTokenUrl = 'https://oauth2.googleapis.com/token';

  constructor(protected http: HttpClient,
              protected youtubeTokensService: YoutubeTokensService) {
  }

  initGoogleAccount() {
    window.onload = () => {
      google.accounts.id.initialize({
        client_id: environment.youtubeClientId,
        callback: this.handleCredentialResponse.bind(this)
      });
    };
  }

  // Refresh the YouTube access token
  refreshAccessToken(): Observable<string> {
    const body = new URLSearchParams({
      client_id: environment.youtubeClientId,
      client_secret: environment.youtubeClientSecret,
      refresh_token: this.youtubeTokensService.getRefreshToken(),
      grant_type: 'refresh_token'
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<any>(this.youtubeTokenUrl, body.toString(), {headers}).pipe(
      tap(data => {
        if (data.access_token) {
          this.youtubeTokensService.setAccessToken(data.access_token);
          this.youtubeTokensService.setRefreshToken(data.refresh_token);
        }
      }),
      switchMap(response => {
        if (response && response.access_token) {
          // ... (handle successful response)
          return of(response.access_token);
        } else {
          return throwError(() => new Error('Failed to refresh token'));
        }
      }),
      catchError(error => {
        console.error('Error refreshing YouTube access token', error);
        return throwError(() => new Error('Error refreshing YouTube access token'));
      })
    );
  }

  getUserProfile() {
    const accessToken = this.youtubeTokensService.getAccessToken();
    if (!accessToken) {
      throw new Error('No access token available');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get('https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses', {headers});
  }

  exchangeCodeForToken(code: string): Observable<any> {
    const body = new URLSearchParams({
      client_id: environment.youtubeClientId,
      client_secret: environment.youtubeClientSecret,
      code: code,
      grant_type: 'authorization_code',
      redirect_uri: environment.youtubeRedirectUri
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post('https://oauth2.googleapis.com/token', body.toString(), {headers});
  }

  // Handle API errors
  protected handleError<T>(error: any, result?: T) {
    console.error(error); // Log the error to the console (or send to server logs)
    return of({error: 'An error occurred while processing your request.'} as any);
  }

  private handleCredentialResponse(response: any) {
    console.log('google authenticated');
    // Here, you'll handle the response token, typically sending it to your backend for verification
    // console.log('Encoded JWT ID token: ' + response.credential);
  }
}

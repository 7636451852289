import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import {Filter, SubFilter} from '../../../../../interfaces/filter';
import {FiltersStateService} from '../../../../../services/filters-state.service';
import {GlobalStateService} from '../../../../../services/global-state.service';
import {UserStateService} from '../../../../../services/user-state.service';

@Component({
  selector: 'mypart-metadata-filter-dropdown',
  templateUrl: './metadata-filter-dropdown.component.html',
  styleUrls: ['./metadata-filter-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MetadataFilterDropdownComponent implements OnInit {
  @Input() filters!: Filter[];
  @Input() isInputFilter = false;
  @Output() filtersClicked = new EventEmitter<void>();
  @Output() outsideDropdownClick = new EventEmitter<void>();
  showBigSearchHomePage = this.globalStateService.showBigSearchHomePage;

  constructor(private filtersStateService: FiltersStateService,
              private globalStateService: GlobalStateService,
              private userStateService: UserStateService,
              private elRef: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.filters = JSON.parse(JSON.stringify(this.filters));

    if (!this.userStateService.isUserWhiteLabel()) {
      this.filters = this.filters.filter((f) => !f.isWhiteLabel);
    }

    this.filters.forEach((filter) => {
      if (this.filtersStateService.selectedFilters.hasOwnProperty(filter.title)) {
        const selectedFilter = this.filtersStateService.selectedFilters[filter.title];
        filter.filters.forEach(subFilter => {
          const selectedSubFilter = selectedFilter.filters.find(sf => sf.id === subFilter.id);
          if (selectedSubFilter) {
            subFilter.state = selectedSubFilter.state;
          }
        });
      }
    });
  }

  setPositionOffScreen() {
    const dropdownEl = this.elRef.nativeElement;
    this.renderer.setStyle(dropdownEl, 'top', '-9999px');
    this.renderer.setStyle(dropdownEl, 'left', '-9999px');
  }

  setPositionRight(top: number, right: number) {
    const dropdownEl = this.elRef.nativeElement;
    this.renderer.setStyle(dropdownEl, 'top', `${top}px`);
    this.renderer.setStyle(dropdownEl, 'right', `${right}px`);
    this.renderer.setStyle(dropdownEl, 'left', 'unset');
  }

  setPositionLeft(top: number, left: number) {
    const dropdownEl = this.elRef.nativeElement;
    this.renderer.setStyle(dropdownEl, 'top', `${top}px`);
    this.renderer.setStyle(dropdownEl, 'left', `${left}px`);
  }

  setPositionCenter(top: number, left: number, width: number) {
    const dropdownEl = this.elRef.nativeElement;

    // Set the initial off-screen position
    this.renderer.setStyle(dropdownEl, 'top', '-9999px');
    this.renderer.setStyle(dropdownEl, 'left', '-9999px');

    // Calculate the center position by subtracting half the dropdown width
    const centerLeft = left + (width / 2) - (250 / 2); // assuming the dropdown width is 250px

    // Set the final position
    setTimeout(() => {
      this.renderer.setStyle(dropdownEl, 'top', `${top}px`);
      this.renderer.setStyle(dropdownEl, 'left', `${centerLeft}px`);
    }, 0); // You can adjust the timeout if needed
  }

  outsideClick() {
    this.outsideDropdownClick.next();
  }

  toggleFilter(filter: Filter, subFilter: SubFilter, state: 'green' | 'red') {
    if (subFilter.state === state) {
      this.removeFilter(filter, subFilter);
    } else {
      this.filtersStateService.addOrChangeSubFilterSelectedFilters(filter, subFilter, state, this.isInputFilter);
    }
    this.emitFiltersClicked();
  }

  filterTextClicked(filter: Filter, subFilter: SubFilter) {
    if (subFilter.state === 'none') {
      this.toggleFilter(filter, subFilter, 'green');
    } else {
      this.toggleFilter(filter, subFilter, subFilter.state);
    }
  }

  private removeFilter(filter: Filter, subFilter: SubFilter) {
    this.filtersStateService.removeFilter(filter, subFilter);
  }

  private emitFiltersClicked() {
    // Use a microtask
    Promise.resolve().then(() => {
      this.filtersClicked.next();
    });
  }
}

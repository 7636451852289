import {ChangeDetectionStrategy, Component, OnInit, signal} from '@angular/core';
import {interval} from 'rxjs';

@Component({
  selector: 'mypart-subtitle',
  templateUrl: './subtitle.component.html',
  styleUrl: './subtitle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SubtitleComponent implements OnInit {
  // A flag to control the CSS class for fading.
  fade = signal(false);
  // Keep track of the current subtitle index.
  private currentIndex = 0;
  private readonly subtitles: string[] = [
    'Upload an image or video to discover songs that match your visual.',
    'Type a text prompt to describe what music you\'re looking for.',
    'Add a song or artist to discover similar songs.',
    'Filter by mood, theme, genre, and more.'
  ];
  mainSubtitle = signal(this.subtitles[0]);

  ngOnInit() {
    interval(5000).subscribe(() => {
      // Begin by starting the fade-out animation.
      this.fade.set(true);
    });
  }

  onTransitionEnd(): void {
    if (this.fade()) {
      // Update the index to the next subtitle.
      this.currentIndex = (this.currentIndex + 1) % this.subtitles.length;
      this.mainSubtitle.set(this.subtitles[this.currentIndex]);
      // Remove the fade class so the element fades back in.
      this.fade.set(false);
    }
  }
}

// Simple seeded random number generator
function seededRandom(seed: number): () => number {
  let m = 0x80000000; // 2**31
  let a = 1103515245;
  let c = 12345;

  let state = seed;

  return function () {
    state = (a * state + c) % m;
    return state / m;
  };
}

export function getRandomItems<T>(array: T[], numItems: number): T[] {
  if (numItems >= array.length) {
    return array; // Return the whole array if numItems is greater than or equal to the array length
  }

  // Generate a random seed using Math.random()
  const seed = Math.floor(Math.random() * 1000000);

  // Create the seeded random function
  const random = seededRandom(seed);

  // Make a copy of the array to avoid modifying the original array
  const shuffledArray = array.slice();

  // Shuffle the array using Fisher-Yates algorithm with seeded random
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  // Return the first `numItems` items from the shuffled array
  return shuffledArray.slice(0, numItems);
}

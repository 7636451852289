<div (outsideClick)="outsideClick()"
     [ngClass]="{'!max-h-[260px]': showBigSearchHomePage()}"
     class="w-[300px] max-h-[300px] overflow-y-auto mt-2 z-30 py-1 bg-white dark:bg-zinc-800 shadow-xl rounded-md overflow-hidden">
  <form class="p-3">
    <label class="sr-only" for="input-group-search">Search {{ filter.title.toLocaleLowerCase() }}</label>
    <div class="relative">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg aria-hidden="true" class="w-4 h-4 text-gray-500 dark:text-gray-400"
             fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" stroke="currentColor"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        </svg>
      </div>
      <input [formControl]="searchTerm"
             class="block shadow:md w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50
                         focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500
                         dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             id="input-group-search" placeholder="Search {{filter.title.toLocaleLowerCase()}}" type="text">
      @if (showSearchWordInThemeFilter() && searchTerm.value !== '' && filteredSubFilters().length === 0) {
        <button (click)="addLyricsContains()"
                class="absolute right-2 bottom-[.25rem] text-gray-900 bg-gray-100 border border-gray-300 focus:outline-none
              hover:bg-gray-200 focus:ring-0 font-medium rounded-full text-xs px-2 py-1.5 dark:bg-gray-800 dark:text-white
          dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600"
                stopPropagation
                type="button">
          Contains
        </button>
      }
    </div>
  </form>
  <ul class="px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200 overscroll-contain">
    @if (showSearchWordInThemeFilter() && lyricsContains()) {
      <li>
        <div class="relative flex items-center pl-2 rounded hover:bg-gray-200 dark:hover:bg-gray-600">
        <span class="text-green-500 w-full py-2 ml-2 text-sm font-medium rounded
                    max-[600px]:whitespace-normal ellipsis" isEllipsis>
          {{ lyricsContains() }}
        </span>
          <button (click)="removeLyricsContains()" class="absolute inset-y-0 right-0 flex items-center pr-3"
                  stopPropagation type="button">
            <svg aria-hidden="true"
                 class="w-3 h-3 text-gray-500 dark:text-gray-400 hover:text-red-600 dark:hover:text-red-600"
                 fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
              <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"/>
            </svg>
          </button>
        </div>
      </li>
    }
    @for (subFilter of filteredSubFilters(); track subFilter.id) {
      <li class="mt sm:my-0">
        <div class="flex items-center rounded hover:bg-gray-200 dark:hover:bg-gray-600 filter-container">
          <span (click)="filterTextClicked(subFilter)"
                [ngClass]="{'!text-red-600': subFilter.state === 'red', '!text-green-500': subFilter.state === 'green'}"
                class="dark:border-gray-600 cursor-pointer w-full py-2 mx-2 text-sm font-medium text-gray-800 rounded
                     max-[600px]:whitespace-normal dark:text-gray-300 ellipsis" isEllipsis>
          {{ subFilter.name }}
          </span>

          <div class="sm:hidden flex items-center justify-center mx-2 filter-actions">
            <svg (click)="toggleFilter(subFilter, 'red')" [ngClass]="{'!text-red-600': subFilter.state === 'red'}"
                 aria-hidden="true"
                 class="cursor-pointer mr-6 sm:mr-4 w-6 h-5 sm:w-4 sm:h-3 text-gray-800 dark:text-gray-300
                      hover:text-black hover:dark:text-gray-50 active:text-red-600"
                 fill="none"
                 viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
              <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"/>
            </svg>

            <svg (click)="toggleFilter(subFilter, 'green')" [ngClass]="{'!text-green-500': subFilter.state === 'green'}"
                 aria-hidden="true"
                 class="cursor-pointer w-6 h-6 sm:w-4 sm:h-4 text-gray-800 dark:text-gray-300 hover:text-black
                      hover:dark:text-gray-50 active:text-green-500"
                 fill="none"
                 viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 5.917 5.724 10.5 15 1.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2"/>
            </svg>
          </div>
        </div>
      </li>
    }
  </ul>
</div>

export function hasSpaceForDropdownOnRight(container: HTMLElement, element: HTMLElement): boolean {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  // The space to the right of the element is the right bound of the container minus the right bound of the element
  const spaceOnRight = containerRect.right - elementRect.right;

  // There is enough space if the space on the right is greater than or equal to the dropdown width
  return spaceOnRight >= 200;
}

export function hasSpaceForDropdownOnLeft(element: HTMLElement): boolean {
  // The space to the left of the element is the left bound of the element
  const spaceOnLeft = element.getBoundingClientRect().left;

  // There is enough space if the space on the left is greater than or equal to the dropdown width
  return spaceOnLeft >= 200;  // Assuming 250px is the width of the dropdown
}

export function isElementInView(container: HTMLElement, element: HTMLElement): boolean {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  // The element is in view if its left and right bounds are within the left and right bounds of the container
  return elementRect.left >= containerRect.left && elementRect.right <= containerRect.right;
}

export function getPositionForDropdown(container: HTMLElement, element: HTMLElement): 'left' | 'right' | 'none' {
  const hasSpaceOnRight = hasSpaceForDropdownOnRight(container, element);
  if (hasSpaceOnRight) return 'right';

  const hasSpaceOnLeft = hasSpaceForDropdownOnLeft(element);
  if (hasSpaceOnLeft) return 'left';

  return 'none';
}

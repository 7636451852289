import {ChangeDetectionStrategy, Component} from '@angular/core';

interface SearchHistoryItem {
  id: number;
  query: string;
  timestamp: string;
  filterTags: string[];
}

@Component({
  selector: 'mypart-profile-content-history',
  standalone: false,
  templateUrl: './profile-content-history.component.html',
  styleUrl: './profile-content-history.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileContentHistoryComponent {
  searchHistory: SearchHistoryItem[] = [
    {
      id: 1,
      query: 'Songs similar to Aretha Franklin - Respect',
      timestamp: 'Oct 12, 2023 • 10:45 AM',
      filterTags: ['Soul', '60s', 'Female Vocals']
    },
    {
      id: 2,
      query: 'Upbeat jazz with saxophone',
      timestamp: 'Oct 5, 2023 • 3:22 PM',
      filterTags: ['Jazz', 'Instrumental']
    },
    {
      id: 3,
      query: 'Songs similar to Ray LaMontagne - You Are The Best Thing',
      timestamp: 'Sep 28, 2023 • 7:15 PM',
      filterTags: ['Folk', 'Acoustic']
    },
    {
      id: 4,
      query: '70s funk with brass section',
      timestamp: 'Sep 15, 2023 • 2:30 PM',
      filterTags: ['Funk', '70s', 'Brass']
    },
    {
      id: 5,
      query: 'Electronic music with female vocals',
      timestamp: 'Sep 10, 2023 • 11:20 AM',
      filterTags: ['Electronic', 'Female Vocals']
    },
    {
      id: 6,
      query: 'Songs similar to Daft Punk - Get Lucky',
      timestamp: 'Sep 5, 2023 • 8:45 PM',
      filterTags: ['Electronic', 'Dance', '2010s']
    },
    {
      id: 7,
      query: 'Indie folk with banjo',
      timestamp: 'Aug 28, 2023 • 4:15 PM',
      filterTags: ['Indie', 'Folk', 'Banjo']
    },
    {
      id: 8,
      query: '90s hip hop classics',
      timestamp: 'Aug 22, 2023 • 7:30 PM',
      filterTags: ['Hip Hop', '90s', 'Rap']
    },
    {
      id: 9,
      query: 'Songs similar to Fleetwood Mac - Dreams',
      timestamp: 'Aug 17, 2023 • 2:10 PM',
      filterTags: ['Rock', '70s', 'Classic Rock']
    },
    {
      id: 10,
      query: 'Ambient music for studying',
      timestamp: 'Aug 10, 2023 • 9:45 AM',
      filterTags: ['Ambient', 'Instrumental', 'Study']
    },
    {
      id: 11,
      query: 'Latin jazz with piano',
      timestamp: 'Aug 5, 2023 • 6:20 PM',
      filterTags: ['Latin', 'Jazz', 'Piano']
    },
    {
      id: 12,
      query: 'Songs similar to Radiohead - Karma Police',
      timestamp: 'Jul 29, 2023 • 3:40 PM',
      filterTags: ['Alternative', '90s', 'Rock']
    },
    {
      id: 13,
      query: 'Classical music for relaxation',
      timestamp: 'Jul 24, 2023 • 10:15 AM',
      filterTags: ['Classical', 'Instrumental', 'Relaxation']
    },
    {
      id: 14,
      query: '80s synthwave with vocals',
      timestamp: 'Jul 18, 2023 • 8:50 PM',
      filterTags: ['Synthwave', '80s', 'Retro']
    },
    {
      id: 15,
      query: 'Songs similar to Kendrick Lamar - HUMBLE.',
      timestamp: 'Jul 12, 2023 • 5:30 PM',
      filterTags: ['Hip Hop', 'Rap', '2010s']
    },
    {
      id: 16,
      query: 'Acoustic covers of pop songs',
      timestamp: 'Jul 7, 2023 • 1:15 PM',
      filterTags: ['Acoustic', 'Covers', 'Pop']
    },
    {
      id: 17,
      query: 'Blues guitar solos',
      timestamp: 'Jul 2, 2023 • 7:40 PM',
      filterTags: ['Blues', 'Guitar', 'Instrumental']
    },
    {
      id: 18,
      query: 'Songs similar to Billie Eilish - bad guy',
      timestamp: 'Jun 25, 2023 • 4:05 PM',
      filterTags: ['Pop', 'Alternative', '2010s']
    },
    {
      id: 19,
      query: 'Japanese city pop from the 80s',
      timestamp: 'Jun 20, 2023 • 11:30 AM',
      filterTags: ['City Pop', 'Japanese', '80s']
    }
  ];

  clearHistory(): void {
    this.searchHistory = [];
  }

  searchAgain(query: string): void {
    console.log(`Searching again for: ${query}`);
    // Implement actual search functionality
  }

  removeHistoryItem(id: number): void {
    this.searchHistory = this.searchHistory.filter(item => item.id !== id);
  }
}

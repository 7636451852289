<input (change)="onFileChange($event)" (click)="fileUploadClicked($event)" [disabled]="navBarService.uploadStarted()"
       accept="image/*,video/*"
       capture="environment"
       class="hidden"
       id="file-upload"
       type="file"/>
<label [ngClass]="{'!cursor-default': navBarService.uploadStarted()}"
       [tp]="isMobileDevice() ? null : 'Upload file'"
       class="flex items-center cursor-pointer"
       for="file-upload">
  @if (navBarService.imagePreview()) {
    <img #thumbnail [src]="navBarService.imagePreview()" alt="File Preview" class="w-6 h-6"/>
    <!-- Hidden Popup Content -->
    <div #popupContent style="display:none">
      @if (navBarService.isImageFile()) {
        <img [src]="navBarService.imagePreview()" alt="Preview" class="w-[320px] h-auto"/>
      } @else {
        <video [src]="navBarService.videoPreview()" controls class="w-[320px] h-auto"></video>
      }
    </div>
  } @else {
    <svg aria-hidden="true" class="w-6 h-6 text-[#c42727]" fill="currentColor"
         viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
    </svg>
  }
</label>

<!-- Upload options menu for mobile devices -->
@if (isMobileDevice() && showUploadOptions()) {
  <div
      class="upload-options-menu absolute top-full right-0 mt-2 bg-white dark:bg-zinc-800 rounded-lg shadow-lg z-50 w-48 transition-all duration-200 ease">
    <!-- Gallery option -->
    <button type="button" (click)="selectFromGallery()"
            class="w-full text-left py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white rounded flex items-center">
      <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
      </svg>
      Gallery
    </button>
    <!-- Take Photo option -->
    <button type="button" (click)="capturePhoto()"
            class="w-full text-left py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white rounded flex items-center">
      <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
      </svg>
      Take Photo
    </button>
    <!-- Record Video option -->
    <button type="button" (click)="captureVideo()"
            class="w-full text-left py-2 px-3 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white rounded flex items-center">
      <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
           xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
      </svg>
      Record Video
    </button>
  </div>
}

<!-- Upload progress indicator -->
@if (navBarService.uploadStarted() && navBarService.uploadProgress() < 100) {
  <div class="upload-progress absolute bottom-[-20px] left-0 w-full h-1 bg-gray-200 dark:bg-gray-700
               mb-4 rounded-full overflow-hidden">
    <div class="h-full bg-gradient-to-r from-gray-700 to-gray-100 transition-all"
         [style.width.%]="navBarService.uploadProgress()"></div>
  </div>
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {GlobalStateService} from '../../services/global-state.service';

@Component({
  selector: 'mypart-profile',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  appTheme = this.globalStateService.appTheme;

  constructor(private globalStateService: GlobalStateService) {
  }
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'mypart-filters-container',
  templateUrl: './filters-container.component.html',
  styleUrl: './filters-container.component.scss',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersContainerComponent {
  @Input() showScrollArrows!: boolean;

}

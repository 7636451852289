import {Injectable} from '@angular/core';
import {UserStateService} from '../user-state.service';
import {environment} from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class YoutubeTokensService {
  readonly accessTokenAddress = 'youtubeAccessToken';
  readonly refreshTokenAddress = 'youtubeRefreshToken';
  readonly isYoutubeConnectedAddress = 'isYoutubeConnected';
  readonly connectedUserEmailAddress = 'youtubeUserEmailAddress';

  constructor(private userStateService: UserStateService) {
  }

  clearCredentials(): void {
    localStorage.removeItem(this.accessTokenAddress);
    localStorage.removeItem(this.refreshTokenAddress);
    localStorage.removeItem(this.isYoutubeConnectedAddress);
    localStorage.removeItem(this.connectedUserEmailAddress);

    const user = this.userStateService.user();
    if (user) {
      user.isYoutubeConnected = false;
      this.userStateService.setUser(user);
    }
  }

  setRefreshToken(refreshToken: string) {
    if (localStorage.getItem(this.isYoutubeConnectedAddress)) {
      localStorage.setItem(this.refreshTokenAddress, refreshToken);
    } else {
      environment.youtubeDefaultUserRefreshToken = refreshToken;
    }
  }

  setAccessToken(accessToken: string) {
    if (localStorage.getItem(this.isYoutubeConnectedAddress)) {
      localStorage.setItem(this.accessTokenAddress, accessToken);
    } else {
      environment.youtubeDefaultUserAccessToken = accessToken;
    }
  }

  setIsYoutubeConnected() {
    localStorage.setItem(this.isYoutubeConnectedAddress, 'true');
  }

  setTokens(accessToken: string, refreshToken: string, email: string) {
    localStorage.setItem(this.accessTokenAddress, accessToken);
    localStorage.setItem(this.refreshTokenAddress, refreshToken);
    localStorage.setItem(this.connectedUserEmailAddress, email);
  }

  getAccessToken() {
    if (this.getIsYoutubeConnected() && this.userStateService.user()?.isSubscribed) {
      return localStorage.getItem(this.accessTokenAddress)!;
    } else {
      return environment.youtubeDefaultUserAccessToken;
    }
  }

  getConnectedUserEmailAddress() {
    return localStorage.getItem(this.connectedUserEmailAddress)!;
  }

  getRefreshToken() {
    if (this.getIsYoutubeConnected() && this.userStateService.user()?.isSubscribed) {
      return localStorage.getItem(this.refreshTokenAddress)!;
    } else {
      return environment.youtubeDefaultUserRefreshToken;
    }
  }

  getIsYoutubeConnected() {
    const value = localStorage.getItem(this.isYoutubeConnectedAddress);
    return value === 'true';
  }
}

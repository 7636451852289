<section class="big-screen">
  <section [@pixelateInOutDelay]="true" class="bottom-boxes bottom-boxes-bubble">
    @for (prompt of fileUploadResponse(); track prompt.key) {
      <div class="bubble" (click)="sendToMainEvent.emit(prompt.value)">
        <p [title]="prompt.key">{{ prompt.value }}</p>
      </div>
    }
  </section>
</section>

<section class="small-screen">
  <section [@pixelateInOutDelay]="true" class="small-bubbles">
    @for (prompt of fileUploadResponse(); track prompt.key) {
      <div class="bubble p-4" (click)="sendToMainEvent.emit(prompt.value)">
        <p [title]="prompt.key">{{ prompt.value }}</p>
      </div>
    }
  </section>
</section>

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'mypart-playlist-icon',
  templateUrl: './playlist-icon.component.html',
  styleUrl: './playlist-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class PlaylistIconComponent {
  @Input() totalPlaylistSongs!: number;
  @Output() showPlaylistEvent = new EventEmitter<void>();
}

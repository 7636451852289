<div (outsideClick)="outsideClick()"
     [ngClass]="{'!max-h-[260px]': showBigSearchHomePage()}"
     class="w-[300px] max-h-[300px] overflow-y-auto mt-2 z-30 py-1 bg-white dark:bg-zinc-800 shadow-xl rounded-md overflow-hidden">
  @for (filter of filters; track filter.title) {
    <ul [attr.aria-label]="filter.title"
        class="px-3 pb-3 text-sm text-gray-700 dark:text-gray-200 overscroll-contain">
      @for (subFilter of filter.filters; track subFilter) {
        <li class="mt sm:my-0">
          <div class="flex items-center rounded hover:bg-gray-200 dark:hover:bg-gray-600 filter-container">
            <span (click)="filterTextClicked(filter, subFilter)"
                  [ngClass]="{'!text-red-600': subFilter.state === 'red', '!text-green-500': subFilter.state === 'green'}"
                  class="dark:border-gray-600 cursor-pointer w-full py-2 mx-2 text-sm font-medium text-gray-800 rounded
                     max-[600px]:whitespace-normal dark:text-gray-300 ellipsis" isEllipsis>{{ subFilter.name }}
            </span>
            <div class="sm:hidden flex items-center justify-center mx-2 filter-actions">
              <svg (click)="toggleFilter(filter,subFilter, 'red')"
                   [ngClass]="{'!text-red-600': subFilter.state === 'red'}"
                   aria-hidden="true"
                   class="cursor-pointer mr-6 sm:mr-4 w-6 h-5 sm:w-4 sm:h-3 text-gray-800 dark:text-gray-300
                      hover:text-black hover:dark:text-gray-50 active:text-red-600"
                   fill="none"
                   viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"/>
              </svg>

              <svg (click)="toggleFilter(filter, subFilter, 'green')"
                   [ngClass]="{'!text-green-500': subFilter.state === 'green'}"
                   aria-hidden="true"
                   class="cursor-pointer w-6 h-6 sm:w-4 sm:h-4 text-gray-800 dark:text-gray-300 hover:text-black
                      hover:dark:text-gray-50 active:text-green-500"
                   fill="none"
                   viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.917 5.724 10.5 15 1.5" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"/>
              </svg>
            </div>
          </div>
        </li>
      }
    </ul>
  }
</div>

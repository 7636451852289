<section [@pixelateInOutSearchBoxes]="true"
         [ngClass]="{'!z-10': searchDropDownHidden()}"
         class="bottom-boxes bottom-boxes-playlist">
  @for (playlist of curatedPromptPlaylists(); track playlist.id; let first = $first) {
    <div class="playlist" (click)="promptClicked(playlist.songhuntLink, false)"
         [ngClass]="{'mb-4': first}">
      @if (isMobileDevice()) {
        <section class="relative">
          <img imageLoader [src]="playlist.promptImageUrl" alt="" class="opacity-70 !h-[64px]">
          <div
              class="absolute top-0 bottom-0 left-0 right-0 text-center bg-black/50 flex items-center justify-center">
                <span
                    class="text-white text-base w-full break-words whitespace-normal">{{ playlist.shortPrompt }}</span>
          </div>
        </section>
      } @else {
        <img imageLoader [src]="playlist.promptImageUrl" alt="">
        <p class="multiline-ellipsis" multilineEllipsis>{{ playlist.shortPrompt }}</p>
      }
    </div>
  }
  @if (curatedPromptPlaylists() && featuredArtist()) {
    <div class="playlist" (click)="promptClicked('', true)">
      @if (isMobileDevice()) {
        <section class="relative">
          <img imageLoader [src]="featuredArtist()!.imageURL" alt="" class="opacity-70 !h-[64px]">
          <div
              class="absolute top-0 bottom-0 left-0 right-0 text-center bg-black/50 flex items-center justify-center">
                <span class="text-white text-base w-full">
                   <strong>Featured Artist:</strong> Songs by {{ featuredArtist()!.artistName }}
                </span>
          </div>
        </section>
      } @else {
        <section class="relative">
          <img imageLoader [src]="featuredArtist()!.imageURL" alt="">
          <div class="absolute bottom-0 left-0 right-0 text-center py-2 bg-black/50">
            <span class="text-white text-[22px] font-medium">Featured Artist</span>
          </div>
        </section>
        <p class="multiline-ellipsis" multilineEllipsis>Songs by {{ featuredArtist()!.artistName }}</p>
      }
    </div>
  }
</section>
